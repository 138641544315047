// general
import React, { useState } from "react";

// material components
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material//DialogTitle";

// custom components
import { CustomColorScheme } from "../components/CustomTheme";

export default function DeletionConfirmationDialog(props) {
  const {
    open,
    setOpen,
    title,
    message,
    onCancel,
    onDelete,
  } = props;

  const text = message.indexOf("\n") ? message.split("\n") : [message];

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box
        sx={{
          color: CustomColorScheme['black'],
          backgroundColor: CustomColorScheme['white']
        }} >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent >
          {
            text.map((segment, idx) => {
              return (
                <div key={idx}>{segment}</div>
              )
            })
          }
        </DialogContent>
        <DialogActions>
          <Box
            flexGrow={1}
            padding={2}
            display="flex"
            justifyContent="flex-end"
            minWidth={300}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-end"
              justifyContent="flex-start"
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: CustomColorScheme['darkGreenBrown'],
                  '&:hover': {
                    backgroundColor: CustomColorScheme['darkRed'],

                  }
                }}
                onClick={() => {
                  setOpen(false);
                  onDelete();
                }}>
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  color: CustomColorScheme['darkGreenBrown'],
                  borderColor: CustomColorScheme['darkGreenBrown'],
                  '&:hover': {
                    color: CustomColorScheme['darkGreenBrown'],
                    borderColor: CustomColorScheme['darkGreenBrown'],
                  }
                }}
                onClick={() => {
                  setOpen(false);
                  onCancel();
                }}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Box >
    </Dialog >
  );
};

