export const TemplateTypes = [
  {
    id: -1,
    title: "All"
  }, {
    id: 1,
    title: "Week"
  }, {
    id: 2,
    title: "Month"
  }, {
    id: 3,
    title: "Every N Days"
  }, {
    id: 5,
    title: "Misc"
  }
]

export const daysOfMonth = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28,
  100
]

export const orderIdList = [1, 2, 3, 4, 5]

export const daysOfWeek = [
  {
    id: 1,
    weekday: "Sunday",
  }, {
    id: 2,
    weekday: "Monday",
  }, {
    id: 3,
    weekday: "Tuesday",
  }, {
    id: 4,
    weekday: "Wednesday",
  }, {
    id: 5,
    weekday: "Thursday",
  }, {
    id: 6,
    weekday: "Friday",
  }, {
    id: 7,
    weekday: "Saturday",
  }
]

export const frequencyDays = [
  {
    id: 1,
    label: "every day",
  }, {
    id: 2,
    label: "2 days",
  }, {
    id: 3,
    label: "3 days",
  }, {
    id: 4,
    label: "4 days",
  }, {
    id: 5,
    label: "5 days",
  }, {
    id: 6,
    label: "6 days",
  }, {
    id: 7,
    label: "every week",
  }, {
    id: 14,
    label: "2 weeks",
  }, {
    id: 21,
    label: "3 weeks",
  }, {
    id: 28,
    label: "4 weeks",
  }, {
    id: 30,
    label: "30 days",
  }, {
    id: 35,
    label: "5 weeks",
  }, {
    id: 42,
    label: "6 weeks",
  }, {
    id: 60,
    label: "60 days",
  }
]
export const businessRules = [
  "Closed templates may not be modified",
  "All templates:",
  "- ParentTemplate may be changed for templates with no children",
  "- Title, Order and EndDate may be changed",
  "Monthly templates:",
  "- DayOfMonth may be changed ",
  "EveryNDay templates:",
  "- Frequency may be changed"

]