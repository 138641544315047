// general
import { useMediaQuery } from 'react-responsive'

// material ui components
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// custom components
import DeletionConfirmationDialog from "../dialogs/DeletionConfirmationDialog";
import { CustomColorScheme } from "../components/CustomTheme";


export default function ButtonBar(props) {
  const {
    providedTask,
    title,
    confirmDeleteOpen,
    handleTaskSave,
    handleTaskComplete,
    handleDialogDelete,
    setConfirmDeleteOpen,
    handleClose,
  } = props;

  // constants //////////////

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });


  // render ///////////////////

  return (
    <>
      <Stack
        direction="row"
        spacing={3}
        margin={2}
        display='flex'
        width='100%'
        justifyContent={isMobile ? 'space-evenly' : 'end'}
      >
        <Typography
          onClick={handleTaskSave}
          sx={{
            color: CustomColorScheme['darkestBrown'],
            fontSize: 18,
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 'bold',
              color: CustomColorScheme['green']
            }
          }}
        >
          Save
        </Typography>
        <Typography
          onClick={() => { handleTaskComplete(providedTask) }}
          sx={{
            color: CustomColorScheme['darkestBrown'],
            fontSize: 18,
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 'bold',
              color: CustomColorScheme['green']
            }
          }}
        >
          {providedTask.isCompleted ? "Uncomplete" : "Complete"}
        </Typography>
        {
          providedTask.taskId &&
          <>
            <Typography
              onClick={() => setConfirmDeleteOpen(true)}
              sx={{
                color: CustomColorScheme['darkestBrown'],
                fontSize: 18,
                cursor: 'pointer',
                '&:hover': {
                  fontWeight: 'bold',
                  color: CustomColorScheme['red']
                }
              }}
            >
              Delete
            </Typography>
          </>}
        <Typography
          onClick={() => handleClose()}
          sx={{
            color: CustomColorScheme['darkestBrown'],
            fontSize: 18,
            paddingRight: isMobile ? 0 : 1,
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 'bold',
            }
          }}
        >
          Cancel
        </Typography>
      </Stack>

      {/**** non-visible elements ****/}
      <DeletionConfirmationDialog
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete\n${title}?`}
        onCancel={handleClose}
        onDelete={() => handleDialogDelete(providedTask)}
      />
    </>
  )
}