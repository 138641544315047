import '../App.css';
import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive'

// mui
import Stack from "@mui/material/Stack";

// components
import DateCard from "../components/DateCard";
import { CustomColorScheme, DATEFORMAT, TitleLabel } from '../components/CustomTheme';
import Toolbar from '../components/Toolbar';


export default function CustomDateRange(props) {
  const {
    getCustomTaskdates,
    customTaskdates,
    setReturnPath,
    includeDeleted,
  } = props;

  const [currentStartdate, setCurrentStartdate] = useState(null);

  const previousStartdate = dayjs(currentStartdate).add(-7, 'days').format(DATEFORMAT);
  const nextStartdate = dayjs(currentStartdate).add(7, 'days').format(DATEFORMAT);


  ///////////////////////////

  // useffect ///////////////

  useEffect(() => {
    document.title = "Custom Date Range";
    setReturnPath("/customdaterange")
  }, []);


  useEffect(() => {
    if (customTaskdates) {
      setCurrentStartdate(Object.keys(customTaskdates)[0])
    }
  }, [customTaskdates]);


  // event handlers /////////

  const handleStartdateChange = (newDateStr) => {
    getCustomTaskdates(newDateStr);
  }

  // constants //////////////

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });

  // toolbar plugin /////////

  function ToolbarPlugin(props) {
    const {
      handleStartdateChange,
      previousStartdate,
      nextStartdate,
    } = props


    return (
      <Stack
        direction='row'
        justifyContent='end'
        spacing={2}
      >
        <TitleLabel
          sx={{
            color: CustomColorScheme['transparentWhite70'],
            cursor: "pointer",
            ":hover": {
              color: CustomColorScheme['white']
            }
          }}
          onClick={() => handleStartdateChange(previousStartdate)}
        >
          &#9664; prev
        </TitleLabel>
        <TitleLabel
          sx={{
            color: CustomColorScheme['transparentWhite70'],
            cursor: "pointer",
            ":hover": {
              color: CustomColorScheme['white']
            }
          }}
          onClick={() => handleStartdateChange(nextStartdate)}
        >
          next &#9654;
        </TitleLabel>
      </Stack>
    )
  }

  ////////////////////////////

  return (
    <>
      <Toolbar
        title="Selected Week"
        component={
          <ToolbarPlugin
            handleStartdateChange={handleStartdateChange}
            previousStartdate={previousStartdate}
            nextStartdate={nextStartdate}
          />
        }
      />
      <Stack direction="column" spacing={.5}>
        {
          customTaskdates
            ?
            Object.keys(customTaskdates).map((date) => {
              return (
                <DateCard key={date}
                  calendarItems={customTaskdates[date].calendarItems}
                  date={date}
                  tasks={customTaskdates[date].tasks}
                  includeSwitch={false}
                />
              )
            })
            :
            <></>
        }
      </Stack>
    </>
  );
}