import React, { useState, forwardRef, Ref } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { CustomColorScheme } from "./CustomTheme";

export const WhiteTooltip = styled(({ className, ...props }) => (
  <div>
    <Tooltip {...props} arrow classes={{ popper: className }} />
  </div>
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: CustomColorScheme['white'],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: CustomColorScheme['white'],
    color: CustomColorScheme['black'],
    maxWidth: 500,
    fontSize: 13,
  },
});


export const JournalTooltip = styled(({ className, ...props }) => (
  <div>
    <Tooltip {...props} arrow classes={{ popper: className }} />
  </div>
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: CustomColorScheme['body'],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: CustomColorScheme['body'],
    color: CustomColorScheme['white'],
    maxWidth: 500,
  },
});


