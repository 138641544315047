// general
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive'

// material ui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';


// custom components
import { CustomColorScheme } from './CustomTheme';

//////////////////////////////

export default function Copyright() {

  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const uptakeblueUrl = 'https://d3rqy5efbrvoqx.cloudfront.net';

  // event handlers //////////////

  return (
    <Box
      textAlign='center'
      color={CustomColorScheme['white']}
      marginY={2}
      width='100%'
    >

      Copyright 2006-{dayjs().format('YYYY')}
      <Link
        target="_blank"
        href={uptakeblueUrl}
        sx={{
          color: CustomColorScheme['white'],
          paddingX: 1,
          textDecoration: 'none',
        }}
      >@</Link>
      Michael Rubin{isMobile ? <br /> : ", "} All rights
      reserved
    </Box>
  );
}
