// general
import '../App.css';
import { useState, useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

// mui
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import PersonIcon from '@mui/icons-material/Person';
import ChecklistIcon from '@mui/icons-material/Checklist';

// components
import { CustomColorScheme, DATEFORMAT } from '../components/CustomTheme';
import TemplateDialog from '../dialogs/TemplateDialog';
import { TemplateContext } from './AllContext';
import DeletionConfirmationDialog from '../dialogs/DeletionConfirmationDialog';


export default function TemplateCard(props) {
  const {
    template,
    parentTemplate,
    parentTemplates,
  } = props;

  const {
    templates,
    getTemplates,
    setReturnPath,
    setTemplateCreate,
    setTemplateUpdate,
    setTemplateDelete,
    detailTemplate,
    setDetailTemplate,
    detailParentTemplate,
    setDetailParentTemplate,
    detailParentTemplates,
    setDetailParentTemplates,
    getTemplateTasks,
    taskTemplate,
    setTaskTemplate,
  } = useContext(TemplateContext)


  // constants //////////////

  const [isExpanded, setIsExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [menu, setMenu] = useState(null);
  const iconId = "rotationTarget" + template.templateId.toString();
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const navigate = useNavigate();

  const parentTemplatesFiltered = parentTemplates &&
    parentTemplates.filter((t) => t.id !== template.templateId)

  // event handlers /////////

  const handleExpand = () => {
    setIsExpanded((isExpanded !== undefined) && template.description ? !isExpanded : false);
    const rotatedIcon = document.getElementById(iconId)
    if (rotatedIcon) {
      rotatedIcon.style.transform = isExpanded ? "rotate(0deg)" : "rotate(180deg)";
    }
  };

  const handleMenuOpen = (event) => {
    event.preventDefault();
    setMenu(
      menu === null
        ? {
          mouseX: event.clientX + 5,
          mouseY: event.clientY + 5,
        }
        : null,
    );
  }

  const handleDialogOpen = (event) => {
    event.preventDefault();
    setMenu(null);
    if (isMobile) {
      setDetailTemplate(template);
      setDetailParentTemplate(parentTemplate);
      setDetailParentTemplates(parentTemplatesFiltered)
      navigate('/templatedetail');
    } else {
      setDialogOpen(true);
    }

  }

  const handleDialogOpenClone = (event) => {
    event.preventDefault();
    handleDialogOpen(event);
    setMenu(null);
  }

  const handleDeleteTemplate = (event) => {
    // event.preventDefault();
    setTemplateDelete(template)
    setMenu(null);
  }

  const handleConfirmDelete = (event) => {
    event.preventDefault();
    setConfirmDialogOpen(true)
    // console.log("handleConfirmDelete")
    setMenu(null);
  }

  const handleNavigateToTasks = () => {
    setTaskTemplate(template);
    getTemplateTasks(template);
    navigate("/templatetasks")
  }

  // functions //////////////

  function templateDescription(template) {
    let returnVal = "";

    function computeTimeOfDay(minutes) {
      let returnVal = "";
      if (minutes) {
        let amPm = 'AM'
        let hr = Math.floor(minutes / 60)
        let min = minutes % 60
        if (hr >= 12) {
          hr = hr - 12;
          amPm = "PM";
        }
        returnVal = (isMobile ? "" : ", at ") + hr + ":" + (min ? min : "00") + amPm
      }
      return returnVal;
    }

    let prefix = isMobile ? "" : ",";
    let timeOfDay = computeTimeOfDay(template.minutes);

    switch (template.templateType) {
      case "week":
        returnVal = `${prefix} ${template.dayOfWeekName}`
        returnVal += template.minutes < 660 ? ' mornings' : "s"
        break;
      case "month":
        returnVal = `${prefix} the ${template.nthDayOfMonth} of every month`
        break;
      case "n-days":
        returnVal = `${prefix}  every ${template.nDays} days`
        break;
      case "misc":
        returnVal = ""
        break;
    }

    if (!isMobile && !template.isClosed && template.enddate) {
      returnVal += ", ending " + dayjs(template.enddate).format("M/D/YYYY");
    }

    if (!parentTemplate) {
      return timeOfDay + returnVal
    }
  }

  // render /////////////////

  return (
    <>
      <Box
        sx={{
          marginTop: template.description ? .25 : .25,
          // marginBottom: template.templates.length > 0 ? -.25 : 0,
        }}

      >
        {
          isMobile
            ?
            <Stack
              spacing={0}
              sx={{
                backgroundColor: template.isClosed ? CustomColorScheme['transparentTask85'] : CustomColorScheme['task'],
                paddingX: 1,
                margin: 0,
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
              >
                {
                  template.description
                    ?
                    <Box
                      onClick={handleExpand}
                      display='flex'
                    >
                      <ArrowDropDownIcon
                        id={iconId}
                        onClick={handleExpand}
                      />
                    </Box>
                    :
                    <Box
                      width={21}
                    />
                }
                <Tooltip
                  arrow
                  title={template.templateId}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      paddingRight: 0,
                    }}
                    onClick={(e) => handleDialogOpen(e)}
                  >
                    {template.title}
                  </Typography>
                </Tooltip>
                <Box
                  display='flex'
                  flexGrow={1}
                  justifyContent='end'
                />
                {
                  template.parentTemplateId &&
                  <Typography
                    fontSize={12}
                  >
                    {template.orderId}
                  </Typography>
                }
                <IconButton
                  onClick={handleMenuOpen}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Typography
                  onClick={template.description ? handleExpand : null}
                >
                  {
                    template.isClosed
                      ? "(closed)"
                      : template.enddate ? "(" + dayjs(template.enddate).format("M/D/YY") + ")" : ""
                  }
                </Typography>
              </Stack>
              <Typography
                sx={{
                  paddingLeft: 3,

                }}
              >
                {templateDescription(template)}
              </Typography>
            </Stack>
            :
            <Stack
              direction='row'
              spacing={0}
              justifyContent='center'
              alignItems='center'
              sx={{
                backgroundColor: template.isClosed ? CustomColorScheme['transparentTask85'] : CustomColorScheme['task'],
                padding: 1,
              }}
            >
              {
                template.description
                  ?
                  <Box
                    onClick={handleExpand}
                    display='flex'
                  >
                    <ArrowDropDownIcon
                      id={iconId}
                      onClick={handleExpand}
                    />
                  </Box>
                  :
                  <Box
                    width={21}
                  />
              }
              <Tooltip
                arrow
                title={template.templateId}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    paddingRight: 0,
                    cursor: 'pointer',
                  }}
                  onClick={(e) => handleDialogOpen(e)}
                  onContextMenu={handleMenuOpen}
                >
                  {template.title}
                </Typography>
              </Tooltip>
              <Typography
                display='flex'
                flexGrow={1}
                sx={{
                  paddingLeft: 0,
                }}
              >
                {templateDescription(template)}
              </Typography>
              {
                template.parentTemplateId &&
                <Typography
                  sx={{
                    paddingX: 1,
                    fontSize: 12,
                  }}
                >
                  {template.orderId}
                </Typography>
              }
              {
                template.isClosed &&
                <Typography
                  justifyContent='end'
                  paddingX={1}
                  onClick={template.description ? handleExpand : null}
                >
                  (closed)
                </Typography>
              }
              <Tooltip
                arrow
                title='View Template-specific Tasks'
              >
                <IconButton
                  onClick={handleNavigateToTasks}
                >
                  <ChecklistIcon />
                </IconButton>
              </Tooltip>
            </Stack>
        }
        {
          template.description &&
          < Collapse
            in={isExpanded}
          >
            <Box sx={{
              marginTop: .25,
              marginLeft: 2,
              paddingLeft: 1,
              paddingY: 1.5,
              backgroundColor: template.isClosed ? CustomColorScheme['transparentTask85'] : CustomColorScheme['task'],
            }}>
              <Typography
                fontSize={isMobile ? 14 : 15}
                component="div"
                paddingLeft={2}
                style={{
                  whiteSpace: 'pre-line',
                  wordWrap: "break-word",
                }}
              >
                {template.description}
              </Typography>
            </Box>
          </Collapse>
        }
        {
          template.templates &&
          template.templates.map((childTemplate, idx) => {
            return (
              <Box
                sx={{
                  paddingLeft: 2,
                  '&:last-child': {
                    marginBottom: -.25,
                  }
                }}

              >
                <TemplateCard
                  key={idx}
                  template={childTemplate}
                  parentTemplate={template}
                  parentTemplates={parentTemplates}
                />
              </Box>
            )
          })
        }
      </Box >

      {/* menu */}
      <Menu
        menu={menu}
        open={menu !== null}
        onClose={() => setMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          menu !== null
            ? {
              top: menu.mouseY,
              left: menu.mouseX,
            }
            : undefined
        }
      >
        <MenuItem
          onClick={handleDialogOpen}
        >
          <ListItemIcon>
            {
              template.isClosed
                ? <PersonIcon />
                : <CreateSharpIcon />
            }
          </ListItemIcon>
          <Typography
            component="div"
            variant="inherit">
            {template.isClosed ? "View Template" : "Edit Template"}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleDialogOpenClone}
        >
          <ListItemIcon>
            <ContentCopyIcon
              fontSize="small"
            />
          </ListItemIcon>
          <Typography
            component="div"
            variant="inherit"
          >
            Clone
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleNavigateToTasks}
        >
          <ListItemIcon>
            <ChecklistIcon
              fontSize="small"
            />
          </ListItemIcon>
          <Typography
            component="div"
            variant="inherit"
          >
            Tasks
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleConfirmDelete}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography
            component="div"
            variant="inherit"
            width={110}
          >
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      {/**** non-visible elements ****/}

      <TemplateDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        template={template}
        parentTemplate={parentTemplate}
        parentTemplates={parentTemplatesFiltered}
      />

      <DeletionConfirmationDialog
        open={confirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete\n${template.title}?`}
        onCancel={() => setConfirmDialogOpen(false)}
        onDelete={handleDeleteTemplate}
      />

    </>
  );

}