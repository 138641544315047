// general
import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import { useMediaQuery } from 'react-responsive'

// Material UI
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, alpha } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Toolbar from '../components/Toolbar';

// components
import { CustomColorScheme, TitleLabel, DateCardColor } from '../components/CustomTheme';


/////////////////////////////


export default function People(props) {
  const {
    getPersonData,
    personData,
    setReturnPath,
  } = props;


  // constants ////////////////

  const [monthsAhead, setMonthsAheadValue] = useState(
    localStorage.getItem("monthsAhead") !== null
      ? JSON.parse(localStorage.getItem("monthsAhead"))
      : 12
  );

  function setMonthsAhead(value) {
    localStorage.setItem("monthsAhead", value)
    setMonthsAheadValue(value)
  }

  //

  const [tabSelected, setTabSelectedValue] = useState(
    localStorage.getItem("tabSelected") !== null
      ? JSON.parse(localStorage.getItem("tabSelected"))
      : 0
  );

  function setTabSelected(value) {
    localStorage.setItem("tabSelected", value)
    setTabSelectedValue(value)
  }

  //

  const [rowsPerPage, setRowsPerPageValue] = useState(
    localStorage.getItem("rowsPerPage") !== null
      ? JSON.parse(localStorage.getItem("rowsPerPage"))
      : 10
  );

  function setRowsPerPage(value) {
    localStorage.setItem("rowsPerPage", value)
    setRowsPerPageValue(value)
  }

  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })


  // useEfect /////////////////

  useEffect(() => {
    setPage(0)
  }, [])

  // event handlers ///////////

  const handleMonthsAheadChange = (event, newValue) => {
    if (newValue) {
      setMonthsAhead(newValue);
      setPage(0);
    }
  }

  const handleChangeRowsPerPage = (event) => {
    let newValue = parseInt(event.target.value, 10)
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handlePageChange = (event, newValue) => {
    setPage(newValue)
  }


  // constants ///////////////

  const isBirthday = personData && personData.birthday && personItemsFiltered(personData.birthday).length > 0;
  const isAnniversary = personData && personData.anniversary && personItemsFiltered(personData.anniversary).length > 0;
  const isDivorce = personData && personData.divorce && personItemsFiltered(personData.divorce).length > 0;
  const isDeceased = personData && personData.deceased && personItemsFiltered(personData.deceased).length > 0;
  const isPersonDataValid = personData && personData.birthday;

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      border: 0,
      color: alpha(theme.palette.common.white, 0.70),
      maxHeight: 30,
      marginTop: 6,
      "&.Mui-selected": {
        color: CustomColorScheme['white'],
        backgroundColor: CustomColorScheme['darkbody'],
        borderRadius: 7
      },
    }, '& .MuiToggleButtonGroup-grouped:hover': {
      color: CustomColorScheme['yellow'],
      borderRadius: 7
    },
  }));

  const TabPanel = (props) => {
    const { children, value, idx } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== idx}
      >
        {value === idx && (
          <Box sx={{ p: 1 }}>
            <span>{children}</span>
          </Box>
        )}
      </div>
    );
  }

  const PersonTable = (props) => {
    const { personItems } = props;

    let rows = personItemsFiltered(personItems);

    return (
      <>
        {
          isMobile
            ?
            <>
              {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((personItem, idx) => {
                return (
                  <PersonCard
                    key={idx}
                    personItem={personItem}
                    tabSelected={tabSelected}
                  />
                )
              })}
            </>
            :
            <TableContainer component={Paper} sx={{
              backgroundColor: CustomColorScheme['task']
            }}>
              <Table
                sx={{
                  minWidth: 650,
                }}
              >
                <TableHead
                  sx={{
                    '& .MuiTableCell-head': {
                      backgroundColor: CustomColorScheme['brown'],
                      color: CustomColorScheme['white'],
                      borderColor: CustomColorScheme['task'],
                    },

                  }}
                >
                  <TableRow >
                    <TableCell
                      sx={{
                        fontSize: 17,
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        width: 100,
                        fontSize: 17,
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        width: 100,
                        textAlign: "right",
                        fontSize: 17,
                      }}
                    >
                      Next Date
                    </TableCell>
                    <TableCell
                      sx={{
                        width: 135,
                        fontSize: 17,
                      }}
                    >
                      Description
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    (rowsPerPage > 0
                      ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : rows
                    ).map((personItem, idx) => {
                      return (
                        <PersonRow key={idx} personItem={personItem} />
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer >
        }
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handlePageChange}
          labelDisplayedRows={({ from, count }) => `Page ${parseInt(from / rowsPerPage) + 1} of ${parseInt(count / rowsPerPage) + 1}`}
          labelRowsPerPage={isMobile ? "Per page" : "Rows per page"}
          sx={{
            color: CustomColorScheme['white'],
            '& .MuiSelect-icon': {
              color: CustomColorScheme['white'],
            },
          }}
        />
      </>
    );
  }

  const PersonRow = (props) => {
    const { personItem } = props;
    return (
      <TableRow key={personItem.name} >
        <TableCell
        >
          {personItem.name}
        </TableCell>
        <TableCell
          sx={{
            textAlign: "center",
          }
          }>
          {dayjs(personItem.originalDate).format("MMM D, YYYY")}
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'right',
          }}
        >
          {dayjs(personItem.nextDate).format("MMM D, YYYY")}
        </TableCell>
        <TableCell >
          {personItem.description}
        </TableCell>
      </TableRow>
    );
  }

  const PersonCard = (props) => {
    const {
      personItem,
      tabSelected,
    } = props;
    let origination;
    let eventType;
    switch (tabSelected) {
      case 0:
        origination = 'Born on';
        eventType = 'birthday';
        break;
      case 1:
        origination = 'Married on';
        eventType = 'anniversary';
        break;
      case 2:
        origination = 'Divorced on';
        eventType = 'years';
        break;
      case 3:
        origination = 'Died on';
        eventType = 'years';
        break;
    }
    let pos = personItem.description.indexOf(' ');

    let note = `${personItem.description.substring(0, pos) + ' ' + eventType + personItem.description.substring(pos)} on ${dayjs(personItem.nextDate).format("MMM D, YYYY")}`;

    return (
      <Box
        backgroundColor={CustomColorScheme['task']}
        marginBottom={.25}
        padding={1}
        fontSize={15}
      >
        <Box
          fontWeight='bold'
          fontSize={18}
          color={CustomColorScheme['darkGray']}
        >
          {personItem.name}
        </Box>
        <Box>
          {`${origination} ${dayjs(personItem.originalDate).format("MMM D, YYYY")}`}
        </Box>
        <Box>
          {note}
        </Box>
      </Box>
    )
  }


  // functions ////////////////

  function personItemsFiltered(personItemsArray) {
    return personItemsArray.filter((element) => {
      return element.monthsOut <= monthsAhead;
    });
  }

  ////////////////////////////

  useEffect(() => {
    document.title = "People";
    setReturnPath("/people")
    getPersonData();
  }, []);


  // toolbar plugin //////////

  function ToolbarPlugin(props) {

    const { isMobile } = props;

    return (
      <>
        <TitleLabel sx={{ paddingRight: 1.5 }}>Months Ahead: </TitleLabel>
        <StyledToggleButtonGroup
          value={monthsAhead}
          exclusive
          onChange={handleMonthsAheadChange}
          aria-label="months"
          size="small"
        >
          <ToggleButton value={1}>{isMobile ? '1' : 'One'}</ToggleButton>
          <ToggleButton value={2}>{isMobile ? '3' : 'Two'}</ToggleButton>
          <ToggleButton value={3}>{isMobile ? '4' : 'Three'}</ToggleButton>
          <ToggleButton value={6}>{isMobile ? '6' : 'Six'}</ToggleButton>
          <ToggleButton value={12}>All</ToggleButton>

        </StyledToggleButtonGroup>
      </>
    )
  }


  // render //////////////////

  return (
    <>
      <Toolbar
        title="People"
        component={
          <ToolbarPlugin
            isMobile={isMobile}
          />
        }
      />
      <Paper
        sx={{
          backgroundColor: DateCardColor,
          width: "100%",
        }}
      >
        <Tabs
          value={tabSelected}
          onChange={(event, newTabSeletion) => {
            setTabSelected(newTabSeletion);
            setPage(0);
          }}
          area-label="person-tabs"
          centered
          sx={{
            color: CustomColorScheme['white'],
            '& .MuiTab-root.Mui-selected': {
              color: CustomColorScheme['white'],
            },
            '& .MuiTab-root': {
              color: CustomColorScheme['task'],
              // textTransform: 'none',
            },
            '& .MuiTabs-indicator': {
              background: CustomColorScheme['task'],
            }
          }}
        >
          <Tab
            value={0}
            label={isMobile ? "BDay" : "Birthdays"}
            disabled={!isBirthday}
            sx={{
              width: isMobile ? 65 : 125,
            }}
          />
          <Tab
            value={1}
            label={isMobile ? "Anv" : "Anniversaries"}
            disabled={!isAnniversary}
            sx={{
              width: isMobile ? 65 : 125,
            }}
          />
          <Tab
            value={2}
            label={isMobile ? "Divc'd" : "Divorces"}
            disabled={!isDivorce}
            sx={{
              width: isMobile ? 65 : 125,
            }}
          />
          <Tab
            value={3}
            label={isMobile ? "Dec'd" : "Deceased"}
            disabled={!isDeceased}
            sx={{
              width: isMobile ? 65 : 125,
            }}
          />
        </Tabs >
        <TabPanel value={tabSelected} idx={0}>
          {isPersonDataValid ? <PersonTable personItems={personData.birthday} /> : <></>}
        </TabPanel >
        <TabPanel value={tabSelected} idx={1}>
          {isPersonDataValid ? <PersonTable personItems={personData.anniversary} /> : <></>}
        </TabPanel>
        <TabPanel value={tabSelected} idx={2}>
          {isPersonDataValid ? <PersonTable personItems={personData.divorce} /> : <></>}
        </TabPanel>
        <TabPanel value={tabSelected} idx={3}>
          {isPersonDataValid ? <PersonTable personItems={personData.deceased} /> : <></>}
        </TabPanel>
      </Paper >
    </>
  );
}