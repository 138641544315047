// general
import { useState, useContext } from "react";
import dayjs from "dayjs";
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from "react-router-dom";

// material ui
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";

// components
import TaskCardList from "./TaskCardList";
import { CustomColorScheme, DATEFORMAT, DateCardColor } from "./CustomTheme";
import CustomSwitch from './CustomSwitch';
import { DateCardContext } from "./AllContext";
import TaskContent from "./TaskContent";

export default function DateCard(props) {
  const {
    calendarItems,
    date,
    tasks,
    isLast,
    includeSwitch,
    singleDateView,
  } = props;

  const {
    setDetailTask,
    setReturnPath,
    includeCompletedTasks,
    setIncludeCompletedTasks,
    includeDeleted,
  } = useContext(DateCardContext)

  // constants //////////////

  const [dialogOpen, setDialogOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });
  const navigate = useNavigate();
  const location = useLocation();

  const newTask = {
    date: dayjs(date).format(DATEFORMAT),
    detail: null,
    minutes: null,
    orderId: null,
    parentTaskId: null,
    taskId: null,
    templateId: null,
    title: null,
    isCompleted: false,
    isClone: false,
  }

  // event handlers /////////

  const handleCreateNewTask = () => {
    if (isMobile) {
      setDetailTask(newTask);
      navigate("/taskdetail")
    } else {
      setReturnPath(location.pathname)
      setDialogOpen(true)
    }
  }

  return (
    <>
      <a id={dayjs(date).format(DATEFORMAT)} />
      <Stack
        item={date}
        key={date}>
        <Paper
          elevation={2}
          sx={{
            backgroundColor: DateCardColor,
            color: CustomColorScheme['white'],
            paddingBottom: isLast ? 2.5 : 0,
          }}
        >
          <Box
            padding={1}
            onContextMenu={(e) => e.preventDefault()}
          >
            <Stack
              direction='row'
              paddingTop={0.5}
            >
              <Stack
                direction="row"
                flexGrow={1}
                alignItems='center'
              >
                <Typography
                  variant="body1"
                  component="div"
                  fontSize={17.5}
                  onClick={handleCreateNewTask}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    }
                  }}
                >
                  {isMobile
                    ? dayjs(date).format("dddd, M/D/YYYY")
                    : dayjs(date).format("dddd, MMM D, YYYY")
                  }
                </Typography>
                <Box flexGrow={1} />
              </Stack>
              {
                includeSwitch
                  ?
                  <CustomSwitch
                    stateValue={includeCompletedTasks}
                    setStateValue={setIncludeCompletedTasks}
                    displayMode='dark'
                    title={["Completed", "Show Completed"]}
                  />
                  :
                  <Box
                    width={60}
                    onClick={() => window.scrollTo(0, 0)}
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
              }
            </Stack>
            <Stack
              direction='row'
            >
              <Box
                flexGrow={1}
                paddingBottom={1}
                paddingTop={calendarItems ? 1 : 0}
              >
                {
                  calendarItems
                    ?
                    calendarItems.map((item) => {
                      return (
                        <Typography
                          key={item.title}
                          variant="body2"
                          component="div"
                          margin={0}
                          color={CustomColorScheme['lightgreen']} >
                          {item.title}
                        </Typography>
                      )
                    })
                    :
                    <></>
                }
              </Box>
              <Box
                width={60}
                onClick={() => window.scrollTo(0, 0)}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </Stack>
            <TaskCardList
              tasklist={tasks}
              includeCompletedTasks={includeCompletedTasks || ['/customdaterange', '/previoustasks'].includes(location.pathname) || (singleDateView && date < dayjs().format(DATEFORMAT))}
              includeDeleted={includeDeleted}
            />
          </Box>
        </Paper>
      </Stack >

      {/**** non-render elements ****/}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <TaskContent
          providedTask={newTask}
          onClose={() => setDialogOpen(false)}
        />
      </Dialog>

    </>
  );
}

