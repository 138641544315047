// general
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useMediaQuery } from 'react-responsive'

// Material UI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import Collapse from '@mui/material/Collapse';
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Popover from '@mui/material/Popover';

// icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// components
import { CustomColorScheme, DATEFORMAT } from "../components/CustomTheme"
import { JournalTooltip } from "../components//Tooltips"
import { JournalContext } from "../components/AllContext";
import { Tooltip } from "@mui/material";
import JournalConfirmationAbandonDialog from "../dialogs/JournalConfirmationAbandonDialog";


export default function Journal(props) {
  const {
    journalEntryUnsaved,
    journalMap,
    journalUpdateStatus,
  } = props;

  const {
    getJournalMap,
    setJournalEntryUnsaved,
    setJournalSave,
    setJournalUpdateStatus,
    setTodayJournalEntry,
    todayJournalEntry,
    setWeightValue,
    todayDate,
    returnPath,
  } = useContext(JournalContext)


  // constants ////////////

  const [journalEntry, setJournalEntry] = useState();
  const [originalJournalEntry, setOriginalJournalEntry] = useState();
  const [targetDate, setTargetDate] = useState();
  const [journalDate, setJournalDate] = useState(dayjs());
  const [isToday, setIsToday] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const calendarOpen = Boolean(anchorEl);


  // const weightRegex = /\b\d{3}\.\d{1}\b/g;
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
  const iconId = "rotationTarget";
  const navigate = useNavigate();
  const { routedate } = useParams();

  // useEffect ////////////

  useEffect(() => {
    navigate(`/journal/${routedate} `)
    getJournalMap(routedate);
  }, [])

  useEffect(() => {
    if (journalMap) {
      setJournalEntry(journalMap.journalEntry);
      setOriginalJournalEntry(journalMap.journalEntry);
      setJournalDate(journalMap.currentDate);
      setIsToday(journalMap.currentDate === todayDate);
      setJournalEntryUnsaved(false);
      if (routedate !== journalMap.currentDate)
        navigate(`/journal/${journalMap.currentDate} `)
    }
  }, [journalMap]);


  useEffect(() => {
    if (journalUpdateStatus &&
      journalUpdateStatus.status &&
      (journalUpdateStatus.status === "success" || journalUpdateStatus.status === "error" || journalUpdateStatus.status === "success_copy")) {
      if (journalUpdateStatus.status === "success") {
        setOriginalJournalEntry(journalEntry);
        setJournalEntryUnsaved(false);
      }
      setSnackbarOpen(true);
    }
  }, [journalUpdateStatus])


  // event handlers ///////

  const handleIsExpand = () => {
    let newExpandedValue = !isExpanded
    setIsExpanded(newExpandedValue);
    const rotatedIcon = document.getElementById(iconId)
    if (rotatedIcon) {
      rotatedIcon.style.transform = isExpanded ? "rotate(0deg)" : "rotate(180deg)";
    }
  };
  const handleDateCalendarChange = (newDate, selectionState) => {
    if (selectionState === 'finish') {
      setAnchorEl(null)
      handleDateChange(dayjs(newDate).format(DATEFORMAT));
    }
  }


  const handleDateChange = (newDateStr, journalSavedOverride) => {
    let isJournalEntryUnsaved = journalSavedOverride !== undefined
      ? journalSavedOverride
      : journalEntryUnsaved;
    if (isJournalEntryUnsaved) {
      setTargetDate(newDateStr)
      setJournalUpdateStatus({});
      setConfirmationDialogOpen(true)
    } else {
      setConfirmationDialogOpen(false);
      setJournalDate(newDateStr)
      getJournalMap(newDateStr);
    }
  }

  const handleDatePrev = () => {
    let newDate = dayjs(journalDate).add(-1, "day").format(DATEFORMAT);
    handleDateChange(newDate);
  }

  const handleDateNext = () => {
    let newDate = dayjs(journalDate).add(1, "day").format(DATEFORMAT);
    handleDateChange(newDate);
  }


  const handleJournalEntryApplyChanges = (navTarget) => {
    if (isToday) {
      if (journalEntry !== todayJournalEntry) {
        setTodayJournalEntry(journalEntry);
      }
    }
    let dateStr = dayjs(journalDate).format(DATEFORMAT);
    let journalObject = {
      "date": dateStr,
      "journalEntry": journalEntry,
    };
    if (navTarget) {
      journalObject.navTarget = navTarget;
    }
    if (targetDate) {
      journalObject['targetDate'] = dayjs(targetDate).format(DATEFORMAT);
      setTargetDate(null);
    }
    setJournalSave(journalObject);
  }


  const handleReturn = () => {
    if (journalEntryUnsaved) {
      setJournalUpdateStatus({});
      handleJournalEntryApplyChanges(returnPath)
    } else {
      setSnackbarOpen(false);
      getJournalMap(dayjs(todayDate).format(DATEFORMAT));
      // window.scrollTo(0, 0);
      navigate(returnPath);
    }
  }


  const handleJournalEntryChange = (e) => {
    setJournalEntry(e.target.value)
    setJournalEntryUnsaved(e.target.value !== originalJournalEntry)
  }

  const handleCopyCalendarItemList = (text) => {
    navigator.clipboard.writeText(text);
    setJournalUpdateStatus({
      status: "success",
      message: "Calendar Items copied to the Clipboard"
    });
  }

  const handleAbandonJournalChanges = () => {
    setJournalEntry(originalJournalEntry);
    setJournalEntryUnsaved(false);
    handleDateChange(targetDate, false);
    // setConfirmationDialogOpen(false);
  }

  // Render //////////////////////////

  let text = "\n\n";
  let separator = ""

  return (
    <>
      <Stack
        paddingTop={1}
      >
        <AppBar
          position="static"
          margin={1}
          elevation={0}
          height={60}
          sx={{
            backgroundColor: CustomColorScheme['brown'],
          }}
        >
          <Stack
            direction="row"
            width={isMobile ? '100%' : 700}
          >
            {journalMap &&
              journalMap.calendarItems &&
              journalMap.tasks &&
              (journalMap.calendarItems.length > 0 || journalMap.tasks.length > 0)
              ?
              <IconButton onClick={handleIsExpand} >
                <ArrowDropDownIcon
                  id={iconId}
                  sx={{
                    color: CustomColorScheme['white'],
                  }}
                />
              </IconButton>
              :
              <Box width={40} />
            }
            <Typography
              variant="h6"
              component="div"
              display='flex'
              alignItems='center'
              onClick={handleIsExpand}
              sx={{
                flexGrow: 1,
                padding: 1,
                textAlign: "center",
                fontSize: isMobile ? 16 : 18,
              }}
            >
              {dayjs(journalDate).format(isMobile ? "ddd, M/D/YY" : "dddd, MMM D, YYYY")}
            </Typography>
            <JournalTooltip
              title={
                <Typography
                  variant="div"
                  sx={{
                    fontSize: 14,
                  }}
                >
                  Previous Date
                </Typography>
              } >
              <IconButton
                width={45}
                sx={{ marginTop: .56 }}
                onClick={() => handleDatePrev()}
              >
                <ChevronLeftIcon />
              </IconButton>
            </JournalTooltip>
            <JournalTooltip
              title={
                <Typography
                  variant="div"
                  sx={{
                    fontSize: 14,
                  }}
                >
                  Select A Date
                </Typography>
              } >
              <IconButton
                sx={{
                  marginTop: .35
                }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <CalendarMonthIcon />
              </IconButton>
            </JournalTooltip>
            <JournalTooltip
              title={
                <Typography
                  variant="div"
                  sx={{
                    fontSize: 14,
                  }}
                >
                  Next Date
                </Typography>
              } >
              <IconButton
                width={45}
                sx={{ marginTop: .56 }}
                onClick={() => handleDateNext()}
              >
                <ChevronRightIcon />
              </IconButton>
            </JournalTooltip>
          </Stack>
        </AppBar>
        {
          journalMap &&
          ((journalMap.calendarItems && journalMap.calendarItems.length > 0) || (journalMap.tasks && journalMap.tasks.length > 0))
          &&
          <Collapse in={isExpanded} >
            {/* <CalendarItems /> */}
            <Box
              sx={{
                backgroundColor: CustomColorScheme['datelight']
              }}
            >
              <Stack direction='row'>
                <Stack
                  padding={2}
                >

                  {
                    journalMap &&
                    journalMap.calendarItems &&
                    journalMap.calendarItems.map((item, idx) => {
                      text += separator + item.title;
                      separator = "\n\n"
                      return (
                        <Typography
                          key={idx}
                          variant="body2"
                          component="div"
                          margin={0}
                          color={CustomColorScheme['white']}
                        >
                          {item.title}
                        </Typography>
                      )
                    })

                  }
                  {
                    journalMap &&
                    journalMap.tasks &&
                    journalMap.tasks.map((task) => {
                      text += (task.parentTaskId ? "\n" : separator) + task.title;
                      separator = "\n\n"
                      return (
                        <Typography
                          key={task.taskId}
                          variant="body2"
                          component="div"
                          margin={0}
                          color={CustomColorScheme['yellow']}
                        >
                          {task.title}
                          {task.tasks
                            ? task.tasks.map((childTask, idx) => {
                              text += "\n" + childTask.title;
                              return (
                                <Typography
                                  key={idx}
                                  variant="body2"
                                  component="div"
                                  margin={0}
                                  color={CustomColorScheme['yellow']}
                                  marginLeft={1}
                                >
                                  <div key={childTask.taskId} >
                                    {childTask.title}
                                  </div>
                                </Typography>
                              )
                            })
                            : null
                          }
                        </Typography>
                      )
                    })
                  }
                </Stack>
                <Box
                  display='flex'
                  flexGrow={1}
                  alignItems='start'
                  justifyContent='end'
                  sx={{
                    backgroundColor: CustomColorScheme['datelight']
                  }}
                >
                  <Tooltip
                    title="Copy item list"
                  >
                    <IconButton
                      sx={{
                        marginRight: .5,
                        marginTop: .75,
                      }}
                      onClick={() => handleCopyCalendarItemList(text)}
                    >
                      <ContentCopyIcon
                        sx={{
                          color: CustomColorScheme['white']
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
            </Box>

          </Collapse>
        }
        <ButtonBar
          journalEntryUnsaved={journalEntryUnsaved}
        />
        <Box
          display='flex'
          flexGrow={1}
          backgroundColor={CustomColorScheme['task']}
        >
          <TextField
            rows={isMobile ? 18 : 28}
            outline='none'
            value={journalEntry}
            multiline
            onChange={handleJournalEntryChange}
            sx={{
              background: CustomColorScheme['white'],
              marginX: isMobile ? .5 : 0,
              width: '100%',
              cursor: "pointer",
              "& fieldset": {
                border: 'none',
              },
            }}
          />
        </Box>
        <ButtonBar
          journalEntryUnsaved={journalEntryUnsaved}
        />
      </Stack>

      {/**** non-render elements ****/}
      <Snackbar
        open={snackbarOpen && journalUpdateStatus && journalUpdateStatus.status}
        autoHideDuration={1250}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        onClose={() => {
          setJournalUpdateStatus({});
          setSnackbarOpen(false);
        }}
      >
        <Alert
          severity={journalUpdateStatus.status}
          variant="filled"
          elevation={6}>
          {journalUpdateStatus.message}
        </Alert>
      </Snackbar>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={calendarOpen}
        onClose={() => setAnchorEl(null)}
      >
        <DateCalendar
          value={dayjs(journalDate)}
          onChange={handleDateCalendarChange}
          sx={{
            "& .MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: CustomColorScheme['kellyGreen'],
              },
            }
          }}
        />
      </Popover>
      <JournalConfirmationAbandonDialog
        open={confirmationDialogOpen}
        setOpen={setConfirmationDialogOpen}
        onAbondonChanges={handleAbandonJournalChanges}
      />

    </>
  )
    ;

  ////////////////////////////////////////

  function ButtonBar(props) {
    const {
      journalEntryUnsaved
    } = props;
    return (
      <Paper
        elevation={0}
        square
        sx={{
          '&.MuiPaper-root': {
            backgroundColor: CustomColorScheme['task'],
            color: CustomColorScheme['white'],
            padding: 1,
          }
        }}

      >
        <Stack
          direction='row'
        >
          <Typography
            paddingY={.5}
            paddingX={1}
            sx={{
              cursor: 'pointer',
              borderRadius: 5,
              '&:hover': {
                backgroundColor: CustomColorScheme['transparentWhite25'],
              }
            }}
            onClick={handleReturn}
          >
            Return
          </Typography>
          <Typography
            paddingY={.5}
            paddingX={1}
            sx={{
              cursor: 'pointer',
              borderRadius: 5,
              color: journalEntryUnsaved ? "inherit" : CustomColorScheme['warmGrayMedium'],
              '&:hover': {
                backgroundColor: journalEntryUnsaved ? CustomColorScheme['transparentWhite25'] : 'inherit',
              }
            }}
            onClick={() => journalEntryUnsaved ? handleJournalEntryApplyChanges(null) : null}
          >
            Apply Changes
          </Typography>

          {
            isMobile &&
            <Box
              flexGrow={1}
              onClick={() => window.scrollTo(0, 0)}
              sx={{
                cursor: 'pointer',
              }}
            />}
        </Stack>
      </Paper>
    )
  }
}