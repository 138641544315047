import { useContext, createContext } from 'react';

export const TaskDialogContext = createContext();

export const TaskCardContext = createContext();

export const DateCardContext = createContext();

export const TaskDetailContext = createContext();

export const JournalContext = createContext();

export const AppbarContext = createContext();

export const HomeContext = createContext();

export const PreviousTasksContext = createContext();

export const CustomSwitchContext = createContext();

export const SettingsContext = createContext();

export const SearchContext = createContext();

export const TemplateContext = createContext();

export const TemplateTasksContext = createContext();

