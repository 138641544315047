import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material//DialogTitle";
import { CustomColorScheme } from "../components/CustomTheme";

export default function JournalConfirmationAbandonDialog(props) {

  const {
    open,
    onAbondonChanges,
    setOpen,
  } = props;


  // event handlers /////////

  const handleAbandonChanges = () => {
    setOpen(false);
    onAbondonChanges();
  }


  // render /////////////////

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <Box
        sx={{
          color: CustomColorScheme['black'],
          backgroundColor: CustomColorScheme['white']
        }} >
        <DialogTitle id="confirm-dialog" >Journal Entry Has Changed</DialogTitle>
        <DialogContent >The current Journal Entry has changed. Do you wish to <br />abandon your changes?</DialogContent>
        <DialogActions>
          <Box flexGrow={1} padding={2} display="flex" justifyContent="flex-end" alignItems="flex-end" >
            <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="flex-start">
              <Button
                id="save-button"
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: CustomColorScheme['datelight'],
                  '&:hover': {
                    backgroundColor: CustomColorScheme['datelight'],
                  }
                }}
                onClick={() => setOpen(false)
                }>
                Continue Editing
              </Button>
              <Button
                id="dont-save-button"
                variant="outlined"
                size="small"
                sx={{
                  color: CustomColorScheme['darkGreenBrown'],
                  borderColor: CustomColorScheme['darkGreenBrown'],
                  '&:hover': {
                    color: CustomColorScheme['white'],
                    borderColor: CustomColorScheme['darkRed'],
                    backgroundColor: CustomColorScheme['darkRed']
                  }
                }}
                onClick={handleAbandonChanges}>
                Abandon Changes
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

