// general
import '../App.css';
import { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs';

// mui
import Stack from "@mui/material/Stack";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// custom components
import { CustomColorScheme, DATEFORMAT } from '../components/CustomTheme';


/////////////////////////////


export default function HourMinutesControl(props) {

  const {
    providedMinutes,
    setProvidedMinutes,
    disabled,
    nowAndClear,
  } = props;

  const [minutes, setMinutes] = useState();
  const [hours, setHours] = useState();

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });

  const menuProps = {
    '&.Mui-selected, .Mui-focusVisible': {
      backgroundColor: CustomColorScheme['transparentLightOrange75']
    },
    '&:hover, .Mui-selected:hover, .MuiMenuItem-root:hover': {
      backgroundColor: CustomColorScheme['task']
    },
  }


  // useEffect //////////////

  useEffect(() => {
    if ((providedMinutes !== null) && (providedMinutes !== undefined)) {
      setMinutes((providedMinutes % 60) + 100);
      setHours(Math.floor(providedMinutes / 60) + 100);
    } else {
      setHours(null)
      setMinutes(null)
    }
  }, [providedMinutes])

  // event handlers /////////

  const handleMinutesChange = (newValue) => {
    if (newValue === -1) {
      handleClearAll();
    } else {
      setMinutes(newValue);
      let hrs = hours ? (hours - 100) : 0;
      let mins = newValue - 100;
      if (!hrs) {
        setHours(100);
      }
      setProvidedMinutes((hrs * 60) + mins);
    }
  }

  const handleHoursChange = (newValue) => {
    if (newValue === -1) {
      handleClearAll();
    } else {
      setHours(newValue);
      let mins = minutes ? minutes - 100 : 0;
      let hrs = newValue - 100;
      if (!mins) {
        setMinutes(100);
      }
      setProvidedMinutes((hrs * 60) + mins)
    }
  }

  const handleNowMinutes = () => {
    let min = dayjs().minute();
    let hr = dayjs().hour();

    min = ((((min + 7.5) / 15 | 0) * 15) % 60);

    setMinutes(min + 100);
    setHours(hr + 100);
    setProvidedMinutes((hr * 60) + min)
  }

  const handleClearAll = () => {
    setHours(null);
    setMinutes(null);
    setProvidedMinutes(null)
  }


  // functions //////////////

  function hourString(hour) {
    let returnVal;
    if (hour === 0) {
      returnVal = "12 am";
    } else if (hour === 12) {
      returnVal = '12 pm';
    } else if (hour >= 12) {
      returnVal = (hour - 12) + ' pm';
    } else {
      returnVal = hour + ' am';
    }
    return returnVal;
  }


  // render /////////////////

  return (
    <Stack
      direction="row"
      spacing={1}
      paddingTop={.5}
    >
      <Box
        width={100}
      >
        <FormControl
          variant='standard'
          fullWidth
        >
          <InputLabel
            shrink
            sx={{
              '&.MuiInputLabel-root': {
                color: CustomColorScheme['darkGreenBrown'],
              },
            }}
          >
            Hour
          </InputLabel>
          <Select
            label="Hour"
            value={hours ? hours : ''}
            disabled={disabled}
            sx={{
              borderBottomColor: CustomColorScheme['darkGreenBrown'],
              '&:hover:not(.Mui-disabled):before': {
                borderColor: CustomColorScheme['darkGreenBrown'],
              },
              '&:after, :before': {
                borderBottomColor: CustomColorScheme['darkGreenBrown'],
              },
            }}
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: CustomColorScheme['transparentLightOrange75']
                }
              }
            }}
            onChange={e => handleHoursChange(e.target.value)}
          >
            <MenuItem
              key={-1}
              value={-1}
              sx={{
                justifyContent: 'end',
              }}
            >
              {"clear"}
            </MenuItem>
            {
              Array(24).fill(0).map((_, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={i + 100}
                    sx={{
                      justifyContent: 'end',
                      backgroundColor: i >= 12 ? CustomColorScheme['transparentLightOrange45'] : 'inherit',
                      '&.Mui-selected': {
                        backgroundColor: CustomColorScheme['transparentLightOrange75']
                      },
                      '&:hover, &.Mui-selected:hover, &.MuiMenuItem-root:hover': {
                        backgroundColor: CustomColorScheme['task']
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: CustomColorScheme['task']
                      },
                    }}
                  >
                    {hourString(i)}
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </Box>
      <Box
        width={75}
      >
        <FormControl
          fullWidth
          variant='standard'
        >
          <InputLabel
            shrink
            sx={{
              '&.MuiInputLabel-root': {
                color: CustomColorScheme['darkGreenBrown'],
              },
            }}
          >
            Minutes
          </InputLabel>
          <Select
            label="Minutes"
            disabled={disabled}
            value={providedMinutes ? minutes : ''}
            sx={{
              borderBottomColor: CustomColorScheme['darkGreenBrown'],
              '&:hover:not(.Mui-disabled):before': {
                borderColor: CustomColorScheme['darkGreenBrown'],
              },
              '&:after, :before': {
                borderBottomColor: CustomColorScheme['darkGreenBrown'],
              },
            }}
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: CustomColorScheme['transparentLightOrange75']
                }
              }
            }}
            onChange={e => handleMinutesChange(e.target.value)}
          >
            <MenuItem key={-1} value={-1}>{"clear"}</MenuItem>
            <MenuItem key={0} value={100} sx={menuProps}>{"00"}</MenuItem>
            <MenuItem key={1} value={115} sx={menuProps}>{"15"}</MenuItem>
            <MenuItem key={2} value={130} sx={menuProps}>{"30"}</MenuItem>
            <MenuItem key={3} value={145} sx={menuProps}>{"45"}</MenuItem>

          </Select>
        </FormControl>
      </Box>
      {
        nowAndClear &&
        <>
          <Typography
            alignItems='center'
            sx={{
              display: 'flex',
              paddingLeft: isMobile ? .5 : 2,
              paddingTop: 2,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
            onClick={handleNowMinutes}
          >
            now
          </Typography>
          <Typography
            alignItems='center'
            sx={{
              display: 'flex',
              paddingLeft: 1,
              paddingTop: 2,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
            onClick={handleClearAll}
          >
            clear
          </Typography>
        </>
      }
      {
        isMobile &&
        <Box
          flexGrow={1}
          onClick={() => window.scrollTo(0, document.body.scrollHeight)}
        />
      }

    </Stack>
  );
}
