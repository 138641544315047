// general
import '../App.css';
import dayjs from 'dayjs';
import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { Helmet, HelmetProvider } from "react-helmet-async";

// mui
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Popover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';

/// icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// components
import { WhiteTooltip } from "../components/Tooltips";
import DateCard from "../components/DateCard";
import { TitleLabel } from '../components/CustomTheme'
import { CustomColorScheme, DATEFORMAT } from '../components/CustomTheme';
import { HomeContext } from '../components/AllContext';
import CustomSwitch from '../components/CustomSwitch';


/////////////////////////////


export default function Home(props) {
  const {
    includeCompletedTasks,
    setIncludeCompletedTasks,
    singleDateView,
    taskdate,
    taskdates,
  } = props;

  // document.title = "Michael's Tasklist";


  // constants //////////////

  const {
    getCustomTaskdates,
    getTaskdateMap,
    setSingleDateView,
    setTaskdate,
    setTodayJournalWithWeight,
    setJournalSave,
    settingsMap,
    todayJournalEntry,
    todayDate,
    showWeightControlOverride,
    setShowWeightControlOverride,
    weightValue,
    setWeightValue,
    getSettingsMap,
    defaultWeight,
    getDefaultWeight,
    setReturnPath,
    journalUpdateStatus,
    setJournalUpdateStatus,
    reset,
    setReset,
  } = useContext(HomeContext);

  const [weightRange, setWeightRange] = useState([168, 169, 170, 171, 172]);
  const [weightSelected, setWeightSelected] = useState(170);
  const [weightSelectedDecimal, setWeightSelectedDecimal] = useState(0);
  const [weightControlDisplay, setWeightControlDisplay] = useState("none");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { dateparam } = useParams();

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });
  const calendarOpen = Boolean(anchorEl);

  const currentTaskdate = taskdates && Object.keys(taskdates).length > 0 ? Object.keys(taskdates)[0] : null;

  const weightRegex = /\b\d{3}\.\d{1}\b/g;

  // useEffect //////////////

  useEffect(() => {
    if (isMobile && singleDateView) {
      if (dateparam) {
        console.log("Home useEffect1 dateparam", dateparam)
        setTaskdate(dayjs(dateparam));
      } else {
        setTaskdate(dayjs());
      }
    }
    setReturnPath(location.pathname)
    window.scrollTo(0, 0);
    getDefaultWeight();
  }, []);


  useEffect(() => {
    setShowWeightControlOverride(false)
    if (singleDateView && dateparam) {
      console.log("Home useEffect2 dateparam", dateparam)
      setTaskdate(dayjs(dateparam));
    } else {
      setTaskdate(dayjs());
    }
    getSettingsMap();
  }, [singleDateView]);


  useEffect(() => {
    let taskdateStr = taskdate ? dayjs(taskdate).format(DATEFORMAT) : dayjs().format(DATEFORMAT);
    computeWeightControlDisplay();
    getTaskdateMap(taskdateStr);
    if (isMobile) {
      let url = isMobile && singleDateView ? `/${dayjs(taskdate).format(DATEFORMAT)}` : "/"
      setReturnPath(url)
      navigate(url);
    }

  }, [taskdate]);


  useEffect(() => {
    if (isMobile && singleDateView && settingsMap) {
      let weightTop = parseInt(settingsMap.settings['weightRangeTop']);
      let weightBottom = parseInt(settingsMap.settings['weightRangeBottom']);
      let weightRange = [];
      let sel = parseInt(settingsMap.settings['weightSelected']);
      for (let i = weightBottom; i < weightTop; i++) {
        weightRange.push(i);
      }
      setWeightRange(weightRange);
    }
  }, [settingsMap]);


  useEffect(() => {
    setWeightSelected(defaultWeight);
  }, [defaultWeight]);


  useEffect(() => {
    computeWeightControlDisplay()
  }, [
    showWeightControlOverride,
    todayJournalEntry,
  ]);

  useEffect(() => {
    if (journalUpdateStatus && journalUpdateStatus.status) {
      setSnackbarOpen(true);
    }
  }, [journalUpdateStatus])

  useEffect(() => {
    if (reset) {
      setShowWeightControlOverride(false)
      setTaskdate(dayjs().format(DATEFORMAT));
      setReset(false)
    }
  }, [reset])

  // helper functions ///////

  function computeWeightControlDisplay() {
    if (isMobile && singleDateView) {
      let newDisplayValue = "controls";
      if (weightValue) {
        setWeightSelected(weightValue.split(".")[0])
        setWeightSelectedDecimal(weightValue.split(".")[1])
      }
      if (!todayJournalEntry || (typeof todayJournalEntry !== "string")) {
        newDisplayValue = "controls";
        setWeightSelected(defaultWeight)
        setWeightSelectedDecimal(0)
      } else if (todayJournalEntry.indexOf("\n") > -1) {
        newDisplayValue = "none";
      } else if (todayJournalEntry.match(weightRegex) && todayJournalEntry.match(weightRegex).length === 1) {
        if (!weightValue) {
          setWeightValue(todayJournalEntry.match(weightRegex)[0])
        }
        newDisplayValue = showWeightControlOverride ? "controls" : "link";
      } else {
        newDisplayValue = todayJournalEntry.match(weightRegex) ? "none" : "controls"
      }

      if (newDisplayValue !== weightControlDisplay) {
        setWeightControlDisplay(newDisplayValue);
      }
    }
    else {
      setWeightControlDisplay("none");
    }

  }

  // event handlers /////////

  const handleAllDateClick = () => {
    setShowWeightControlOverride(false)
    setSingleDateView(!singleDateView);
  }

  const handleCustomTaskdate = (newDate, selectionState) => {
    if (selectionState === 'finish') {
      setAnchorEl(null)
      navigate("/customdaterange")
      getCustomTaskdates(dayjs(newDate).format(DATEFORMAT));
    }
  }

  const handleDateChange = (newDate) => {
    setShowWeightControlOverride(false)
    setTaskdate(newDate);
  }

  const handleWeightSelected = (event) => {
    let pounds = event.target.value;
    setWeightSelected(pounds)
    setWeightValue(pounds + "." + weightSelectedDecimal)
  }

  const handleWeightSelectedDecimal = (event) => {
    let poundsDecimal = event.target.value;
    setWeightSelectedDecimal(poundsDecimal)
    setWeightValue(weightSelected + "." + poundsDecimal)
  }

  const handleTodayJournalWithWeight = () => {
    if (typeof todayJournalEntry === "string" && todayJournalEntry.replace("\n", "").replace("\r", "").length) {
      //  if there's a journal entry, modify it
      let currentVal = todayJournalEntry.match(weightRegex)[0]

      let journalObj = {
        "date": dayjs(todayDate).format(DATEFORMAT),
        "journalEntry": todayJournalEntry.replace(currentVal, weightValue)
      }
      setJournalSave(journalObj);
      setShowWeightControlOverride(false)
    } else {
      //  if there's no journal entry, create one
      setTodayJournalWithWeight(weightSelected, weightSelectedDecimal);
    }
  }

  const handleShowWeightControls = () => {

    setWeightSelected(weightValue.split(".")[0])
    setWeightSelectedDecimal(weightValue.split(".")[1])
    setShowWeightControlOverride(true)
  }



  // render /////////////////
  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>Michael's Tasklist</title>
        <meta property="og:title" content="Michael's Tasklist" />
        <meta property="og:image" content="apple-touch-icon.png" />
        <meta property="og:url" content={location.pathname} />
      </Helmet>
      {
        isMobile
          ?
          //  mobile display
          <>
            <Stack
              direction='row'
              flexGrow={1}
              alignItems='center'
              spacing={0}
              paddingY={1}
              paddingX={1}
            >
              <TitleLabel
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  paddingBottom: .5,
                }}
                onClick={() => handleDateChange(dayjs())}
              >
                Current Tasks
              </TitleLabel>
              <IconButton
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <CalendarMonthIcon
                  sx={{
                    color: CustomColorScheme['transparentWhite70'],
                    height: 22,
                  }}
                />
              </IconButton>
              <Box
                flexGrow={1}
              />
              {
                singleDateView &&
                <ChevronLeftIcon
                  sx={{
                    color: CustomColorScheme['white'],
                  }}
                  onClick={() => handleDateChange(dayjs(taskdate).add(-1, 'day'))}
                />
              }
              <Typography
                sx={{
                  color: CustomColorScheme['white'],
                  cursor: 'pointer',
                  fontSize: 15,
                  padding: 0,
                  margin: 0,
                }}
                onClick={handleAllDateClick}
              >
                {dayjs(taskdate).format("MM/DD/YYYY")}
              </Typography>
              {
                singleDateView
                  ?
                  <ChevronRightIcon
                    sx={{
                      color: CustomColorScheme['white'],
                    }}
                    onClick={() => handleDateChange(dayjs(taskdate).add(1, 'day'))}
                  />
                  :
                  <Box width={24} />
              }
            </Stack>
            {/* weight controls */}
            <Stack
              direction='row'
              paddingBottom={1}
            >
              <Box
                display='flex'
                flexGrow={1}
                justifyContent='start'
                alignItems='center'
              >
                {
                  dayjs().format(DATEFORMAT) === currentTaskdate &&
                  weightControlDisplay === "controls" &&
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={1}
                    paddingLeft={1}
                    paddingBottom={.5}
                  >
                    <FormControl
                      size='small'
                    >
                      <Select
                        value={weightSelected ? weightSelected : 170}
                        onChange={handleWeightSelected}
                        sx={{
                          width: 75,
                          backgroundColor: CustomColorScheme['task'],
                          borderRadius: 5,
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                        }}
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: CustomColorScheme['lightOrange'],
                            },
                          },
                        }}                      >
                        {
                          weightRange &&
                          weightRange.map((i, idx) => {
                            return (
                              <MenuItem key={i} value={i}>{i}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                    <FormControl
                      size='small'
                    >
                      <Select
                        value={weightSelectedDecimal}
                        onChange={handleWeightSelectedDecimal}
                        sx={{
                          backgroundColor: CustomColorScheme['task'],
                          borderRadius: 5,
                          '&:after': {
                            borderBottomColor: CustomColorScheme['black'],
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                        }}
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: CustomColorScheme['lightOrange'],
                            },
                          },
                        }}
                      >

                        {
                          Array(10).fill(0).map((_, i) => {
                            return (
                              <MenuItem key={i} value={i}>{"." + i}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                    <Typography
                      sx={{
                        borderRadius: 5,
                        paddingX: 1,
                        paddingY: .75,
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: CustomColorScheme['lightBrown'],
                        }
                      }}
                      onClick={handleTodayJournalWithWeight}
                    >Go</Typography>
                  </Stack>
                }
                {
                  dayjs().format(DATEFORMAT) === currentTaskdate &&
                  weightControlDisplay === "link" &&
                  <Typography
                    component='div'
                    variant='body1'
                    fontWeight='bold'
                    paddingLeft={.5}
                    sx={{
                      borderRadius: 5,
                      paddingX: 1,
                      paddingY: .75,
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: CustomColorScheme['lightBrown'],
                      }
                    }}
                    onClick={handleShowWeightControls}
                  >
                    {weightValue}
                  </Typography>
                }
              </Box>
            </Stack>

          </>
          :

          // desktop display
          <Stack
            direction="row"
            padding={1}
            paddingRight={0}
          >
            <TitleLabel sx={{
              fontSize: 20,
              fontWeight: "bold",
              minHeight: 38,
            }}>
              Current & Upcoming Tasks
            </TitleLabel>
            <WhiteTooltip
              title='Select a specific week'
            >
              <IconButton
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <CalendarMonthIcon
                  sx={{
                    color: CustomColorScheme['transparentWhite70'],
                    paddingTop: 0.25,
                  }}
                />
              </IconButton>
            </WhiteTooltip>
            <Box
              flexGrow={1}
            />
            <CustomSwitch
              stateValue={includeCompletedTasks}
              setStateValue={setIncludeCompletedTasks}
              displayMode={'light'}
              title={["Completed", "Show Completed"]}
            />
          </Stack>

      }

      {
        // render taskdates
        !taskdates || Object.keys(taskdates).length === 0
          ?
          <Box
            display='flex'
            flexGrow={1}
            height={350}
            justifyContent='center'
            alignItems='center'
            color={CustomColorScheme['yellow']}
          >
            <CircularProgress
              color='inherit'
            />
          </Box>
          :
          <Stack
            direction="column"
            spacing={.5}
          >
            {
              Object.keys(taskdates).map((date, idx) => {
                return (
                  <DateCard
                    key={idx}
                    calendarItems={taskdates[date].calendarItems}
                    date={date}
                    tasks={taskdates[date]['tasks']}
                    isLast={idx === Object.keys(taskdates).length - 1}
                    includeSwitch={(isMobile && idx === 0) && !(singleDateView && date < dayjs().format(DATEFORMAT))}
                    singleDateView={singleDateView}
                  />
                )
              })
            }

          </Stack>
      }

      {/**** non-render elements ****/}

      <Backdrop
        sx={{
          color: CustomColorScheme['white'],
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={false}
      // open={taskdates && Object.keys(taskdates).length > 0 && isLoading}
      >
        <CircularProgress
          sx={{
            color: CustomColorScheme['darkOrange']
          }}
        />
      </Backdrop>
      <Snackbar
        open={snackbarOpen && journalUpdateStatus && journalUpdateStatus.status}
        autoHideDuration={1250}
        onClose={() => {
          setJournalUpdateStatus({});
          setSnackbarOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }} >
        <Alert
          severity={journalUpdateStatus.status}
          variant="filled"
          elevation={6}
          sx={{
            '&.MuiAlert-filled': {
              backgroundColor: CustomColorScheme[journalUpdateStatus.status === 'success'
                ? 'darkestGreen'
                : 'inherit']
            },
          }}
        >
          {journalUpdateStatus.message}
        </Alert>
      </Snackbar>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={calendarOpen}
        onClose={() => setAnchorEl(null)}
      >
        <DateCalendar
          value={dayjs()}
          onChange={handleCustomTaskdate}
          sx={{
            "& .MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: CustomColorScheme['kellyGreen'],
              },
            }
          }}
        />
      </Popover>

    </HelmetProvider>
  );
}

