// general
import { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs';

// material ui components
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from "@mui/material/Dialog";

// icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SettingsBackupRestoreSharpIcon from '@mui/icons-material/SettingsBackupRestoreSharp';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

// custom components
import { CustomColorScheme, DATEFORMAT, DateCardColor } from "./CustomTheme"
import TaskCardList from "./TaskCardList";
import DeletionConfirmationDialog from "../dialogs/DeletionConfirmationDialog";
import { TaskCardContext } from "./AllContext";
import TaskContent from './TaskContent';


/////////////////////////////


const LinkSentences = ({ children }) => {
  return (
    <Stack>
      {
        children.replace("\r", "").split("\n\n").map((paragraph, idx) => {
          return (
            <Box
              sx={{
                paddingBottom: 2,
              }}
              key={idx} >
              <Stack>
                {paragraph.split("\n").map((sentence, idx) => {
                  return (
                    <Linkify
                      key={idx}
                    >
                      {sentence}
                    </Linkify>
                  )

                })}

              </Stack>
            </Box>
          )
        })
      }
    </Stack>
  )
}


const Linkify = ({ children }) => {
  const urlRegex =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
  const telRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/g;

  const addMarkup = word => {
    return word.match(urlRegex)
      ? `<a href="${word}" target="_blank" style="color:${DateCardColor};" >${word}</a>`
      : word.match(telRegex)
        ? `<a href=tel:${word.replace("-", "")} style="color:${DateCardColor};" >${word}</a>`
        : word;
  }

  const words = children.split(' ')
  const formatedWords = words.map((w, i) => addMarkup(w))
  const html = formatedWords.join(' ')
  return (
    <Box
      paddingY={0}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}


/////////////////////////////


export default function TaskCard(props) {

  const {
    task,
    position, // refers to position in tasklist: 'first', 'last', or 'only'
    includeCompletedTasks,
  } = props;

  const {
    setTaskComplete,
    setTaskReorder,
    setTaskDelete,
    setDetailTask,
    setReturnPath,
    setTaskUndelete,
    includeDeleted,
    setTaskUpdate,
  } = useContext(TaskCardContext)


  // useEffect //////////////

  useEffect(() => {
    if (task) {
      setIsCompleted(task.isCompleted)
    }
    // console.log("TaskCard useEffect", location.pathname)
    // setReturnPath(location.pathname);
  }, [task])


  // constants //////////////

  const [isExpanded, setIsExpanded] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [providedTask, setProvidedTask] = useState();

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const iconId = "rotationTarget" + task.taskId.toString();

  const location = useLocation();
  const navigate = useNavigate();

  const isHomepage = (location.pathname === "/") || location.pathname.startsWith("/202")
  const isNotSearch = location.pathname !== "/search";


  // event handlers /////////

  const handleTaskCreateClone = () => {
    let newTask = {
      taskId: null,
      parentTaskId: null,
      date: dayjs().format(DATEFORMAT),
      minutes: task.minutes,
      title: task.title,
      detail: task.detail,
      orderId: task.orderId,
      templateId: task.templateId,
      isCompleted: false,
      isEmphasis: task.isEmphasis,
      isClone: true
    }
    if (isMobile) {
      setDetailTask(newTask);
      navigate("/taskdetail")
    } else {
      setProvidedTask(newTask);
      setTaskDialogOpen(true)
    }
    setContextMenu(null);
  }

  const handleOpenTaskDialog = () => {
    let newTask = {
      taskId: task.taskId,
      parentTaskId: task.parentTaskId,
      date: task.date,
      minutes: task.minutes,
      title: task.title,
      detail: task.detail,
      orderId: task.orderId,
      templateId: task.templateId,
      isCompleted: task.isCompleted,
      isEmphasis: task.isEmphasis,
      isClone: false
    }
    if (isMobile) {
      setDetailTask(newTask);
      navigate("/taskdetail")
    } else {
      setProvidedTask(newTask);
      setTaskDialogOpen(true)
    }
    setContextMenu(null);
  }

  const handleNavClick = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 5,
          mouseY: event.clientY + 5,
        }
        : null,
    );
  };

  const handleNavClose = () => {
    setContextMenu(null);
  };

  const handleExpand = () => {
    setIsExpanded((isExpanded !== undefined) && task.detail ? !isExpanded : false);
    const rotatedIcon = document.getElementById(iconId)
    if (rotatedIcon) {
      rotatedIcon.style.transform = isExpanded ? "rotate(0deg)" : "rotate(180deg)";
    }
  };

  const handleTaskComplete = () => {
    let newIsCompleted = !isCompleted
    setIsCompleted(newIsCompleted)
    task.isCompleted = newIsCompleted;
    setTaskComplete(task);
    setContextMenu(null);
  }

  const handleTaskOrderFirst = () => {
    task.orderId = 0;
    setTaskReorder(task, location.pathname);
    setContextMenu(null);
  }

  const handleTaskOrderUp = () => {
    task.orderId = position.prev;
    setTaskReorder(task, location.pathname);
    setContextMenu(null);
  }

  const handleTaskOrderDown = () => {
    task.orderId = position.next;
    setTaskReorder(task, location.pathname);
    setContextMenu(null);
  }

  const handleTaskOrderLast = () => {
    task.orderId = 100;
    setTaskReorder(task, location.pathname);
    setContextMenu(null);
  }

  const handleConfirmTaskDelete = () => {
    setConfirmDeleteDialogOpen(true)
    setContextMenu(null);
  }

  const handleTaskDelete = () => {
    setConfirmDeleteDialogOpen(false);
    setTaskDelete(task);
  }

  const handleTaskUndelete = () => {
    setTaskUndelete(task.taskId)
    setContextMenu(null);
  }

  const handleTaskEmphasize = () => {
    let taskObj = {
      taskId: task.taskId,
      parentTaskId: task.parentTaskId,
      date: task.date,
      minutes: task.minutes,
      title: task.title,
      detail: task.detail,
      orderId: task.orderId,
      templateId: task.templateId,
      isCompleted: task.isCompleted,
      isEmphasis: !task.isEmphasis,
      isClone: false
    }
    setTaskUpdate(taskObj);
    setContextMenu(null);
  }

  // functions //////////////

  const minuteString = (minutes) => {
    let minuteStr = "";
    if (minutes) {
      let ampm = 'AM'
      let hours = Math.floor(minutes / 60);
      let minStr = (minutes % 60).toString().padStart(2, '0');
      if (hours > 11) {
        ampm = 'PM'
        hours -= 12;
      }
      if (hours === 0) {
        hours = 12
      }
      minuteStr = hours.toString() + ':' + minStr + ' ' + ampm;
      return minuteStr;
    }
  }


  // render /////////////////

  return (
    <>
      < Stack
        item={task.taskId}
        key={task.taskid}
      >
        <Paper
          component={Stack}
          height={40}
          sx={{
            backgroundColor: task.isDeleted
              ? CustomColorScheme['transparentTask75']
              : CustomColorScheme['task']
          }}
          paddingY={isMobile ? .5 : 0}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={0}
            margin={0}
          >
            {
              // dialog title
              isMobile ?
                <>
                  {/* mobile display: expansion arrow and checkbox */}
                  {
                    task.detail
                      ?
                      <Box
                        onClick={handleExpand}
                        display='flex'
                      >
                        <ArrowDropDownIcon
                          id={iconId}
                          sx={{
                            marginLeft: 1,
                          }}
                          onClick={handleExpand}
                        />
                      </Box>
                      :
                      <Box
                        width={32}
                      />
                  }
                  <Checkbox
                    value={isCompleted}
                    checked={isCompleted}
                    onChange={handleTaskComplete}
                    disabled={!isHomepage || task.isDeleted}
                    sx={{
                      transform: 'scale(.70)',
                      marginLeft: -1,
                      marginRight: -.25,
                      '&.Mui-checked': {
                        color: CustomColorScheme['warmGrayMedium'],
                      },
                      '&.Mui-disabled': {
                        color: CustomColorScheme['lightBrown'],
                      }
                    }}
                  />
                  {/* mobile display: title */}
                  <Stack
                    sx={{
                      flexGrow: 1,
                      maxWidth: isPortrait
                        ? task.parentTaskId ? 120 : 136
                        : task.parentTaskId ? 445 : 461,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      underline='hover'
                      color='inherit'
                      onClick={task.isDeleted ? null : handleOpenTaskDialog}
                      onContextMenu={handleNavClick}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontWeight: task.isEmphasis ? 'bold' : 'normal',
                      }}
                    >
                      {
                        task.minutes > 0 &&
                        <Typography
                          fontWeight="bold"
                          component='span'
                          marginRight={.75}
                        >
                          {minuteString(task.minutes)}
                        </Typography>
                      }
                      {task.title}
                    </Typography>
                  </Stack>
                </>
                :
                <>
                  {/* destop display: expansion arrow and checkbox */}
                  {
                    task.detail
                      ?
                      <IconButton
                        onClick={handleExpand}
                        sx={{
                          marginLeft: .5,
                        }}
                      >
                        <ArrowDropDownIcon id={iconId} />
                      </IconButton>
                      :
                      <Box
                        width={60}
                      />
                  }
                  <Checkbox
                    value={isCompleted}
                    checked={isCompleted}
                    onChange={handleTaskComplete}
                    disabled={!isHomepage || task.isDeleted}
                    // size='small'
                    sx={{
                      transform: 'scale(.75)',
                      marginX: 0,
                      '&.Mui-checked': {
                        color: CustomColorScheme['warmGrayMedium'],
                      },
                      '&.Mui-disabled': {
                        color: CustomColorScheme['lightBrown'],
                      }
                    }}
                  />
                  {/* desktop display: title */}
                  <Stack
                    direction='row'
                    sx={{
                      display: 'flex',
                      flexGrow: 1,
                      width: '100%'
                    }}
                  >
                    <Tooltip
                      paddingY={1}
                      title=
                      {
                        <Typography
                          component="div"
                          variant="body1"
                          style={{
                            fontSize: 14,
                            whiteSpace: 'pre-line',
                            wordWrap: "break-word",
                          }}
                        >
                          <div
                            style={{
                              color: CustomColorScheme['yellow'],
                            }}>
                            {task.taskId}
                          </div>
                          {
                            task.detail &&
                            <div>
                              {task.detail}
                            </div>
                          }
                        </Typography>
                      }
                      placement="bottom-start"
                    >
                      <Typography
                        variant="body1"
                        component="h3"
                        underline='hover'
                        color='inherit'
                        onClick={task.isDeleted ? null : handleOpenTaskDialog}
                        onContextMenu={handleNavClick}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: task.isEmphasis ? 'bold' : 'normal',
                          cursor: task.isDeleted ? 'inherit' : 'pointer',
                        }}
                      >
                        {
                          task.minutes > 0 &&
                          <Typography
                            fontWeight="bold"
                            component='span'
                            marginRight={.75}
                          >
                            {minuteString(task.minutes)}
                          </Typography>
                        }
                        {task.title}
                      </Typography>
                    </Tooltip>
                    <Box
                      sx={{
                        display: 'flex',
                        flexGrow: 1,
                      }}
                      onClick={handleExpand}
                      onContextMenu={handleNavClick}
                    />
                  </Stack>
                </>
            }
            {/* nav icons */}
            {
              !position.description &&
              <Box
                flexGrow={1}
              />
            }
            <Stack
              direction='row'
              justifyContent='end'
              width={position.description ? 105 : 'inherit'}
              margin={0}
              paddingRight={position.description ? 'inherit' : 1}
            >
              {
                isMobile &&
                <Box
                  width={35}
                >
                  <IconButton
                    onClick={handleNavClick}
                  >
                    <MoreHorizIcon
                      fontSize='small'
                    />
                  </IconButton>
                </Box>
              }
              {
                position.description &&
                isNotSearch &&
                !task.isDeleted &&
                <>
                  {
                    position.description === 'any'
                      || position.description === 'last'
                      || position.description === 'lastoftwo'
                      ?
                      <Box
                        width={isMobile ? 35 : 45}>
                        <IconButton
                          onClick={handleTaskOrderUp} >
                          <KeyboardArrowUpIcon
                            fontSize='sm'
                          />
                        </IconButton>
                      </Box>
                      :
                      <Box width={isMobile ? 35 : 45}>
                        <IconButton
                          disabled
                        >
                          <KeyboardArrowUpIcon
                            fontSize='sm'
                            style={{
                              color: CustomColorScheme['task']
                            }}
                          />
                        </IconButton>
                      </Box>
                  }
                  {
                    position.description === 'any'
                      || position.description === 'first'
                      || position.description === 'firstoftwo'

                      ?
                      <Box
                        width={isMobile ? 35 : 45}
                      >
                        <IconButton
                          onClick={handleTaskOrderDown}
                        >
                          <KeyboardArrowDownIcon
                            fontSize='sm' />
                        </IconButton>
                      </Box>
                      :
                      <Box
                        width={isMobile ? 35 : 45}
                      >
                        <IconButton disabled >
                          <KeyboardArrowDownIcon fontSize='sm'
                            style={{
                              color: CustomColorScheme['task']
                            }}
                          />
                        </IconButton>
                      </Box>
                  }
                </>
              }
            </Stack>
          </Stack>
        </Paper >

        {/* detail */}
        < Collapse
          in={isExpanded}
        >
          <Paper sx={{
            minHeight: 35,
            textAlign: "left",
            marginLeft: 2,
            marginTop: .25,
            paddingLeft: 1,
            paddingTop: 1.5,
            backgroundColor: task.isDeleted
              ? CustomColorScheme['transparentTask75']
              : CustomColorScheme['task']
          }}>
            {
              isMobile &&
              <Typography
                fontWeight='bold'
                paddingLeft={2}
              >
                {task.title}
              </Typography>
            }
            <Typography
              fontSize={isMobile ? 14 : 15}
              component="div"
              paddingLeft={2}
              style={{
                whiteSpace: 'pre-line',
                wordWrap: "break-word"
              }}
            >
              {
                task.detail &&
                task.detail.length > 0 &&
                <>
                  <LinkSentences>{task.detail}</LinkSentences>
                </>
              }
            </Typography>

          </Paper>
        </Collapse >

      </Stack >
      {
        task.tasks &&
        <Box paddingLeft={2}>
          <TaskCardList
            tasklist={task.tasks}
            includeCompletedTasks={includeCompletedTasks || ['/customdaterange', '/previoustasks'].includes(location.pathname)}
            includeDeleted={includeDeleted}
          />
        </Box>
      }
      <ContextMenu
        contextMenu={contextMenu}
        handleNavClose={handleNavClose}
        handleOpenTaskDialog={handleOpenTaskDialog}
        handleTaskComplete={handleTaskComplete}
        handleConfirmTaskDelete={handleConfirmTaskDelete}
        handleTaskCreateClone={handleTaskCreateClone}
        handleTaskOrderDown={handleTaskOrderDown}
        handleTaskOrderFirst={handleTaskOrderFirst}
        handleTaskOrderLast={handleTaskOrderLast}
        handleTaskOrderUp={handleTaskOrderUp}
        handleTaskUndelete={handleTaskUndelete}
        handleTaskEmphasize={handleTaskEmphasize}
        position={position.description}
        isNotSearch={isNotSearch}
        isDeleted={task.isDeleted}
        isEmphasis={task.isEmphasis}
      />

      {/**** non-render elements ****/}
      <DeletionConfirmationDialog
        open={confirmDeleteDialogOpen}
        setOpen={setConfirmDeleteDialogOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete\n${task.title}?`}
        onCancel={() => setConfirmDeleteDialogOpen(false)}
        onDelete={handleTaskDelete}
      />
      <Dialog
        open={taskDialogOpen}
        onClose={() => setTaskDialogOpen(false)}
      >
        <TaskContent
          providedTask={providedTask}
          onClose={() => setTaskDialogOpen(false)}
        />
      </Dialog>
    </>
  );
}


/////////////////////////////


function ContextMenu(props) {
  const {
    contextMenu,
    handleNavClose,
    handleOpenTaskDialog,
    handleTaskComplete,
    handleConfirmTaskDelete,
    handleTaskCreateClone,
    handleTaskOrderDown,
    handleTaskOrderFirst,
    handleTaskOrderLast,
    handleTaskOrderUp,
    handleTaskUndelete,
    handleTaskEmphasize,
    position,
    isNotSearch,
    isDeleted,
    isEmphasis,
  } = props;


  return (
    <Menu
      contextMenu={contextMenu}
      open={contextMenu !== null}
      onClose={handleNavClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? {
            top: contextMenu.mouseY,
            left: contextMenu.mouseX,
          }
          : undefined
      }
    >
      {
        isDeleted
          ?
          <MenuItem
          // onClick={handleTaskComplete}
          >
            <ListItemIcon>
              <SettingsBackupRestoreSharpIcon />
            </ListItemIcon>
            <Typography
              component="div"
              variant="inherit"
              width={100}
              onClick={handleTaskUndelete}
            >
              Undelete
            </Typography>
          </MenuItem>

          :
          <>
            <MenuItem
              onClick={handleTaskComplete}
            >
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <Typography
                component="div"
                variant="inherit"
                width={140}
              >
                Toggle Complete
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={handleConfirmTaskDelete}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <Typography
                component="div"
                variant="inherit"
                width={110}
              >
                Delete
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={handleTaskCreateClone}
            >
              <ListItemIcon>
                <ContentCopyIcon
                  fontSize="small"
                />
              </ListItemIcon>
              <Typography
                component="div"
                variant="inherit"
              >
                Clone
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={handleTaskEmphasize}
            >
              {
                isEmphasis
                  ?
                  <Typography
                    sx={{
                      paddingLeft: 4.5,
                    }}
                  >
                    De-emphasize
                  </Typography>
                  :
                  <>
                    <ListItemIcon>
                      <PriorityHighIcon
                        fontSize='small'
                      />
                    </ListItemIcon>
                    <Typography
                    >
                      Emphasize
                    </Typography>
                  </>

              }
            </MenuItem>
            {
              position &&
              <Divider />
            }
            {
              // move first
              position &&
              isNotSearch &&
              position !== 'first' &&
              position !== 'one' &&
              position !== 'firstoftwo' &&
              position !== 'lastoftwo' &&
              <MenuItem
                onClick={handleTaskOrderFirst}
              >
                <ListItemIcon>
                  <KeyboardDoubleArrowUpIcon />
                </ListItemIcon>
                <Typography
                  component="div"
                  variant="inherit"
                >
                  Set First
                </Typography>
              </MenuItem>
            }
            {
              // move up (decrease orderId )
              (
                isNotSearch && (
                  position === 'any'
                  || position === 'last'
                  || position === 'lastoftwo'
                )
              ) &&
              <MenuItem onClick={handleTaskOrderUp}>
                <ListItemIcon>
                  <KeyboardArrowUpIcon
                    fontSize="medium"
                  />
                </ListItemIcon>
                <Typography
                  component="div"
                  variant="inherit"
                >
                  Move Up
                </Typography>
              </MenuItem>
            }
            {
              // move down (increase orderId)
              (
                isNotSearch && (
                  position === 'any'
                  || position === 'first'
                  || position === 'firstoftwo'
                )
              ) &&
              <MenuItem
                onClick={handleTaskOrderDown}>
                <ListItemIcon>
                  <KeyboardArrowDownIcon />
                </ListItemIcon>
                <Typography
                  component="div"
                  variant="inherit"
                >
                  Move Down
                </Typography>
              </MenuItem>
            }
            {
              // move last
              position &&
              isNotSearch &&
              position !== 'last' &&
              position !== 'one' &&
              position !== 'firstoftwo' &&
              position !== 'lastoftwo' &&
              <MenuItem onClick={handleTaskOrderLast}>
                <ListItemIcon>
                  <KeyboardDoubleArrowDownIcon
                    fontSize="medium"
                  />
                </ListItemIcon>
                <Typography
                  component="div"
                  variant="inherit">
                  Set Last
                </Typography>
              </MenuItem>
            }
            {
              isNotSearch &&
              position !== 'one' &&
              <Divider />
            }
            <MenuItem
              onClick={handleOpenTaskDialog} >
              <ListItemIcon>
                <CreateSharpIcon />
              </ListItemIcon>
              <Typography
                component="div"
                variant="inherit">
                Edit
              </Typography>
            </MenuItem>
          </>
      }
    </Menu>
  );
}
