// general
import '../App.css';
import { useEffect, useContext, useState } from "react";
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

// mui
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

/// icons
import AddIcon from '@mui/icons-material/Add';

// components
import { WhiteTooltip } from "../components/Tooltips";
import { CustomColorScheme, DateCardColor, DATEFORMAT, TitleLabel } from '../components/CustomTheme';
import { TemplateContext } from '../components/AllContext';
import CustomSwitch from '../components/CustomSwitch';
import TemplateCard from '../components/TemplateCard';
import { TemplateTypes } from '../components/Resources';
import TemplateDialog from '../dialogs/TemplateDialog';

export default function Templates(props) {

  const {
    templates,
    getTemplates,
    setReturnPath,
    setTemplateCreate,
    setTemplateUpdate,
    setTemplateDelete,
    detailTemplate,
    setDetailTemplate,
    detailParentTemplate,
    setDetailParentTemplate,
    detailParentTemplates,
    setDetailParentTemplates,
    getTemplateTasks,
    taskTemplate,
    setTaskTemplate,
  } = useContext(TemplateContext);

  // constants //////////////

  // const [templateTypeId, setTemplateTypeId] = useState(-1);
  const [templateTypeId, setTemplateTypeIdValue] = useState(
    localStorage.getItem("templateTypeId") !== null
      ? JSON.parse(localStorage.getItem("templateTypeId"))
      : -1
  );

  function setTemplateTypeId(value) {
    localStorage.setItem("templateTypeId", JSON.stringify(value))
    setTemplateTypeIdValue(value)
  }

  //

  const [includeClosedTemplates, setIncludeClosedTemplatesValue] = useState(
    localStorage.getItem("setIncludeClosedTemplates") !== null
      ? JSON.parse(localStorage.getItem("setIncludeClosedTemplates"))
      : false
  )

  function setIncludeClosedTemplates(value) {
    localStorage.setItem("setIncludeClosedTemplates", JSON.stringify(value));
    setIncludeClosedTemplatesValue(value)
  }

  //

  const [page, setPageValue] = useState(
    localStorage.getItem("templatesPage") !== null
      ? JSON.parse(localStorage.getItem("templatesPage"))
      : 0
  )

  function setPage(value) {
    localStorage.setItem("templatesPage", JSON.stringify(value));
    setPageValue(value)
  }

  //
  // const [page, setPage] = useState(1)
  const [dialogOpen, setDialogOpen] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });
  const countPerPage = isMobile ? 6 : 8;

  const navigate = useNavigate();

  const emptyTemplate = {
    templateId: null,
    parentTemplateId: null,
    templateTypeId: templateTypeId > 0 ? templateTypeId : 1,
    title: null,
    description: null,
    minutes: null,
    orderId: null,
    nDays: null,
    month: null,
    monthName: null,
    dayOfWeek: null,
    dayOfWeekName: null,
    dayOfMonth: null,
    nthDayOfMonth: null,
    startdate: dayjs().format(DATEFORMAT),
    enddate: null,
    isClosed: false,
    templates: null,
  }

  const filteredTemplates =
    templates && templates.length > 0
      ?
      templates.filter(el => {
        return includeClosedTemplates
          ? templateTypeId === -1 || (el.templateTypeId === templateTypeId)
          : (templateTypeId === -1 && !el.isClosed) || (!el.isClosed && el.templateTypeId === templateTypeId)
      })
      : templates;

  const parentTemplates = templates && templates.filter(t => {
    return !t.parentTemplateId && t.enddate === null
  }).map((t) => {
    return {
      id: t.templateId,
      templateTypeId: t.templateTypeId,
      title: t.title
    }
  });

  const pageCount = filteredTemplates
    ? Math.ceil(filteredTemplates.length / countPerPage)
    : 0;

  const templatesThisPage = filteredTemplates
    ? filteredTemplates.slice((page - 1) * countPerPage, page * countPerPage)
    : null


  // useEffect //////////////

  useEffect(() => {
    setReturnPath("/templates")
    getTemplates();
  }, [])

  // event handlers /////////

  const handleTemplateTypeChange = (event) => {
    setTemplateTypeId(event.target.value)
    setPage(1)
  }

  const handlePaginationChange = (event, pge) => {
    if (templatesThisPage) {
      setPage(pge);
    }
  };

  const handleSwitchStateChange = (newState) => {
    setIncludeClosedTemplates(newState);
    setPage(1);
  }

  const handleDialogOpen = () => {
    if (isMobile) {
      setDetailTemplate(emptyTemplate);
      setDetailParentTemplate(emptyTemplate);
      setDetailParentTemplates(parentTemplates)
      navigate('/templatedetail');

    } else {
      setDialogOpen(true);
    }
  }

  // render//////////////////

  return (
    <>
      <Stack
        direction="row"
        padding={1}
        paddingRight={0}
      >
        <TitleLabel sx={{
          fontSize: 20,
          fontWeight: "bold",
          minHeight: 38,
        }}>
          Templates
        </TitleLabel>
        <Box
          flexGrow={1}
        />
        <CustomSwitch
          stateValue={includeClosedTemplates}
          setStateValue={handleSwitchStateChange}
          displayMode='light'
          title={["Incl Closed", "Include Closed"]}
        />
      </Stack>
      <Paper
        sx={{
          backgroundColor: DateCardColor,
          padding: 1.
        }}
      >
        <Stack
          direction='row'
        >
          <Box
            sx={{
              width: 150,
              backgroundColor: CustomColorScheme['transparentWhite25'],
              padding: .05,
              marginY: 1,
            }}
          >
            <Select
              value={templateTypeId}
              label="Type"
              variant='standard'
              sx={{
                paddingX: 1,
                color: CustomColorScheme['white'],
                borderBottomColor: CustomColorScheme['white'],
                width: '100%',
                '&:hover:not(.Mui-disabled):before': {
                  borderColor: CustomColorScheme['transparentWhite70'],
                },
                '&:after, :before': {
                  borderBottomColor: CustomColorScheme['transparentWhite70'],
                },
              }}
              onChange={handleTemplateTypeChange}
            >
              {
                TemplateTypes &&
                TemplateTypes.map((t, idx) => {
                  return (
                    <MenuItem
                      value={t.id}
                      key={idx}
                    >
                      {t.title}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </Box>
          <Box
            display='flex'
            flexGrow={1}
            justifyContent='end'
          >
            <WhiteTooltip
              title="Add a template"
            >
              <IconButton
                onClick={handleDialogOpen}
              >
                <AddIcon
                  sx={{
                    color: CustomColorScheme['transparentWhite70'],
                    '&:hover': {
                      color: CustomColorScheme['white'],
                    }
                  }}
                />
              </IconButton>
            </WhiteTooltip>
          </Box>
        </Stack>
        <Stack
          spacing={.5}
        >
          {
            templatesThisPage &&
            templatesThisPage.map((template, idx) => {
              return (
                <TemplateCard
                  key={idx}
                  template={template}
                  parentTemplate={template.parentTemplateId ? templates.find((t) => t.templateId === template.parentTemplateId) : null}
                  parentTemplates={parentTemplates}
                />
              )
            })
          }
        </Stack>
      </Paper>
      <Box
        display='flex'
        flexGrow={1}
        alignItems='center'
        justifyContent='center'
        paddingTop={2}
      >
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePaginationChange}
        />
      </Box>

      {/**** non-visible elements ****/}
      <TemplateDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        template={emptyTemplate}
        parentTemplate={emptyTemplate}
        parentTemplates={parentTemplates}
      />
    </>
  );

}

