// general
import '../App.css';
import { useEffect, useContext, useState } from "react";
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

// mui
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";

/// icons
import AddIcon from '@mui/icons-material/Add';

// components
import { WhiteTooltip } from "../components/Tooltips";
import { CustomColorScheme, DateCardColor, DATEFORMAT, TitleLabel } from '../components/CustomTheme';
import { TemplateTasksContext } from '../components/AllContext';
import CustomSwitch from '../components/CustomSwitch';
import TemplateCard from '../components/TemplateCard';
import { TemplateTypes } from '../components/Resources';
import TemplateDialog from '../dialogs/TemplateDialog';
import TaskCard from '../components/TaskCard';


export default function TemplateTasks(props) {
  const {
    taskTemplate,
    templateTasks,
  } = props;


  // constants //////////////

  const [page, setPage] = useState(1)

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
  const countPerPage = isMobile ? 6 : 8;
  const navigate = useNavigate();

  const pageCount = templateTasks
    ? Math.ceil(templateTasks.length / countPerPage)
    : 0;

  const tasksThisPage = templateTasks
    ? templateTasks.slice((page - 1) * countPerPage, page * countPerPage)
    : null

  // event handlers /////////

  const handlePaginationChange = (event, pge) => {
    if (templateTasks) {
      setPage(pge);
    }
  };


  return (
    <>
      <Stack
        direction="column"
        spacing={1}
        paddingY={2}
      >
        <Stack
          direction='row'
        >
          <Typography
            fontSize={20}
            color={CustomColorScheme['white']}
            flexGrow={1}
            paddingLeft={.25}
            paddingBottom={isMobile ? 2 : 0}
          >
            {`${taskTemplate.title} Tasks ${templateTasks ? " (" + templateTasks.length + ")" : ""}`}
          </Typography>
          <Typography
            sx={{
              color: CustomColorScheme['transparentWhite70'],
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              paddingTop: .4,
              minWidth: 90,
              textAlign: 'end',
              paddingRight: .25,
              '&:hover': {
                color: CustomColorScheme['white'],
              }
            }}
            onClick={() => navigate('/templates')}
          >
            {"< Return"}
          </Typography>
        </Stack>
        {
          tasksThisPage &&
          tasksThisPage.map((task, idx) => {
            return (
              <Box
                key={idx}
                color="white"
                fontSize={18}
                backgroundColor={CustomColorScheme['blueGreen']}
                paddingY={0}
                paddingX={1}
              >
                <Stack
                  direction='row'
                >
                  <Box
                    paddingY={1}
                  >
                    {dayjs(task.date).format("MMMM D, YYYY")}
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                  />
                  <Box
                    width={60}
                    onClick={() => window.scrollTo(0, 0)}
                    sx={{
                      cursor: 'pointer'
                    }}
                  />
                </Stack>
                <Paper
                  component={Stack}
                  sx={{
                    backgroundColor: DateCardColor,
                  }}
                  marginBottom={1}
                >

                  <TaskCard
                    key={idx}
                    task={task}
                    position={{
                      "prev": null,
                      "description": null,
                      "next": null
                    }}
                  />
                </Paper>
              </Box>
            )
          })
        }
      </Stack>
      <Box
        display='flex'
        flexGrow={1}
        alignItems='center'
        justifyContent='center'
        paddingTop={2}
      >
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePaginationChange}
          size={isMobile ? 'small' : ''}
        />
      </Box>
    </>

  )

}