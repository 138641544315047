// general
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

// custom components
import TaskContent from "../components/TaskContent";
import { DATEFORMAT } from "../components/CustomTheme";


export default function TaskDetail(props) {
  const {
    detailTask,
    returnPath
  } = props;


  // constants //////////////

  const navigate = useNavigate();

  // event handlers /////////

  const handleReturn = () => {
    let path = returnPath + "#" + dayjs(detailTask.date).format(DATEFORMAT)
    // console.log("TaskDetail handlReturn", path)
    // navigate(path);
    navigate(returnPath);
  }

  return (
    <TaskContent
      providedTask={detailTask}
      onClose={handleReturn}
    />
  );

}