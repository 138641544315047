// general
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// mui
import Box from "@mui/material/Box";

// custom components
import { TemplateContext } from "../components/AllContext";
import TemplateContent from "../components/TemplateContent";
import { CustomColorScheme } from '../components/CustomTheme';


export default function TemplateDetail(props) {
  const {
    returnPath
  } = props;

  const {
    templates,
    getTemplates,
    setReturnPath,
    setTemplateCreate,
    setTemplateUpdate,
    setTemplateDelete,
    detailTemplate,
    setDetailTemplate,
    detailParentTemplate,
    setDetailParentTemplate,
    detailParentTemplates,
    setDetailParentTemplates,
    getTemplateTasks,
    taskTemplate,
    setTaskTemplate,
  } = useContext(TemplateContext)

  // constants //////////////

  const navigate = useNavigate();

  // useEffect //////////////

  useEffect(() => {
    console.log('templatedeTemplateDetail')
  }, [])

  // event handlers /////////

  const handleReturn = () => {
    navigate(returnPath);
  }

  return (
    <Box
      sx={{
        backgroundColor: CustomColorScheme['white'],
        marginTop: 1
      }}
    >
      <TemplateContent
        template={detailTemplate}
        parentTemplate={detailParentTemplate}
        parentTemplates={detailParentTemplates}
        onClose={handleReturn}
      />
    </Box>
  );

}