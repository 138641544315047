// general
import '../App.css';
import { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs';

// mui
import Stack from "@mui/material/Stack";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// custom components
import { CustomColorScheme, DATEFORMAT } from '../components/CustomTheme';
import { TemplateContext } from '../components/AllContext';
import HourMinutesControl from './HourMinutesControl';
import DeletionConfirmationDialog from '../dialogs/DeletionConfirmationDialog';
import {
  TemplateTypes,
  daysOfMonth,
  daysOfWeek,
  frequencyDays,
  orderIdList,
  businessRules,
} from '../components/Resources';


export default function TemplateContent(props) {
  const {
    template,
    parentTemplate,
    parentTemplates,
    onClose
  } = props;

  const {
    templates,
    getTemplates,
    setReturnPath,
    setTemplateCreate,
    setTemplateUpdate,
    setTemplateDelete,
    detailTemplate,
    setDetailTemplate,
    detailParentTemplate,
    setDetailParentTemplate,
    detailParentTemplates,
    setDetailParentTemplates,
    getTemplateTasks,
    taskTemplate,
    setTaskTemplate,
  } = useContext(TemplateContext);

  // constants //////////////

  const [templateId, setTemplateId] = useState();
  const [parentTemplateId, setParentTemplateId] = useState(0);
  const [templateTypeId, setTemplateTypeId] = useState();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [nDays, setNDays] = useState(0);
  const [month, setMonth] = useState(0);
  const [dayOfWeek, setDayOfWeek] = useState(0);
  const [dayOfMonth, setDayOfMonth] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [confirmDialogOpen, setConfirmDeleteOpen] = useState(false);


  const [dateName, setDateName] = useState("startDate");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElBusRules, setAnchorElBusRules] = useState(null)

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const calendardate = dateName === 'startDate' ? startDate : endDate;
  const calendarOpen = Boolean(anchorEl);
  const busRulesOpen = Boolean(anchorElBusRules);

  const datepickerFormat = isMobile ? 'M/D/YYYY' : 'dddd,  MMMM D, YYYY';
  const isClosed = template.isClosed;
  const isReadOnly = isClosed || templateId > 0;
  const hasChildren = (template.templates && template.templates.length > 0);

  const filteredParentTemplates =
    parentTemplates && parentTemplates.length > 0
      ?
      parentTemplates.filter((t) => {
        return t.templateTypeId === templateTypeId
      })
      :
      null;

  // useEffect //////////////

  useEffect(() => {
    populateConstants()
  }, [template])

  // event handlers /////////

  const handleTemplateTypeChange = (event) => {
    console.log(event.target.value)
    setTemplateTypeId(event.target.value)
  }

  const handleCalendarOpen = (event, datename) => {
    setDateName(datename);
    setAnchorElBusRules(null);
    setAnchorEl(event.currentTarget);
  }

  const handleCalendarClose = (event) => {
    setAnchorEl(null);
  }

  const handleBusinessRuleOpen = (event) => {
    setAnchorEl(null);
    setAnchorElBusRules(event.currentTarget);
  }

  const handleBusinessRuleClose = (event) => {
    setAnchorElBusRules(null);
  }

  const handleClearEnddate = (event) => {
    event.stopPropagation();
    setEndDate(null)
  }

  const handleDateChange = (newValue, selectionState) => {
    if (selectionState === 'finish') {
      setAnchorEl(null)
      if (dateName === 'startDate') {
        setStartDate(newValue);
      } else {
        setEndDate(newValue);
      }
    }
  }

  const handleDialogClose = () => {
    populateConstants();
    onClose()
  }

  const handleTemplateSave = () => {
    let t = {
      templateId: templateId,
      parentTemplateId: parentTemplateId,
      templateTypeId: templateTypeId,
      title: title,
      description: description,
      minutes: minutes,
      orderId: orderId,
      nDays: nDays,
      month: month,
      dayOfWeek: dayOfWeek,
      dayOfMonth: dayOfMonth,
      startdate: dayjs(startDate).format(DATEFORMAT),
      enddate: endDate ? dayjs(endDate).format(DATEFORMAT) : null,
    }
    if (templateId) {
      setTemplateUpdate(t);
    } else {
      setTemplateCreate(t);
    }
    onClose();
  }

  const handleTemplateDelete = () => {
    setConfirmDeleteOpen(false)
    setTemplateDelete(template);
    onClose()
  }


  // functions //////////////

  function populateConstants() {
    if (template) {
      setTemplateId(template.templateId);
      setParentTemplateId(template.parentTemplateId);
      setTemplateTypeId(template.templateTypeId);
      setTitle(template.title);
      setDescription(template.description);
      setMinutes(template.minutes);
      setOrderId(template.orderId);
      setNDays(template.nDays);
      setMonth(template.month);
      setDayOfWeek(template.dayOfWeek);
      setDayOfMonth(template.dayOfMonth);
      setStartDate(dayjs(template.startdate))
      setEndDate(template.enddate ? dayjs(template.enddate) : null);
    }
  }

  function hourString(hour) {
    let returnVal;
    if (hour === 0) {
      returnVal = "12 am";
    } else if (hour === 12) {
      returnVal = '12 pm';
    } else if (hour >= 12) {
      returnVal = (hour - 12) + ' pm';
    } else {
      returnVal = hour + ' am';
    }
    return returnVal;
  }

  // components /////////////
  const TypeControl = () => {
    return (
      <Box
        sx={{
          width: 150,
          marginTop: hasChildren ? 3 : 0,
          backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
          padding: .75,
          paddingTop: .5

        }}
      >
        {
          templateId || isReadOnly
            ?
            <TextField
              label="Type"
              variant="standard"
              disabled
              value={templateTypeId && TemplateTypes.find(t => t.id === templateTypeId) ? TemplateTypes.find(t => t.id === templateTypeId).title : ""}
              inputProps={{
                readOnly: true,
              }}
              sx={{
                paddingX: 1.25,
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  marginLeft: 1.5,
                  marginTop: 0.5,
                }
              }}
            />
            :
            <FormControl
              fullWidth
              variant='standard'
            >
              <InputLabel
                sx={{
                  marginLeft: .75,
                  paddingLeft: .75,
                  '&.MuiInputLabel-root': {
                    color: CustomColorScheme['darkGreenBrown'],
                  },
                }}
              >
                Type
              </InputLabel>
              <Select
                value={templateTypeId}
                label="Type"
                variant='standard'
                sx={{
                  paddingX: 1.25,
                  borderBottomColor: CustomColorScheme['darkGreenBrown'],
                  backgroundColor: CustomColorScheme['warmGrayLight'],
                  '&:hover:not(.Mui-disabled):before': {
                    borderColor: CustomColorScheme['darkGreenBrown'],
                  },
                  '&:after, :before': {
                    borderBottomColor: CustomColorScheme['darkGreenBrown'],
                  },
                }}
                onChange={handleTemplateTypeChange}
              >
                {
                  TemplateTypes &&
                  TemplateTypes.map((t, idx) => {
                    return (
                      t.id > 0 &&
                      <MenuItem
                        value={t.id}
                        key={idx}
                      >
                        {t.title}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
        }
      </Box>
    );
  }

  // frequency
  const NDaysControl = () => {
    if (templateTypeId === 3)
      return (
        <Box
          sx={{
            width: isMobile ? '45.5%' : 150,
            backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
            padding: .75,
            paddingTop: .5
          }}
        >
          {
            isClosed
              ?
              <TextField
                label="Frequency"
                variant="standard"
                disabled
                value={nDays && frequencyDays.find(nd => nd.id === nDays) ? frequencyDays.find(nd => nd.id === nDays).label : nDays}
                inputProps={{
                  readOnly: true,
                }}
                sx={{
                  paddingX: 1.5,
                }}
                InputLabelProps={{
                  // shrink: true,
                  sx: {
                    marginLeft: 1.5,
                    marginTop: 0.5,
                  }
                }}
              />
              :
              <FormControl
                fullWidth
                variant='standard'
              >
                <InputLabel
                  shrink
                  sx={{
                    marginLeft: .75,
                    '&.MuiInputLabel-root': {
                      color: CustomColorScheme['darkGreenBrown'],
                    },
                  }}
                >
                  Frequency
                </InputLabel>
                <Select
                  value={nDays}
                  label="Day"
                  variant='standard'
                  sx={{
                    paddingX: .5,
                    borderBottomColor: CustomColorScheme['darkGreenBrown'],
                    '&:hover:not(.Mui-disabled):before': {
                      borderColor: CustomColorScheme['darkGreenBrown'],
                    },
                    '&:after, :before': {
                      borderBottomColor: CustomColorScheme['darkGreenBrown'],
                    },
                  }}
                  onChange={(e) => setNDays(e.target.value)}
                >
                  {
                    frequencyDays &&
                    frequencyDays.map((f, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          value={f.id}
                        >
                          {f.label}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
          }
        </Box>
      );
  }

  const DayOfMonthControl = () => {
    if (templateTypeId === 2)
      return (
        <Box
          sx={{
            width: isMobile ? '20%' : 75,
            backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
            padding: .75,
            paddingTop: .5
          }}
        >
          {
            isClosed
              ?
              <TextField
                label="Day"
                variant="standard"
                value={dayOfMonth}
                disabled
                inputProps={{
                  readOnly: true,
                }}
                sx={{
                  paddingX: 1.5,
                }}
                InputLabelProps={{
                  // shrink: true,
                  sx: {
                    marginLeft: 1.5,
                    marginTop: 0.5,
                  }
                }}
              />
              :
              <FormControl
                fullWidth
                variant='standard'
              >
                <InputLabel
                  shrink
                  sx={{
                    marginLeft: .75,
                    paddingLeft: .75,
                    '&.MuiInputLabel-root': {
                      color: CustomColorScheme['darkGreenBrown'],
                    },
                  }}
                >
                  Day
                </InputLabel>
                <Select
                  value={dayOfMonth}
                  label="Day"
                  variant='standard'
                  sx={{
                    paddingX: .5,
                    borderBottomColor: CustomColorScheme['darkGreenBrown'],
                    width: 75,
                    '&:hover:not(.Mui-disabled):before': {
                      borderColor: CustomColorScheme['darkGreenBrown'],
                    },
                    '&:after, :before': {
                      borderBottomColor: CustomColorScheme['darkGreenBrown'],
                    },
                  }}
                  onChange={(e) => setDayOfMonth(e.target.value)}
                >
                  {
                    daysOfMonth &&
                    daysOfMonth.map((day) => {
                      return (
                        <MenuItem
                          key={day}
                          value={day}
                        >
                          {day !== 100 ? day : 'last'}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
          }
        </Box>
      );
  }

  // weekday 
  const DayOfWeekControl = () => {
    if (templateTypeId === 1) {
      return (
        <Box
          sx={{
            // width: isMobile ? 132 : 114,
            width: isMobile ? '45.5%' : 114,
            backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
            padding: .75,
            paddingTop: .5
          }}
        >
          {
            isReadOnly
              ?
              <TextField
                label="Weekday"
                variant="standard"
                disabled
                value={dayOfWeek && daysOfWeek.find(d => d.id === dayOfWeek) ? daysOfWeek.find(d => d.id === dayOfWeek).weekday : ""}
                inputProps={{
                  readOnly: true,
                }}
                sx={{
                  paddingX: 1.25,
                }}
                InputLabelProps={{
                  // shrink: true,
                  sx: {
                    marginLeft: 1.25,
                    marginTop: 0.5,
                  }
                }}
              />
              :
              <FormControl
                fullWidth
                variant='standard'
              >
                <InputLabel
                  shrink
                  sx={{
                    marginLeft: .75,
                    paddingLeft: .75,
                    '&.MuiInputLabel-root': {
                      color: CustomColorScheme['darkGreenBrown'],
                    },
                  }}
                >
                  Weekday
                </InputLabel>
                <Select
                  value={dayOfWeek}
                  label="Day"
                  variant='standard'
                  sx={{
                    paddingX: 1.25,
                    borderBottomColor: CustomColorScheme['darkGreenBrown'],
                    '&:hover:not(.Mui-disabled):before': {
                      borderColor: CustomColorScheme['darkGreenBrown'],
                    },
                    '&:after, :before': {
                      borderBottomColor: CustomColorScheme['darkGreenBrown'],
                    },
                  }}
                  onChange={(e) => setDayOfWeek(e.target.value)}
                >
                  {
                    daysOfWeek &&
                    daysOfWeek.map((d, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          value={d.id}
                        >
                          {d.weekday}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
          }
        </Box>
      )
    }
  }

  // orderId
  const OrderIdControl = () => {
    return (
      <Box
        sx={{
          width: isMobile ? '20%' : 75,
          backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
          padding: .75,
          paddingTop: .5
        }}
      >
        {
          isClosed
            ?
            <TextField
              label="Order"
              variant="standard"
              value={orderId}
              disabled
              inputProps={{
                readOnly: true,
              }}
              sx={{
                paddingX: 1.25,
              }}
              InputLabelProps={{
                sx: {
                  // shrink: true,
                  marginLeft: 1.25,
                  marginTop: 0.5,
                }
              }}
            />
            :
            <FormControl
              fullWidth
              variant='standard'
            >
              <InputLabel
                shrink
                sx={{
                  marginLeft: .75,
                  '&.MuiInputLabel-root': {
                    color: CustomColorScheme['darkGreenBrown'],
                  },
                }}
              >
                Order
              </InputLabel>
              <Select
                value={orderId}
                label="Day"
                variant='standard'
                sx={{
                  paddingX: .75,
                  borderBottomColor: CustomColorScheme['darkGreenBrown'],
                  '&:hover:not(.Mui-disabled):before': {
                    borderColor: CustomColorScheme['darkGreenBrown'],
                  },
                  '&:after, :before': {
                    borderBottomColor: CustomColorScheme['darkGreenBrown'],
                  },
                }}
                onChange={(e) => setOrderId(e.target.value)}
              >
                {
                  orderIdList &&
                  orderIdList.map((idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        value={idx}
                      >
                        {idx}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
        }
      </Box>
    )
  }

  // parenttemplate
  const ParentTemplateControl = () => {
    if (!hasChildren)
      return (
        <Box
          sx={{
            width: isMobile ? 230 : 290,
            backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
            padding: .75,
            paddingTop: .5
          }}
        >
          {
            isClosed
              ?
              <TextField
                label="Parent Template"
                variant="standard"
                value={parentTemplate ? parentTemplate.title : 'none'}
                disabled
                inputProps={{
                  readOnly: true,
                }}
                sx={{
                  paddingX: 1.25,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    marginLeft: 1.25,
                    marginTop: 0.5,
                    '&.Mui-focused': {
                      color: CustomColorScheme['darkGreenBrown'],
                    },
                  }
                }}
              />
              :
              <FormControl
                fullWidth
                variant='standard'
              >
                <InputLabel
                  sx={{
                    marginLeft: .75,
                    paddingLeft: .75,
                    '&.MuiInputLabel-root': {
                      color: CustomColorScheme['darkGreenBrown'],
                    },
                  }}
                >
                  Parent Template
                </InputLabel>
                <Select
                  value={parentTemplateId}
                  label="Day"
                  variant='standard'
                  sx={{
                    paddingX: 1.25,
                    borderBottomColor: CustomColorScheme['darkGreenBrown'],
                    '&:hover:not(.Mui-disabled):before': {
                      borderColor: CustomColorScheme['darkGreenBrown'],
                    },
                    '&:after, :before': {
                      borderBottomColor: CustomColorScheme['darkGreenBrown'],
                    },
                  }}
                  onChange={(e) => setParentTemplateId(e.target.value)}
                >
                  {
                    filteredParentTemplates &&
                    filteredParentTemplates.map((pt, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          value={pt.id}
                        >
                          {pt.title}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
          }
        </Box>
      )
  }


  // render /////////////////

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          border: isMobile ? 0 : 1,
          borderBottom: 0,
          borderColor: CustomColorScheme['white'],
          backgroundColor: CustomColorScheme[isMobile ? 'task' : 'darkestGreen'],
        }}
      >
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
        >
          {
            <Typography
              sx={{
                flexGrow: 1,
                padding: 1,
                paddingLeft: 2.5,
                fontSize: 22,
                fontWeight: isMobile ? 'normal' : 'bold',
                color: CustomColorScheme[isMobile ? 'darkestGreen' : 'white'],
              }}
            >
              {template.templateId ? "Edit Template" : "Create Template"}
            </Typography>
          }
          <Tooltip
            arrow
            title="Business Rules"
          >
            <IconButton
              onClick={handleBusinessRuleOpen}
            >
              <HelpOutlineIcon
                sx={{
                  color: CustomColorScheme[isMobile ? 'darkestGreen' : 'transparentWhite70'],
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          minWidth: isMobile ? 'inherit' : 500,
          paddingX: isMobile ? 1 : 2,
          paddingY: 2.5,
        }}
      >
        <Stack
          width='100%'
          spacing={2}
        >
          <TypeControl />
          <ParentTemplateControl />

          {/* title */}
          <Box
            sx={{
              width: isMobile ? 230 : 290,
              backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
              padding: .75,
              paddingTop: .5,
              paddingBottom: .75,
            }}
          >
            <TextField
              label="Title"
              variant="standard"
              value={title}
              disabled={isClosed}
              onChange={(e) => setTitle(e.target.value)}
              inputProps={{
                readOnly: isClosed,
                paddingLeft: 2,
              }}
              sx={{
                paddingLeft: 1.25,
                width: '97%',
                '& .MuiInput-underline:after': {
                  borderBottomColor: CustomColorScheme['darkGreenBrown'],
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  marginLeft: 1.5,
                  marginTop: 0.5,
                  '&.Mui-focused': {
                    color: CustomColorScheme['darkGreenBrown'],
                  },
                }
              }}
            />
          </Box>

          {/* description */}
          <Box
            fullWidth
            sx={{
              paddingX: .5,
              backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
              padding: .75,
              paddingTop: .5,
              paddingBottom: .75,
            }}
          >
            <TextField
              label="Description"
              variant="standard"
              value={description ? description : isClosed ? 'none' : ''}
              multiline
              rows={isClosed && !description ? 1 : isMobile ? 4 : 6}
              maxRows={10}
              disabled={isClosed}
              onChange={(e) => setDescription(e.target.value)}
              inputProps={{
                readOnly: isClosed,
              }}
              sx={{
                backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
                paddingLeft: 1.25,
                width: '97%',
                '& .MuiInput-underline:after': {
                  borderBottomColor: CustomColorScheme['darkGreenBrown'],
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  marginLeft: 1.5,
                  marginTop: 0.5,
                  '&.Mui-focused': {
                    color: CustomColorScheme['darkGreenBrown'],
                  },
                }
              }}
            />
          </Box>


          {
            isMobile &&
            <Stack
              direction='row'
              spacing={1}
              paddingLeft={1}
            >
              <HourMinutesControl
                providedMinutes={minutes}
                setProvidedMinutes={setMinutes}
                disabled={isClosed}
              />
            </Stack>

          }
          {/* control block */}
          <Stack
            direction='row'
            spacing={1}
            paddingLeft={1}
          >

            {
              !isMobile &&
              <HourMinutesControl
                providedMinutes={minutes}
                setProvidedMinutes={setMinutes}
                disabled={isClosed}
              />
            }
            <DayOfMonthControl />
            <DayOfWeekControl />
            <NDaysControl />
            <OrderIdControl />
          </Stack>

          {/* startdate */}
          <Stack
            direction={isMobile ? 'row' : 'column'}
            spacing={isMobile ? 1 : 2}
          // border={1}
          >
            <TextField
              label='Start Date'
              onClick={(e) => isReadOnly ? null : handleCalendarOpen(e, "startDate")}
              value={dayjs(startDate).format(datepickerFormat)}
              variant="standard"
              disabled={isReadOnly}
              inputProps={{
                readOnly: isReadOnly,
              }}
              sx={{
                paddingX: 1,
                paddingBottom: .5,
                // width: isMobile ? '40%' : 290,
                width: isMobile ? '45%' : 290,
                backgroundColor: CustomColorScheme[isReadOnly ? 'inherit' : 'warmGrayLight'],
                input: {
                  cursor: 'pointer',
                  paddingY: 1,
                },
                '&.MuiTextField-root': {
                  ':hover': {
                    cursor: 'pointer',
                  }
                },
                '& .MuiInput-underline, .MuiInput-underline:after': {
                  borderBottomColor: CustomColorScheme['black'],
                },
              }}
              InputLabelProps={{
                sx: {
                  shrink: true,
                  marginLeft: 1,
                  marginTop: 0.5,
                  '&.Mui-focused': {
                    color: CustomColorScheme['darkGreenBrown'],
                  },
                }
              }}
              InputProps={{
                readOnly: isReadOnly,
                endAdornment:
                  <CalendarMonthIcon
                    onClick={(e) => isReadOnly ? null : handleCalendarOpen(e, "startDate")}
                    cursor='pointer'
                    sx={{
                      color: CustomColorScheme[isReadOnly ? 'warmGray' : 'darkGreenBrown'],
                    }}
                  />
              }}
            />


            {/* enddate */}
            <TextField
              label='End Date'
              onClick={(e) => isClosed ? null : handleCalendarOpen(e, "endDate")}
              value={endDate ? dayjs(endDate).format(datepickerFormat) : ''}
              variant="standard"
              disabled={isClosed}
              inputProps={{
                readOnly: isClosed,
              }}
              sx={{
                paddingX: 1,
                paddingBottom: .5,
                backgroundColor: CustomColorScheme[isClosed ? 'inherit' : 'warmGrayLight'],
                width: isMobile ? '45%' : 290,
                input: {
                  cursor: 'pointer',
                  paddingY: 1,
                },
                '&.MuiTextField-root': {
                  ':hover': {
                    cursor: 'pointer',
                  }
                },
                '& .MuiInput-underline, .MuiInput-underline:after': {
                  borderBottomColor: CustomColorScheme['black'],
                },
              }}
              InputLabelProps={{
                sx: {
                  shrink: true,
                  marginLeft: 1,
                  marginTop: 0.5,
                  '&.Mui-focused': {
                    color: CustomColorScheme['darkGreenBrown'],
                  },
                }
              }}
              InputProps={{
                readOnly: isClosed,
                endAdornment:
                  <>
                    {
                      !isClosed && endDate &&
                      <CloseIcon
                        onClick={isClosed ? null : handleClearEnddate}
                        sx={{
                          width: 20,
                          marginRight: .5,
                          cursor: 'pointer',
                          color: CustomColorScheme[isClosed ? 'warmGray' : 'darkGreenBrown'],
                        }}
                      />
                    }
                    <CalendarMonthIcon
                      onClick={(e) => isClosed ? null : handleCalendarOpen(e, "endDate")}
                      sx={{
                        cursor: 'pointer',
                        color: CustomColorScheme[isClosed ? 'warmGray' : 'darkGreenBrown'],
                      }}
                    />
                  </>

              }}
            />
          </Stack>
        </Stack>
      </Box>

      {/* button bar */}
      <Stack
        direction="row"
        spacing={3}
        padding={1.4}
        display='flex'
        backgroundColor={CustomColorScheme['task']}
        justifyContent={isMobile ? 'space-evenly' : 'end'}
      >
        <Typography
          onClick={handleTemplateSave}
          sx={{
            color: CustomColorScheme['darkestBrown'],
            fontSize: 18,
            cursor: 'pointer',
            '&:hover': {
              color: CustomColorScheme['black']
            }
          }}
        >
          Save
        </Typography>
        {
          template.templateId &&
          <Typography
            onClick={() => setConfirmDeleteOpen(true)}
            sx={{
              color: CustomColorScheme['darkestBrown'],
              fontSize: 18,
              cursor: 'pointer',
              '&:hover': {
                color: CustomColorScheme['red']
              }
            }}
          >
            Delete
          </Typography>
        }
        <Typography
          onClick={handleDialogClose}
          sx={{
            color: CustomColorScheme['darkestBrown'],
            fontSize: 18,
            paddingRight: isMobile ? 0 : 1,
            cursor: 'pointer',
          }}
        >
          Cancel
        </Typography>
      </Stack>

      {/**** non-visible elements ****/}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={calendarOpen}
        onClose={handleCalendarClose}
      >
        <DateCalendar
          value={calendardate ? dayjs(calendardate) : dayjs()}
          onChange={handleDateChange}
          sx={{
            "& .MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: CustomColorScheme['kellyGreen'],
              },
            }
          }}
        />
      </Popover>

      <Popover
        anchorEl={anchorElBusRules}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={busRulesOpen}
        onClose={handleBusinessRuleClose}
      >
        <Box
          sx={{
            width: 350,
            padding: 2,
            paddingBottom: 3,
          }}
        >
          <Typography
            fontWeight='bold'
          >
            Business Rules
          </Typography>
          {
            businessRules &&
            businessRules.map((rule, idx) => {
              return (
                <Box
                  sx={{
                    paddingLeft: rule.startsWith("- ") ? 2 : 0,
                  }}
                >
                  {rule.replace("- ", "")}
                </Box>
              )
            })

          }
        </Box>
      </Popover>
      <DeletionConfirmationDialog
        open={confirmDialogOpen}
        setOpen={setConfirmDeleteOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete\n${title}?`}
        onCancel={() => setConfirmDeleteOpen(false)}
        onDelete={handleTemplateDelete}
      />
    </>

  );
}
