import '../App.css';
import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive'

// mui
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// components
import DateCard from "../components/DateCard";
import { CustomColorScheme, TitleLabel } from '../components/CustomTheme';
import Toolbar from '../components/Toolbar';


export default function PreviousTasks(props) {
  const {
    getPreviousTaskdates,
    previousTaskdates,
    setReturnPath,
    isLoading,
    setIsLoading,
  } = props;

  const daysInView = 8;

  const maximumEnddate = dayjs().add(-1, 'days');
  const [currentEnddate, setCurrentEnddate] = useState(maximumEnddate)

  const previousEnddate = dayjs(currentEnddate).add(daysInView * -1, 'days');
  const nextEnddate = dayjs(currentEnddate).add(daysInView, 'days');

  const handleEnddateChange = (newDate) => {
    setIsLoading(true);
    setCurrentEnddate(newDate);
    getPreviousTaskdates(newDate);
  }

  ///////////////////////////

  // runs once on startup

  useEffect(() => {
    document.title = "Previous Taskdates";
    getPreviousTaskdates(currentEnddate);
    setReturnPath("/previoustasks")
  }, []);


  // constants //////////////


  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });

  // toolbar plugin /////////

  function ToolbarPlugin(props) {
    const {
      handleEnddateChange,
      currentEnddate,
      previousEnddate,
      nextEnddate,
      maximumEnddate,
    } = props


    return (
      <Stack
        direction='row'
        justifyContent='end'
      >
        <TitleLabel
          sx={{
            color: CustomColorScheme['white'],
            cursor: "pointer",
            ":hover": {
              color: CustomColorScheme['yellow'],
            }
          }}
          onClick={() => handleEnddateChange(previousEnddate)}
        >
          &#9664; prev
        </TitleLabel>
        {
          dayjs(currentEnddate).isSame(maximumEnddate, 'day')
            ?
            <TitleLabel
              sx={{
                paddingX: 1,
              }}
            >
              most recent
            </TitleLabel>
            :
            <TitleLabel
              sx={{
                paddingX: 1,
                cursor: "pointer",
                color: CustomColorScheme['white'],
                ":hover": {
                  color: CustomColorScheme['yellow'],
                }
              }}
              onClick={() => handleEnddateChange(maximumEnddate)}
            >
              most recent
            </TitleLabel>
        }
        {
          dayjs(nextEnddate).isBefore(maximumEnddate)
            ?
            <TitleLabel
              sx={{
                color: CustomColorScheme['white'],
                cursor: "pointer",
                ":hover": {
                  color: CustomColorScheme['yellow'],
                }
              }}
              onClick={() => handleEnddateChange(nextEnddate)}
            >
              next &#9654;
            </TitleLabel>
            :
            <TitleLabel
            >
              next &#9654;
            </TitleLabel>
        }
      </Stack>
    )
  }

  ////////////////////////////

  return (
    <>
      <Toolbar
        title="Previous Tasks"
        breakAfterTitle={isMobile}
        component={
          <ToolbarPlugin
            handleEnddateChange={handleEnddateChange}
            currentEnddate={currentEnddate}
            previousEnddate={previousEnddate}
            nextEnddate={nextEnddate}
            maximumEnddate={maximumEnddate}
          />
        }
      />
      <Stack direction="column" spacing={.5}>
        {
          previousTaskdates && Object.keys(previousTaskdates).length > 0
            ?
            Object.keys(previousTaskdates).map((date) => {
              return (
                <DateCard key={date}
                  calendarItems={previousTaskdates[date].calendarItems}
                  date={date}
                  tasks={previousTaskdates[date].tasks}
                  includeSwitch={false}
                />
              )
            })
            :
            <Box
              display='flex'
              flexGrow={1}
              height={350}
              justifyContent='center'
              alignItems='center'
              color={CustomColorScheme['yellow']}
            >
              <CircularProgress
                color='inherit'
              />
            </Box>
        }
      </Stack>

      {/**** non-render elements ****/}
      <Backdrop
        sx={{
          color: CustomColorScheme['white'],
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={previousTaskdates && Object.keys(previousTaskdates).length > 0 && isLoading}
      >
        <CircularProgress
          sx={{
            color: CustomColorScheme['darkOrange']
          }}
        />
      </Backdrop>
    </>
  );
}