
// general
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Highlighter from "react-highlight-words";
import { useMediaQuery } from 'react-responsive'

// Material UI
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, alpha } from '@mui/material/styles';

// icons
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

// components
import { CustomColorScheme, TitleLabel, DATEFORMAT, DateCardColor } from '../components/CustomTheme';
import TaskCardList from "../components/TaskCardList";
import { SearchContext } from "../components/AllContext";

///////////////////////////


export default function Search(props) {
  const {
    getSearchResults,
    searchKeyword,
    searchRecordLimit,
    searchResults,
    setReturnPath,
    selectedSearchTab,
    setSelectedSearchTab,
  } = useContext(SearchContext);


  // useEffect //////////////

  useEffect(() => {
    setReturnPath("/search");
    if (searchKeyword && !searchResults) {
      getSearchResults(searchKeyword, searchRecordLimit);
    }
  }, []);


  // constants //////////////
  const [localKeyword, setLocalKeyword] = useState(searchKeyword);
  const [dialogOpen, setDialogOpen] = useState(false);

  const Title = "Search Results" + (searchKeyword ? ` for "${searchKeyword}"` : "");
  const taskCount = searchResults && searchResults.taskCount ? searchResults.taskCount : 0;
  const journalCount = searchResults && searchResults.journalCount ? searchResults.journalCount : 0;

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
  const navigate = useNavigate();

  // components //////////////

  const TabPanel = (props) => {
    const { children, value, idx } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== idx}
        id={`tabpanel-${idx}`}
        aria-labelledby={`tab-${idx}`}
      >
        {value === idx && (
          <Box marginTop={.5}  >
            <span>{children}</span>
          </Box>
        )}
      </div>
    );
  }


  // toolbar plugin //////////

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      border: 0,
      color: alpha(theme.palette.common.white, 0.70),
      maxHeight: 30,
      marginTop: 6,
      "&.Mui-selected": {
        color: CustomColorScheme['white'],
        backgroundColor: CustomColorScheme['darkbody'],
        borderRadius: 7
      },
    }, '& .MuiToggleButtonGroup-grouped:hover': {
      color: CustomColorScheme['yellow'],
      borderRadius: 7
    },
  }));


  // event handlers //////////

  const handleRecordLimitChange = (event, recordLimit) => {
    if (recordLimit) {
      getSearchResults(searchKeyword, recordLimit);
    }
  }

  const handleGetSearchResults = (keyword) => {
    setLocalKeyword(keyword);
    getSearchResults(keyword, searchRecordLimit);
  }

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleGetSearchResults(localKeyword);
    } else if (event.key === 'Escape') {
      handleGetSearchResults('');
    }
  }



  // functions ///////////////

  function tabLabel(tab) {
    let returnStr = tab;
    if (searchResults && searchRecordLimit) {
      let recordCount =
        tab === "tasks"
          ? taskCount
          : journalCount;
      if (searchRecordLimit !== -1) {
        if (recordCount > searchRecordLimit) {
          returnStr = `${tab} (${searchRecordLimit}/${recordCount})`
        } else {
          returnStr = `${tab} (${recordCount})`
        }
      } else {
        returnStr = `${tab} (${recordCount})`;
      }
      return returnStr;
    }
  }

  // render //////////////////

  return (
    searchKeyword && !searchResults
      ?
      <Box
        display='flex'
        flexGrow={1}
        height={350}
        justifyContent='center'
        alignItems='center'
        color={CustomColorScheme['yellow']}
      >
        <CircularProgress
          color='inherit'
        />
      </Box>
      :
      <>
        <Stack
          direction={isMobile ? "column" : "row"}
          paddingY={2}
          alignItems="start"
        >
          <Typography
            fontSize={20}
            variant="h6"
            component='div'
            color={CustomColorScheme['white']}
            flexGrow={1}
            paddingBottom={isMobile ? 2 : 0}
          >
            {Title}
          </Typography>
          <Stack
            width={isMobile ? '100%' : 'inherit'}
          >
            <TextField
              size='small'
              variant="filled"
              label=' Keyword'
              value={localKeyword}
              sx={{
                backgroundColor: CustomColorScheme['white'],
                '& .MuiFormLabel-root': {
                  marginLeft: 1,
                },
                '& label.Mui-focused': {
                  color: 'inherit',
                  marginLeft: 1,
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: CustomColorScheme['text'],
                },
              }}
              onChange={(e) => setLocalKeyword(e.target.value)}
              onKeyDown={(e) => handleEnterKeyPress(e)}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment
                      position='end'
                    >
                      <IconButton
                        onClick={() => handleGetSearchResults(localKeyword)}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                    <InputAdornment
                      position='end'
                    >
                      <IconButton
                        onClick={() => handleGetSearchResults('')}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                )
              }}
            />
            <Stack direction='row'>
              <TitleLabel sx={{ paddingRight: .5 }}>Records:</TitleLabel>
              <StyledToggleButtonGroup
                value={searchRecordLimit}
                exclusive
                onChange={handleRecordLimitChange}
                aria-label="record-limit"
                size="small"
              >
                <ToggleButton value={50}>50</ToggleButton>
                <ToggleButton value={100}>100</ToggleButton>
                <ToggleButton value={250}>250</ToggleButton>
                <ToggleButton value={-1}>All</ToggleButton>

              </StyledToggleButtonGroup>
            </Stack>
          </Stack>
        </Stack>
        < Tabs
          value={selectedSearchTab}
          onChange={(event, newTabSeletion) => setSelectedSearchTab(newTabSeletion)}
          area-label="person-tabs"
          sx={{
            alignItems: "bottom",
            height: 55,
            padding: 0,
            paddingBottom: 2,
            backgroundClip: DateCardColor,
            color: CustomColorScheme['white'],
            '& .MuiTab-root.Mui-selected': {
              color: CustomColorScheme['white'],
            },
            '& .MuiTab-root': {
              color: CustomColorScheme['task'],
            },
            '& .MuiTabs-indicator': {
              background: CustomColorScheme['task'],
            }
          }}
          centered
        >
          <Tab icon={
            <ChecklistIcon
              sx={{
                marginBottom: 0.25,
              }}
            />
          }
            iconPosition="start"
            value={0}
            label={tabLabel("tasks")}
            sx={{
              width: isMobile ? 150 : 225,
            }}
          />
          <Tab
            icon={
              <ImportContactsIcon
                sx={{
                  marginBottom: 0.5,
                }}
              />
            }
            iconPosition="start"
            value={1}
            label={tabLabel("journals")}
            sx={{
              width: isMobile ? 150 : 225,
            }}
          />
        </Tabs >
        <TabPanel value={selectedSearchTab} idx={0} >
          <Stack
            spacing={0.5}
          >
            {
              taskCount > 0
                ?
                searchResults.tasks.map((task, idx) => {
                  return (
                    <Box
                      key={idx}
                      color="white"
                      fontSize={18}
                      backgroundColor={DateCardColor}
                      paddingY={0}
                      paddingX={1}
                    >
                      <Stack
                        direction='row'
                      >
                        <Box
                          paddingY={1}
                        >
                          {dayjs(task.date).format("MMMM D, YYYY")}
                        </Box>
                        <Box
                          display='flex'
                          flexGrow={1}
                        />
                        <Box
                          width={60}
                          onClick={() => window.scrollTo(0, 0)}
                          sx={{
                            cursor: 'pointer'
                          }}
                        />
                      </Stack>

                      <Paper
                        component={Stack}
                        sx={{
                          backgroundColor: DateCardColor,
                        }}
                        marginBottom={1}
                      >
                        <TaskCardList
                          tasklist={task.tasks}
                          includeCompletedTasks={true}
                        />
                      </Paper>
                    </Box>
                  )
                })
                :
                <Box color="white" paddingY={2}>No Tasks found</Box>
            }
          </Stack>
        </TabPanel >
        <TabPanel value={selectedSearchTab} idx={1}>
          {
            journalCount > 0
              ?
              searchResults.journalEntries.map((journalDate, idx) => {
                return (
                  <Box
                    key={idx}
                    backgroundColor={DateCardColor}
                    marginBottom={0.5}
                    padding={1}
                  >
                    <Stack
                      direction='row'
                    >
                      <Box
                        to="#"
                        underline="hover"
                        color='white'
                        paddingBottom={1}
                        fontSize={18}
                        sx={{
                          whiteSpace: 'pre-line',
                          wordWrap: "break-word",
                          textDecoration: "none",
                          cursor: "pointer",
                          '&:hover': {
                            textDecoration: "underline",
                          }
                        }}
                        onClick={() => navigate(`/journal/${dayjs(journalDate.date).format(DATEFORMAT)} `)}
                      >
                        {dayjs(journalDate.date).format("MMMM D, YYYY")}
                      </Box>
                      <Box
                        display='flex'
                        flexGrow={1}
                      />
                      <Box
                        width={60}
                        onClick={() => window.scrollTo(0, 0)}
                        sx={{
                          cursor: 'pointer'
                        }}
                      />

                    </Stack>
                    <Box
                      backgroundColor={CustomColorScheme['task']}
                      padding={1}
                      sx={{
                        whiteSpace: 'pre-line',
                        wordWrap: "break-word",
                      }}
                    >
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: CustomColorScheme['yellow'],
                        }}
                        highlightClassName="text-highlight"
                        searchWords={[searchKeyword]}
                        autoEscape
                        textToHighlight={journalDate.journalEntry}
                      />

                    </Box>
                  </Box>
                  // </Box>
                )
              })
              :
              <Box color="white" paddingY={2}>No Journal Entries found</Box>
          }
        </TabPanel>
      </>
  );
}
