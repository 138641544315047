import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from "dayjs";
import { useMediaQuery } from 'react-responsive'

// Material ui
import MuiAppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Popover from '@mui/material/Popover';


// icons
import SearchIcon from '@mui/icons-material/Search';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import TuneIcon from '@mui/icons-material/Tune';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import Check from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

// custom components
import { CustomColorScheme, DATEFORMAT, AppbarColor } from './CustomTheme';
import { WhiteTooltip } from "./Tooltips";
import SettingsDrawer from "../dialogs/SettingsDrawer";
import { AppbarContext } from "./AllContext"
import { AuthContext } from '../AuthContext';
import JournalConfirmationAbandonDialog from "../dialogs/JournalConfirmationAbandonDialog";

/////////////////////////////


const CustomMenuItem = (props) => {
  const {
    title,
    targetFunction,
    TargetIcon
  } = props;

  return (
    <MenuItem
      onClick={targetFunction}
    >
      <ListItemIcon>
        <Icon
          sx={{
            display: 'flex',
          }}
        >{TargetIcon}</Icon>
      </ListItemIcon>
      <ListItemText>
        {title}
      </ListItemText>
    </MenuItem >
  )
}

/////////////////////////////


export function Appbar(props) {

  const {
    todayJournalEntry,
    journalEntryUnsaved,
    settingsMap,
    setSettingSave,
  } = props;

  const {
    setSingleDateView,
    getJournalMap,
    setJournalUpdateStatus,
    setJournalEntryUnsaved,
    setShowWeightControlOverride,
    setReset,
    getCustomTaskdates,
    getTaskdateMap,
    includeDeleted,
    setIncludeDeleted,
  } = useContext(AppbarContext);


  // constants ////////////

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [journalText, setJournalText] = useState('')
  const [openJournalConfirmationDialog, setOpenJournalConfirmationDialog] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorElement);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
  const calendarOpen = Boolean(anchorEl);

  const { signOut, isAuthenticated } = useContext(AuthContext)

  // useEffect ////////////

  useEffect(() => {
    setJournalText(
      todayJournalEntry && (todayJournalEntry.length > 0)
        ? todayJournalEntry.indexOf("\n") ? todayJournalEntry.split("\n") : [todayJournalEntry]
        : []
    );
  }, [todayJournalEntry]);


  // event handlers ///////

  const handleLogout = () => {
    signOut();
    setMenuAnchorElement(null);
    setShowWeightControlOverride(false)
    navigate("/");
  };


  const handleIconClick = (event) => {
    setShowWeightControlOverride(false)
    setMenuAnchorElement(event.currentTarget);
  }

  const handleMenuHome = (isJournalEntryUnsaved) => {
    setShowWeightControlOverride(false)
    setMenuAnchorElement(null);
    if (isJournalEntryUnsaved) {
      setOpenJournalConfirmationDialog(true)
    } else {
      setJournalUpdateStatus({});
      setReset(true)
      setSingleDateView(isMobile);
      if (location.pathname === "/" || location.pathname.startsWith("/20")) {
        getTaskdateMap(dayjs().format(DATEFORMAT));
      }
      navigate("/");
    }
  }

  const handleMenuPreviousTasks = (isJournalEntryUnsaved) => {
    setMenuAnchorElement(null);
    if (!isJournalEntryUnsaved) {
      setJournalEntryUnsaved(false);
      setJournalUpdateStatus({});
      navigate("/previoustasks");
    } else {
      setOpenJournalConfirmationDialog(true)
    }
  }


  const handleMenuPeople = (isJournalEntryUnsaved) => {
    setMenuAnchorElement(null);
    if (!isJournalEntryUnsaved) {
      setJournalEntryUnsaved(false);
      setJournalUpdateStatus({});
      navigate("/people");
    } else {
      setOpenJournalConfirmationDialog(true)
    }
  }

  const handleMenuSearch = (isJournalEntryUnsaved) => {
    setShowWeightControlOverride(false)
    setMenuAnchorElement(null);
    if (!isJournalEntryUnsaved) {
      setJournalEntryUnsaved(false);
      setJournalUpdateStatus({});
      navigate("/search");
    } else {
      setOpenJournalConfirmationDialog(true)
    }
  }

  const handleMenuSettings = () => {
    setMenuAnchorElement(null);
    if (isMobile) {
      navigate("/settings")
    } else
      setDrawerOpen(true)

  }

  const handleMenuTemplates = () => {
    setMenuAnchorElement(null);
    navigate("/templates")
  }

  const handleMenuJournal = () => {
    setShowWeightControlOverride(false)
    setMenuAnchorElement(null);
    getJournalMap(dayjs().format(DATEFORMAT));
    setJournalUpdateStatus({});
    navigate(`/journal/${dayjs().format(DATEFORMAT)}`);
  }

  const handleMenuIncludeDeleted = () => {
    setMenuAnchorElement(null);
    setIncludeDeleted(!includeDeleted);
  }

  const handleAbandonJournalChanges = () => {
    setOpenJournalConfirmationDialog(false);
    setJournalEntryUnsaved(false);
    handleMenuHome(false);
  }

  const handleCustomTaskdate = (newDate, selectionState) => {
    if (selectionState === 'finish') {
      setAnchorEl(null)
      setMenuAnchorElement(null);
      navigate("/customdaterange")
      getCustomTaskdates(dayjs(newDate).format(DATEFORMAT));
    }
  }


  // render ///////////////

  return (
    <>
      <MuiAppBar
        position="static"
        sx={{
          backgroundColor: AppbarColor,
        }}
      >
        <Toolbar disableGutters>
          <Container
            maxWidth='false'
            sx={{
              maxWidth: 750,
            }}
          >
            {
              isAuthenticated
                ?
                <Stack
                  direction="row"
                >
                  <IconButton
                    sx={{
                      marginRight: 1,
                      color: CustomColorScheme['white']
                    }}
                    onClick={handleIconClick}
                  >
                    <ChecklistIcon />
                  </IconButton>
                  <Menu
                    id="tasklist-menu"
                    anchorEl={menuAnchorElement}
                    open={menuOpen}
                    onClose={() => setMenuAnchorElement(null)}
                  >
                    <CustomMenuItem
                      title="Home"
                      targetFunction={() => handleMenuHome(journalEntryUnsaved)}
                      TargetIcon={<HomeIcon fontSize="small" />}
                    />
                    <Divider />
                    <CustomMenuItem
                      title="Previous Tasks"
                      targetFunction={() => handleMenuPreviousTasks(journalEntryUnsaved)}
                      TargetIcon={<ChecklistIcon fontSize="small" />}
                    />
                    <CustomMenuItem
                      title="Specific Week"
                      targetFunction={(e) => setAnchorEl(e.currentTarget)}
                      TargetIcon={<DateRangeIcon fontSize="small" />}
                    />
                    <CustomMenuItem
                      title="People"
                      targetFunction={() => handleMenuPeople(journalEntryUnsaved)}
                      TargetIcon={<PersonSearchIcon fontSize="small" />}
                    />
                    <CustomMenuItem
                      title="Search"
                      targetFunction={() => handleMenuSearch(journalEntryUnsaved)}
                      TargetIcon={<SearchIcon fontSize="small" />}
                    />
                    <Divider />
                    <CustomMenuItem
                      title={includeDeleted ? "Exclude Deleted" : "Include Deleted"}
                      targetFunction={handleMenuIncludeDeleted}
                      TargetIcon={
                        includeDeleted
                          ? <CloseIcon fontSize="small" />
                          : <Check fontSize="small" />
                      }
                    />
                    <Divider />
                    <CustomMenuItem
                      title="Settings"
                      targetFunction={handleMenuSettings}
                      TargetIcon={<SettingsIcon fontSize="small" />}
                    />
                    <CustomMenuItem
                      title="Templates"
                      targetFunction={handleMenuTemplates}
                      TargetIcon={<TuneIcon fontSize="small" />}
                    />
                    <Divider />
                    <CustomMenuItem
                      title="Log Out"
                      targetFunction={handleLogout}
                      TargetIcon={<LogoutIcon fontSize="small" />}
                    />
                  </Menu>
                  <Box
                    display='flex'
                    alignItems='center'
                  >
                    <WhiteTooltip
                      title={location.pathname !== "/" && !location.pathname.startsWith("/20") ? "Return to Home page" : ""}
                    >
                      <Typography
                        variant="h6"
                        onClick={() => handleMenuHome(journalEntryUnsaved)}
                        sx={{
                          cursor: 'pointer',
                          fontFamily: 'monospace',
                          fontWeight: 700,
                          letterSpacing: '.3rem',
                          color: 'white',
                        }}>
                        TASKLIST
                      </Typography>
                    </WhiteTooltip>
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                  />
                  <WhiteTooltip
                    title={
                      isMobile
                        ?
                        ""
                        :
                        journalText.length > 0
                          ?
                          !location.pathname.startsWith("/journal")
                            ?
                            <Typography
                              variant="div"
                              sx={{
                                fontSize: 14
                              }}
                            >
                              {
                                journalText.map((d, idx) => {
                                  return (
                                    <p key={idx}>{d}</p>
                                  )
                                })

                              }
                            </Typography>
                            : ""
                          :
                          "Edit Today's Journal"
                    }
                    arrow
                    placement="bottom-end"
                    onClick={handleMenuJournal}>

                    <IconButton
                      sx={{
                        color: CustomColorScheme['white'],
                        marginRight: 1,
                      }}>
                      <AutoStoriesIcon />
                    </IconButton>
                  </WhiteTooltip>
                  {
                    location.pathname !== "/search"
                      ? <WhiteTooltip
                        title={isMobile ? "" :
                          location.pathname === "/search" ? "Return" : "Search"}
                      >
                        <IconButton
                          onClick={() => handleMenuSearch(journalEntryUnsaved)}
                        >
                          <SearchIcon
                            sx={{
                              color: CustomColorScheme['white'],
                            }}
                          />
                        </IconButton>
                      </WhiteTooltip>
                      :
                      <IconButton
                        disabled
                      >
                        <SearchIcon
                          sx={{
                            color: CustomColorScheme['lightGray'],
                          }}
                        />
                      </IconButton>
                  }
                </Stack>
                :
                <Stack
                  direction='row'
                  display='flex'
                  alignItems='center'
                >
                  <IconButton
                    sx={{
                      marginRight: 1,
                      color: CustomColorScheme['white']
                    }}
                  >
                    <ChecklistIcon />
                  </IconButton>
                  <Typography
                    variant="h6"
                    sx={{
                      cursor: 'pointer',
                      fontFamily: 'monospace',
                      fontWeight: 700,
                      letterSpacing: '.3rem',
                      color: 'white',
                    }}>
                    TASKLIST
                  </Typography>
                </Stack>
            }
          </Container>
        </Toolbar>
      </MuiAppBar >

      {/**** non-render elements ****/}
      <SettingsDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        settingsMap={settingsMap}
        setSettingSave={setSettingSave}
      />
      <JournalConfirmationAbandonDialog
        open={openJournalConfirmationDialog}
        setOpen={setOpenJournalConfirmationDialog}
        onAbondonChanges={handleAbandonJournalChanges}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={calendarOpen}
        onClose={() => setAnchorEl(null)}
      >
        <DateCalendar
          value={dayjs()}
          onChange={handleCustomTaskdate}
          sx={{
            "& .MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: CustomColorScheme['kellyGreen'],
              },
            }
          }}
        />
      </Popover>

    </>
  );
}