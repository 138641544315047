import Stack from "@mui/material/Stack";
import { TitleLabel } from '../components/CustomTheme'

export default function Toolbar(props) {

  const {
    title,
    component,
    breakAfterTitle,
  } = props;

  return (
    <Stack
      direction={breakAfterTitle ? 'column' : 'row'}
      padding={1}
      paddingRight={0}
    >
      <TitleLabel sx={{
        flexGrow: 1,
        fontSize: 20,
        fontWeight: "bold",
        minHeight: 38,
      }}>
        {title}
      </TitleLabel>
      {component}
    </Stack>
  )
}