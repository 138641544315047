// general
import './App.css';
import Index from './pages'
import React, { useState } from "react";
import axios, { isAxiosError } from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

// material
import dayjs from 'dayjs';

// components
import * as auth from "./auth"
import { DATEFORMAT } from './components/CustomTheme';

import {
  TaskDetailContext,
  TaskCardContext,
  JournalContext,
  AppbarContext,
  HomeContext,
  CustomSwitchContext,
  SettingsContext,
  DateCardContext,
  SearchContext,
  TemplateContext,
  TemplateTasksContext,
} from './components/AllContext';


export default function App() {

  // constants //////////////

  const emptyTask = {
    date: null,
    detail: null,
    minutes: null,
    orderId: null,
    parentTaskId: null,
    taskId: null,
    templateId: null,
    title: null,
    isCompleted: false
  }

  const emptyTemplate = {
    templateId: null,
    parentTemplateId: null,
    templateTypeId: 1,
    title: null,
    description: null,
    minutes: null,
    orderId: null,
    nDays: null,
    month: null,
    monthName: null,
    dayOfWeek: null,
    dayOfWeekName: null,
    dayOfMonth: null,
    nthDayOfMonth: null,
    startdate: dayjs().format(DATEFORMAT),
    enddate: null,
    isClosed: false,
    templates: null,
  }

  const [journalUpdateStatus, setJournalUpdateStatus] = useState({});
  const [taskdates, setTaskdates] = useState({});
  const [previousTaskdates, setPreviousTaskdates] = useState({});
  const [customTaskdates, setCustomTaskdates] = useState({});
  const [todayJournalEntry, setTodayJournalEntry] = useState();
  const [previousTaskEnddate, setPreviousTaskEnddate] = useState();
  const [journalMap, setJournalMap] = useState();
  const [parentTasks, setParentTasks] = useState();
  const [templateTasks, setTemplateTasks] = useState();
  const [personData, setPersonData] = useState();
  const [searchResults, setSearchResults] = useState();
  const [settingsMap, setSettingsMap] = useState();
  const [todayDate, setTodayDate] = useState();
  const [journalEntryUnsaved, setJournalEntryUnsaved] = useState(false);
  const [showWeightControlOverride, setShowWeightControlOverride] = useState(false);
  const [weightValue, setWeightValue] = useState();
  const [defaultWeight, setDefaultWeight] = useState();
  const [returnPath, setReturnPath] = useState('')
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customStartdateStr, setCustomStartdateStr] = useState();
  const [selectedSearchTab, setSelectedSearchTab] = useState(0);
  const [templates, setTemplates] = useState();
  const [taskTemplate, setTaskTemplate] = useState(emptyTemplate);
  const [detailTask, setDetailTask] = useState(emptyTask);
  const [detailTemplate, setDetailTemplate] = useState(emptyTemplate);
  const [detailParentTemplate, setDetailParentTemplate] = useState();
  const [detailParentTemplates, setDetailParentTemplates] = useState();
  const [includeDeleted, setIncludeDeleted] = useState(false)

  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

  const [includeCompletedTasks, setIncludeCompletedTasksValue] = useState(
    localStorage.getItem("includeCompletedTasks") !== null
      ? JSON.parse(localStorage.getItem("includeCompletedTasks"))
      : false
  );

  function setIncludeCompletedTasks(value) {
    localStorage.setItem("includeCompletedTasks", JSON.stringify(value));
    setIncludeCompletedTasksValue(value)
  }

  //


  const [singleDateView, setSingleDateViewValue] = useState(
    localStorage.getItem("singleDateView") !== null
      ? JSON.parse(localStorage.getItem("singleDateView"))
      : !isMobile
  );

  function setSingleDateView(value) {
    localStorage.setItem("singleDateView", JSON.stringify(value));
    setSingleDateViewValue(value)
  }

  //

  const [searchRecordLimit, setSearchRecordLimitValue] = useState(
    localStorage.getItem("searchRecordLimit") !== null
      ? JSON.parse(localStorage.getItem("searchRecordLimit"))
      : 50
  );

  function setSearchRecordLimit(value) {
    localStorage.setItem("searchRecordLimit", JSON.stringify(value));
    setSearchRecordLimitValue(value)
  }

  //

  const [taskdate, setTaskdateValue] = useState(
    localStorage.getItem("taskdate") !== null
      ? JSON.parse(localStorage.getItem("taskdate"))
      : dayjs()
  );

  function setTaskdate(value) {
    localStorage.setItem("taskdate", JSON.stringify(value));
    setTaskdateValue(value)
  }

  //

  const [searchKeyword, setSearchKeywordValue] = useState(
    localStorage.getItem("searchKeyword") !== null
      ? JSON.parse(localStorage.getItem("searchKeyword"))
      : ""
  );

  function setSearchKeyword(value) {
    if (value) {
      localStorage.setItem("searchKeyword", JSON.stringify(value));
    } else {
      localStorage.removeItem("searchKeyword");
    }
    setSearchKeywordValue(value)
  }



  // general functions //////

  function refreshTaskdateList() {
    if ((location.pathname === "/") || (location.pathname.startsWith("/20"))) {
      // home page
      getTaskdateMap();
    } else if (location.pathname === "/previoustasks") {
      // previous tasks
      getPreviousTaskdates(previousTaskEnddate);
    } else if (location.pathname === "/search") {
      // search page
      if (searchKeyword && searchRecordLimit) {
        getSearchResults(searchKeyword, searchRecordLimit);
      }
    } else if (location.pathname === "/customdaterange") {
      getCustomTaskdates();
    } else if (location.pathname === "/templatetasks") {
      getTemplateTasks(taskTemplate)
    }
  }


  // api functions //////////

  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  async function getTaskdateMap(taskdateStr) {
    if (!taskdateStr) {
      taskdateStr = dayjs(taskdate).format(DATEFORMAT);
    }

    let url = isMobile && singleDateView
      ? `${process.env.REACT_APP_API_BASE_URL}/taskdate/map/${taskdateStr}`
      : `${process.env.REACT_APP_API_BASE_URL}/taskdate/map`


    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    )
      .then((response) => {
        if (response.data && response.data.taskdateMap) {
          setTaskdates(response.data.taskdateMap.dates);
          setTodayDate(response.data.taskdateMap.todayDate)
        } else {
          console.log("app getTaskdateMap taskdateMap not defined:", response.data)
        }
        if (response.data && response.data.journalMap) {
          setJournalMap(response.data.journalMap);
          setTodayJournalEntry(response.data.journalMap.journalEntry);
          getSettingsMap()
        } else {
          console.log("app getTaskdateMap journalMap not defined:", response.data)
        }
      })
      .catch(function (error) {
        console.log("API getTaskdateMap error: ")
        console.log("API error url: ", url);
        processError(error)
      }).finally(function () {
        setIsLoading(false)
      });
  };


  async function getTemplateTasks(template) {

    let url = `${process.env.REACT_APP_API_BASE_URL}/task/templateid/${template.templateId}`
    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    )
      .then((response) => {
        setTemplateTasks(response.data)
      })
      .catch(function (error) {
        console.log("API getTemplateTasks error: ")
        console.log("API error url: ", url);
        processError(error)
      }).finally(function () {
        setIsLoading(false)
      });
  };


  async function getCustomTaskdates(startdateStr) {
    if (!startdateStr && customStartdateStr) {
      startdateStr = customStartdateStr;
    } else {
      setCustomStartdateStr(startdateStr);
    }

    let startdate = dayjs(startdateStr)
    let enddateStr = dayjs(startdate).add(7, 'day').format(DATEFORMAT);

    let url = `${process.env.REACT_APP_API_BASE_URL}/taskdate/${startdateStr}/${enddateStr}`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    )
      .then((response) => {
        if (response.data && response.data.dates) {
          setCustomTaskdates(response.data.dates);
        } else {
          console.log("app getTaskdates dates not defined:", response.data)
        }
      })
      .catch(function (error) {
        console.log("API getTaskdates error: ")
        console.log("API error url: ", url);
        processError(error)
      }).finally(function () {
        setIsLoading(false)
      });
  };


  async function setTaskComplete(completedTask) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/task/complete`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.put(
      url,
      {
        taskId: completedTask.taskId,
        isCompleted: completedTask.isCompleted
      },
      headers,
    ).then((response) => {
      refreshTaskdateList();
    }).catch(error => {
      console.log("API setTaskComplete error: ", completedTask)
      console.log("API error url: ", url);
      processError(error)
    })
  };


  async function setTaskUndelete(taskId) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/task/undelete`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.put(
      url,
      {
        taskId: taskId,
      },
      headers,
    ).then((response) => {
      refreshTaskdateList();
    }).catch(error => {
      console.log("API setTaskUndelete error: ", taskId)
      console.log("API error url: ", url);
      processError(error)
    })
  };


  async function setTaskReorder(orderedTask) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/task/reorder`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.put(
      url,
      {
        taskId: orderedTask.taskId,
        orderId: orderedTask.orderId
      },
      headers,
    ).then((response) => {
      refreshTaskdateList();
    }).catch(error => {
      console.log("API setTaskReorder error: ", orderedTask)
      console.log("API error url: ", url);
      processError(error)
    })
  };


  async function setTaskUpdate(modifiedTask) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/task`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.put(
      url,
      modifiedTask,
      headers,
    ).then((response) => {
      refreshTaskdateList();
    }).catch(error => {
      console.log("API setTaskUpdate error: ", modifiedTask)
      console.log("API error url: ", url);
      processError(error)
    })
  };


  async function setTaskCreate(newTask) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/task`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }

    await axios.post(
      url,
      newTask,
      headers,
    ).then((response) => {
      refreshTaskdateList();
    }).catch(error => {
      console.log("API setTaskCreate error: ", newTask)
      console.log("API error url: ", url);
      processError(error)
    })
  };


  async function setTaskDelete(targetTask) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/task/${targetTask.taskId}`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.delete(
      url,
      headers,
      targetTask
    ).then((response) => {
      refreshTaskdateList();
    }).catch(error => {
      console.log("API setTaskDelete error: ", targetTask)
      console.log("API error url: ", url);
      processError(error)
    })
  };

  async function getJournalMap(date) {
    let dateStr = dayjs(date).format(DATEFORMAT);
    let url = `${process.env.REACT_APP_API_BASE_URL}/journal/map/${dateStr}`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    ).then((response) => {
      setJournalMap(response.data)
      if (response.data.todayDate === response.data.currentDate) {
        setTodayJournalEntry(response.data.journalEntry)
        setTodayDate(response.data.todayDate)
      }
    }).catch(error => {
      console.log("API getJournalMap error: ", dateStr)
      console.log("API error url: ", url);
      processError(error)
    }).finally(function () {

    })
  };

  async function setJournalSave(journalObject) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/journal`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.put(
      url,
      journalObject,
      headers,
    ).then((response) => {
      setJournalEntryUnsaved(false);
      setJournalUpdateStatus({
        status: "success",
        message: "Journal Entry Update Succeeded!"
      });
      if (journalObject.targetDate && journalObject.journalEntry) {
        getJournalMap(journalObject.targetDate);
      }
      if (returnPath === '/search') {
        getSearchResults(searchKeyword, searchRecordLimit);
      }
      if (journalObject.navTarget) {
        navigate(journalObject.navTarget);
        // window.scrollTo(0, 0);
      }
    }).catch(error => {
      setJournalUpdateStatus({
        status: "error",
        message: "Journal Entry Update Failed!"
      });
      console.log("API setJournalSave error: ", journalObject)
      console.log("API error url: ", url);
      processError(error)
    })
  };

  async function getPreviousTaskdates(enddate) {
    setPreviousTaskEnddate(enddate)
    let enddateStr = dayjs(enddate).format(DATEFORMAT)

    let url = `${process.env.REACT_APP_API_BASE_URL}/taskdate/previous/${enddateStr}`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    )
      .then((response) => {
        let taskdateDict = response.data.taskDates;

        // retrieve list of keys, make sure it's sorted, then reverse it
        let keys = Object.keys(taskdateDict).sort().reverse();

        // create new, empty dict
        let reversedTaskdateDict = {};

        for (let key of keys) {
          reversedTaskdateDict[key] = taskdateDict[key]
        }
        setPreviousTaskdates(reversedTaskdateDict);
      }).catch(error => {
        console.log("API getPreviousTaskdates error: ", enddateStr)
        console.log("API error url: ", url);
        processError(error)
      }).finally(function () {
        setIsLoading(false)
      })
  };


  async function getPersonData() {
    let url = `${process.env.REACT_APP_API_BASE_URL}/datealert`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    )
      .then((response) => {
        setPersonData(response.data);
      })
      .catch(function (error) {
        console.log("API getPeople error: ")
        console.log("API error url: ", url);
        processError(error)
      })
  };


  async function getSearchResults(keyword, recordLimit) {
    setSearchKeyword(keyword);
    setSearchRecordLimit(recordLimit);
    if (!keyword) {
      setSearchResults(null)
    } else {
      let val = recordLimit === -1 ? 'all' : recordLimit;
      let url = encodeURI(`${process.env.REACT_APP_API_BASE_URL}/search/${keyword}/recentdate/all/recordlimit/${val}/${isMobile}`).replace(/'/g, "%27");

      const session = await auth.getSession();
      const accessToken = session.accessToken.jwtToken

      let headers = {
        "headers": {
          "Authorization": `Bearer ${accessToken}`,
        }
      }
      await axios.get(
        url,
        headers,
      )
        .then((response) => {
          // sort journal entries in decending order
          response.data.journalEntries.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          })
          setSearchResults(response.data);
        })
        .catch(function (error) {
          setSearchKeyword('');
          setSearchResults(null);
          console.log("API getSearchResults error: ", keyword, recordLimit)
          console.log("API error url: ", url);
          processError(error)
        })
        .finally(function () {
        });
    }
  };


  async function getDefaultWeight() {
    let url = `${process.env.REACT_APP_API_BASE_URL}/setting/map`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    ).then((response) => {
      setDefaultWeight(parseInt(response.data.settings.weightSelected))

    }).catch(error => {
      console.log("API getDefaultWeight error: ")
      console.log("API error url: ", url);
      processError(error)
    })
  }

  async function getSettingsMap() {
    let url = `${process.env.REACT_APP_API_BASE_URL}/setting/map`

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    ).then((response) => {
      setSettingsMap(response.data)
    }).catch(error => {
      console.log("API getSettingsMap error: ")
      console.log("API error url: ", url);
      processError(error)
    })

  };


  async function setSettingSave(settingsArray) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/setting/multiple`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.put(
      url,
      settingsArray,
      headers,
    ).then((response) => {
      getSettingsMap();
    }).catch(error => {
      console.log("API setSettingSave error: ", settingsArray)
      console.log("API error url: ", url);
      processError(error)
    })
  };


  async function setTodayJournalWithWeight(weight, weighDecimal) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/weather`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    ).then((response) => {
      let journalEntry;
      if (weight) {
        journalEntry = `I weighed ${weight}.${weighDecimal} ` + (dayjs().hour < 12 ? 'this morning. ' : 'today. ');
      }
      journalEntry += response.data.description;
      setJournalSave({
        "date": dayjs().format(DATEFORMAT),
        "journalEntry": journalEntry
      })
      setTodayJournalEntry(journalEntry);
    }).catch(error => {
      console.log("API setTodayJournalWithWeight ")
      console.log("API error url: ", url);
      processError(error)
    })
  };


  async function getParentTasks(dateStr) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/task/date/${dateStr}`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    ).then((response) => {
      setParentTasks(response.data);
    }).catch(error => {
      console.log("API getParentTasks error: ", dateStr)
      console.log("API error url: ", url);
      processError(error)
    })
  };


  async function getTodayDate() {
    let url = `${process.env.REACT_APP_API_BASE_URL}/today`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    ).then((response) => {
      setTodayDate(response.data.today);
    }).catch(error => {
      console.log("API getTodayDate error: ")
      console.log("API error url: ", url);
      processError(error)
    })
  };


  async function getTemplates() {
    let url = `${process.env.REACT_APP_API_BASE_URL}/template`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.get(
      url,
      headers,
    ).then((response) => {
      setTemplates(response.data);
    }).catch(error => {
      console.log("API getTemplates error: ")
      console.log("API error url: ", url);
      processError(error)
    })
  };

  async function setTemplateCreate(newTemplate) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/template`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.post(
      url,
      newTemplate,
      headers,
    ).then((response) => {
      getTemplates();
    }).catch(error => {
      console.log("API setTemplateCreate error: ", newTemplate)
      console.log("API error url: ", url);
      processError(error)
    })

  }

  async function setTemplateUpdate(updatedTemplate) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/template`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.put(
      url,
      updatedTemplate,
      headers,
    ).then((response) => {
      getTemplates();
    }).catch(error => {
      console.log("API setTemplateUpdate error: ", updatedTemplate)
      console.log("API error url: ", url);
      processError(error)
    })

  }

  async function setTemplateDelete(targetTemplate) {

    let url = `${process.env.REACT_APP_API_BASE_URL}/template/${targetTemplate.templateId}`;

    const session = await auth.getSession();
    const accessToken = session.accessToken.jwtToken

    let headers = {
      "headers": {
        "Authorization": `Bearer ${accessToken}`,
      }
    }
    await axios.delete(
      url,
      headers,
      targetTemplate
    ).then((response) => {
      getTemplates();
    }).catch(error => {
      console.log("API setTemplateDelete error: ", targetTemplate)
      console.log("API error url: ", url);
      processError(error)
    })

  }

  // error handling

  function processError(error) {
    console.log("API error", error);
    if (error.response) {
      // The request was made and the server responded with a status code > 200
      console.log("API error.response.data: ", error.response.data);
      console.log("API error.response.status: ", error.response.status);
      console.log("API error.response.headers: ", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log("API error.request: ", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('API error.message: ', error.message);
    }
    console.log("API error.config", error.config);
  }


  // contexts ////////////////

  const customSwitchContext = {
    includeCompletedTasks,
    setIncludeCompletedTasks,
    setIsLoading,
  }

  const homeContext = {
    getCustomTaskdates,
    getTaskdateMap,
    setSingleDateView,
    setTaskdate,
    setTodayJournalWithWeight,
    setJournalSave,
    settingsMap,
    todayJournalEntry,
    todayDate,
    showWeightControlOverride,
    setShowWeightControlOverride,
    weightValue,
    setWeightValue,
    getSettingsMap,
    defaultWeight,
    getDefaultWeight,
    setReturnPath,
    journalUpdateStatus,
    setJournalUpdateStatus,
    reset,
    setReset,
  }

  const taskDetailContext = {
    parentTasks,
    getParentTasks,
    setParentTasks,
    setTaskCreate,
    setTaskUpdate,
    setTaskDelete,
    includeCompletedTasks,
  }

  const taskCardContext = {
    setTaskComplete,
    setTaskDelete,
    setTaskReorder,
    setIsLoading,
    setDetailTask,
    setReturnPath,
    setTaskUndelete,
    includeDeleted,
    setTaskUpdate,
  }

  const journalContext = {
    setJournalEntryUnsaved,
    setJournalSave,
    setJournalUpdateStatus,
    setTodayJournalEntry,
    getJournalMap,
    todayJournalEntry,
    setWeightValue,
    todayDate,
    returnPath,
  }

  const appbarContext = {
    setSingleDateView,
    getJournalMap,
    setJournalUpdateStatus,
    setJournalEntryUnsaved,
    setShowWeightControlOverride,
    setReset,
    getCustomTaskdates,
    getTaskdateMap,
    includeDeleted,
    setIncludeDeleted,
  }

  const dateCardContext = {
    setDetailTask,
    setReturnPath,
    includeCompletedTasks,
    setIncludeCompletedTasks,
    includeDeleted,
  }

  const settingsContext = {
    settingsMap,
    setSettingSave,
    returnPath,
  }

  const searchContext = {
    getSearchResults,
    searchKeyword,
    searchRecordLimit,
    searchResults,
    setReturnPath,
    selectedSearchTab,
    setSelectedSearchTab,
  }

  const templateContext = {
    templates,
    getTemplates,
    setReturnPath,
    setTemplateCreate,
    setTemplateUpdate,
    setTemplateDelete,
    detailTemplate,
    setDetailTemplate,
    detailParentTemplate,
    setDetailParentTemplate,
    detailParentTemplates,
    setDetailParentTemplates,
    getTemplateTasks,
    taskTemplate,
    setTaskTemplate,
  }


  return (
    <TaskDetailContext.Provider value={taskDetailContext} >
      <TaskCardContext.Provider value={taskCardContext} >
        <JournalContext.Provider value={journalContext} >
          <AppbarContext.Provider value={appbarContext} >
            <HomeContext.Provider value={homeContext} >
              <CustomSwitchContext.Provider value={customSwitchContext} >
                <SettingsContext.Provider value={settingsContext} >
                  <DateCardContext.Provider value={dateCardContext} >
                    <SearchContext.Provider value={searchContext} >
                      <TemplateContext.Provider value={templateContext}>
                        <Index
                          getCustomTaskdates={getCustomTaskdates}
                          customTaskdates={customTaskdates}
                          getPersonData={getPersonData}
                          getPreviousTaskdates={getPreviousTaskdates}
                          includeCompletedTasks={includeCompletedTasks}
                          setIncludeCompletedTasks={setIncludeCompletedTasks}
                          journalEntryUnsaved={journalEntryUnsaved}
                          journalMap={journalMap}
                          journalUpdateStatus={journalUpdateStatus}
                          personData={personData}
                          previousTaskdates={previousTaskdates}
                          setSettingSave={setSettingSave}
                          settingsMap={settingsMap}
                          singleDateView={singleDateView}
                          taskdate={taskdate}
                          taskdates={taskdates}
                          todayJournalEntry={todayJournalEntry}
                          returnPath={returnPath}
                          setReturnPath={setReturnPath}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          detailTask={detailTask}
                          templateTasks={templateTasks}
                          taskTemplate={taskTemplate}
                          includeDeleted={includeDeleted}
                        />
                      </TemplateContext.Provider>
                    </SearchContext.Provider>
                  </DateCardContext.Provider>
                </SettingsContext.Provider>
              </CustomSwitchContext.Provider>
            </HomeContext.Provider>
          </AppbarContext.Provider>
        </JournalContext.Provider>
      </TaskCardContext.Provider>
    </TaskDetailContext.Provider>
  )
}