// general

// material ui components
import Stack from "@mui/material/Stack";
import TaskCard from "./TaskCard";

export default function TaskCardList(props) {
  const {
    tasklist,
    includeCompletedTasks,
    includeDeleted
  } = props;

  const filteredTasks = (includeCompletedTasks
    ? tasklist
    : tasklist.filter((t) => t.isCompleted === false)).filter((t) => includeDeleted || t.isDeleted === false)


  let position;
  return (
    <Stack
      direction="column"
      spacing={.5}
    >
      {
        filteredTasks &&
        filteredTasks.length > 0 &&
        filteredTasks.map((task, idx) => {
          position = {
            "prev": null,
            "description": null,
            "next": null
          }
          if (tasklist.length === 1) {
            position.description = 'one';
            position.next = null;
            position.prev = null
          } else if (idx === 0 && filteredTasks.length === 2) {
            position.description = 'firstoftwo';
            position.next = filteredTasks[idx + 1].orderId;
            position.prev = null;
          } else if (idx === 0 && filteredTasks.length > 1) {
            position.description = 'first';
            position.next = filteredTasks[idx + 1].orderId;
            position.prev = null;
          } else if (idx === (filteredTasks.length - 1) && filteredTasks.length === 2) {
            position.description = 'lastoftwo';
            position.next = null;
            position.prev = filteredTasks[idx - 1].orderId;
          } else if (idx === (filteredTasks.length - 1)) {
            position.description = 'last';
            position.next = null;
            position.prev = idx > 0 ? filteredTasks[idx - 1].orderId : 0;
          } else {
            position.description = 'any';
            position.next = filteredTasks.length > idx ? filteredTasks[idx + 1].orderId : null;
            position.prev = idx > 0 ? filteredTasks[idx - 1].orderId : null;
          }
          return (
            <TaskCard
              key={task.taskId}
              task={task}
              position={position}
              includeCompletedTasks={includeCompletedTasks}
            />
          )
        })
      }
    </Stack>
  );
}