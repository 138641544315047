// general
import { Routes, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Material UI
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// custom components
import { BodyColor } from '../components/CustomTheme';
import { Appbar } from '../components/Appbar';
import RouteGuard from '../components/RouteGuard';
import Copyright from '../components/Copyright'

// pages
import Login from './Login';
import Home from './home'
import People from './People';
import PreviousTasks from './PreviousTasks';
import Search from './Search';
import Journal from './Journal';
import TaskDetail from './TaskDetail';
import TemplateDetail from './TemplateDetail';
import Settings from './Settings';
import Templates from './Templates';
import CustomDateRange from './CustomDateRange';
import TemplateTasks from './TemplateTasks';


export default function Index(props) {
  const {
    getCustomTaskdates,
    customTaskdates,
    getPersonData,
    getPreviousTaskdates,
    includeCompletedTasks,
    setIncludeCompletedTasks,
    journalEntryUnsaved,
    journalMap,
    journalUpdateStatus,
    personData,
    previousTaskdates,
    setSettingSave,
    settingsMap,
    singleDateView,
    taskdate,
    taskdates,
    todayJournalEntry,
    returnPath,
    setReturnPath,
    isLoading,
    setIsLoading,
    detailTask,
    templateTasks,
    taskTemplate,
    includeDeleted,
  } = props;


  document.body.style.backgroundColor = BodyColor;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
    >
      <a id="top-of-page" />
      <Appbar
        journalEntryUnsaved={journalEntryUnsaved}
        setSettingSave={setSettingSave}
        settingsMap={settingsMap}
        todayJournalEntry={todayJournalEntry}
      />
      <Container
        maxWidth='false'
        sx={{
          minHeight: 500,
          maxWidth: 750,
          padding: 0,
          paddingRight: .25,
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <RouteGuard>
                <Home
                  includeCompletedTasks={includeCompletedTasks}
                  setIncludeCompletedTasks={setIncludeCompletedTasks}
                  singleDateView={singleDateView}
                  taskdate={taskdate}
                  taskdates={taskdates}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/:dateparam"
            element={
              <RouteGuard>
                <Home
                  includeCompletedTasks={includeCompletedTasks}
                  singleDateView={singleDateView}
                  taskdate={taskdate}
                  taskdates={taskdates}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/people"
            element={
              <RouteGuard>
                <People
                  getPersonData={getPersonData}
                  personData={personData}
                  setReturnPath={setReturnPath}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/search"
            element={
              <RouteGuard>
                <Search />
              </RouteGuard>
            }
          />
          <Route
            path="/previoustasks"
            element={
              <RouteGuard>
                <PreviousTasks
                  getPreviousTaskdates={getPreviousTaskdates}
                  previousTaskdates={previousTaskdates}
                  setReturnPath={setReturnPath}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  includeDeleted={includeDeleted}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/journal"
            element={
              <RouteGuard>
                <Journal
                  journalEntryUnsaved={journalEntryUnsaved}
                  journalMap={journalMap}
                  journalUpdateStatus={journalUpdateStatus}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/journal/:routedate"
            element={
              <RouteGuard>
                <Journal
                  journalEntryUnsaved={journalEntryUnsaved}
                  journalMap={journalMap}
                  journalUpdateStatus={journalUpdateStatus}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/taskdetail"
            element={
              <RouteGuard>
                <TaskDetail
                  detailTask={detailTask}
                  returnPath={returnPath}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/templatedetail"
            element={
              <RouteGuard>
                <TemplateDetail
                  returnPath={returnPath}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/customdaterange"
            element={
              <RouteGuard>
                <CustomDateRange
                  getCustomTaskdates={getCustomTaskdates}
                  customTaskdates={customTaskdates}
                  setReturnPath={setReturnPath}
                  includeDeleted={includeDeleted}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/templates"
            element={
              <RouteGuard>
                <Templates
                />
              </RouteGuard>
            }
          />
          <Route
            path="/templatetasks"
            element={
              <RouteGuard>
                <TemplateTasks
                  taskTemplate={taskTemplate}
                  templateTasks={templateTasks}
                />
              </RouteGuard>
            }
          />
          <Route
            path="/settings"
            element={
              <RouteGuard>
                <Settings
                  settingsMap={settingsMap}
                  setSettingSave={setSettingSave}
                  setReturnPath={setReturnPath}
                />
              </RouteGuard>
            }
          />
          <Route
            path="login"
            element=
            {<Login />}
          />
        </Routes>
        <Copyright />
      </Container>
    </LocalizationProvider>
  )
}
