// general
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

// Material UI
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

// components
import { CustomColorScheme } from "./CustomTheme";
import { SettingsContext } from "./AllContext";


export default function SettingsContent(props) {
  const {
    setDrawerOpen
  } = props;

  const {
    settingsMap,
    setSettingSave,
    returnPath,
  } = useContext(SettingsContext);

  // constants ////////////

  const emptyTimezone = {
    description: '',
    descriptionDts: '',
    fullname: '',
    location: '',
    region: '',
    utcOffset: new Date(Date.now()).getTimezoneOffset() * -60,      // milliseconds
    utcOffsetDts: new Date(Date.now()).getTimezoneOffset() * -60,   // milliseconds
  }

  const [displayDatetime, setDisplayDatetime] = useState(new Date);
  const [timerActive, setTimerActive] = useState(true);
  const [dst, setDst] = useState(false);
  const [dtsEnabled, setDtsEnabled] = useState(true);
  const [timezone, setTimezone] = useState(emptyTimezone);
  const [weightTop, setWeightTop] = useState('');
  const [weightBottom, setWeightBottom] = useState('');
  const [weightSelect, setWeightSelect] = useState('');
  const [weightTopRange, setWeightTopRange] = useState();
  const [weightBottomRange, setWeightBottomRange] = useState();
  const [weightSelectRange, setWeightSelectRange] = useState();

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });
  const navigate = useNavigate();

  const filteredTimezones =
    settingsMap && settingsMap.timezones.filter(function (el) {
      return el.region === timezone.region
    });



  // timer ////////////////

  useEffect(() => {
    if (timerActive) {
      const timer = setInterval(() => {
        let newDate = new Date(Date.now());
        let offsetHours = (dst ? timezone.utcOffsetDts : timezone.utcOffset) / 3600;
        let currentOffsetHours = newDate.getTimezoneOffset() / -60
        let currentUTCHours = newDate.getUTCHours();
        newDate.setUTCHours(currentUTCHours - currentOffsetHours + offsetHours);
        setDisplayDatetime(newDate);
      }, 1000);
      return () => {
        clearInterval(timer);
      }
    }
  })

  useEffect(() => {
    if (settingsMap) {
      let isDst = settingsMap.settings['isDst'] === '1'
      setDst(isDst);
      let wTop = parseInt(settingsMap.settings['weightRangeTop']);
      let wBottom = parseInt(settingsMap.settings['weightRangeBottom']);
      let wSelect = parseInt(settingsMap.settings['weightSelected']);

      setWeightTop(wTop);
      setWeightBottom(wBottom);
      setWeightSelect(wSelect);

      setTimezone(getTimezone(settingsMap.settings['timezone']))
      setDtsEnabled(true)

      setWeightTopRange(createArray(wTop - 5, wTop + 5))
      setWeightBottomRange(createArray(wBottom - 5, wBottom + 5))
      setWeightSelectRange(createArray(wSelect - 3, wSelect + 3))
    }
  }, [settingsMap])


  // event handlers ///////////////////

  const handleRegionChange = (event) => {
    let r = event.target.value;
    let tzone = emptyTimezone;
    tzone.region = r;
    setDtsEnabled(false)
    setTimezone(tzone);
    setTimerActive(true);
  }


  const handleTimezonehange = (event) => {
    let tzValue = event.target.value;
    let tzone;
    if (tzValue === '') {
      let r = timezone.region
      tzone = emptyTimezone
      tzone.region = r
      setDtsEnabled(false)
    } else {
      tzone = getTimezone(tzValue);
      setDtsEnabled(true)
    }
    setTimezone(tzone)
    setTimerActive(true);
  }

  const handleDstChange = (event) => {
    setDst(event.target.checked);
    setTimerActive(true);
  }

  const handleWeightTopChange = (event) => {
    setWeightTop(event.target.value);
  }

  const handleWeightSelectChange = (event) => {
    setWeightSelect(event.target.value);
  }

  const handleWeightBottomChange = (event) => {
    setWeightBottom(event.target.value);
  }

  const handleReturn = () => {
    if (setDrawerOpen) {
      setDrawerOpen(false);
    } else {
      navigate(returnPath)
    }
    if (settingsMap) {
      let isDst = (settingsMap.settings['isDst'] === '1')
      let tzone = getTimezone(settingsMap.settings['timezone'])
      setDtsEnabled(true)
      setDst(isDst)
      setTimezone(tzone)
    }
  }

  const handleSaveAndReturn = () => {
    let settings = []
    if (settingsMap.settings['timezone'] !== timezone.fullname) {
      settings.push({
        "key": "timezone",
        "value": timezone.fullname,
      })
    }
    if ((settingsMap.settings['isDst'] === '1') !== dst) {
      settings.push({
        "key": "isDst",
        "value": dst ? "1" : "0",
      })

    }
    if (parseInt(settingsMap.settings['weightRangeTop']) !== weightTop) {
      settings.push({
        "key": "weightRangeTop",
        "value": weightTop,
      })
    }
    if (parseInt(settingsMap.settings['weightRangeBottom']) !== weightBottom) {
      settings.push({
        "key": "weightRangeBottom",
        "value": weightBottom,
      })
    }
    if (parseInt(settingsMap.settings['weightSelected']) !== weightSelect) {
      settings.push({
        "key": "weightSelected",
        "value": weightSelect,
      })
    }
    setSettingSave(settings)
    handleReturn();
  }

  // functions ////////////////////////


  function createArray(start, end) {
    let result = [];
    for (let i = end; i >= start; i--) {
      result.push(i);
    }
    return result;
  }


  function getTimezone(timezoneName) {
    return settingsMap ? settingsMap.timezones.find(tz => tz.fullname === timezoneName) : emptyTimezone
  }


  // render ///////////////////////////


  return (
    <>
      <Box
        sx={{
          padding: isMobile ? 0 : 2,
          backgroundColor: CustomColorScheme['white'],
        }}
      >
        {
          isMobile &&
          <Box
            sx={{
              backgroundColor: CustomColorScheme['lightestTan'],
              paddingY: 1,
              paddingLeft: 3,
            }}
          >
            <Typography
              fontSize={19}
              fontWeight='bold'
              color={CustomColorScheme['mustard']}
            >
              Settings
            </Typography>
          </Box>
        }
        <Stack
          spacing={3}
          paddingY={1}
          paddingX={2}
          paddingBottom={5}
        >
          <Typography
            variant="div"
            component="h3"
            paddingLeft={0.35}
          >
            Timezones
          </Typography>
          <Stack
            direction="row"
            spacing={2} >
            {
              settingsMap &&
              <Box
                paddingLeft={0.35}
                width={isMobile ? '50%' : 160}
              >
                <Select
                  value={timezone.region ? timezone.region : ''}
                  label="Region"
                  variant="standard"
                  sx={{
                    width: '100%',
                    ':after': {
                      borderBottomColor: CustomColorScheme['black'],
                    },
                  }}
                  onFocus={() => setTimerActive(false)}
                  onBlur={() => setTimerActive(true)}
                  onChange={handleRegionChange}
                >
                  <MenuItem id="region-menuitem-0" value={''}>{"none"}</MenuItem>
                  {
                    settingsMap.regions.map((regn, idx) => {
                      return (
                        <MenuItem
                          id={`region-menuitem-${regn}`}
                          key={idx}
                          value={regn}
                        >
                          {regn}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </Box>
            }
            {
              settingsMap &&
              filteredTimezones &&
              <Select
                value={timezone.fullname ? timezone.fullname : ''}
                label="Timezone"
                variant="standard"
                sx={{
                  width: isMobile ? '50%' : 160,
                  ':after': {
                    borderBottomColor: CustomColorScheme['black'],
                  }
                }}
                onFocus={() => setTimerActive(false)}
                onBlur={() => setTimerActive(true)}
                onChange={handleTimezonehange}
              >
                <MenuItem id="region-menuitem-0" value={''}>{"none"}</MenuItem>
                {
                  filteredTimezones.map((tz, idx) => {
                    return (
                      <MenuItem
                        id={`region-menuitem-${idx}`}
                        key={idx}
                        value={tz.fullname}
                      >
                        {dst ? tz.descriptionDts : tz.description}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            }
          </Stack>
          <FormControlLabel
            control={
              <Checkbox
                checked={dst}
                padding={0}
                disabled={!dtsEnabled}
                sx={{
                  padding: 0,
                  margin: 0,
                  '&.MuiCheckbox-root': {
                    color: CustomColorScheme['mustard'],
                  },
                }}
                onFocus={() => setTimerActive(false)}
                onBlur={() => setTimerActive(true)}
                onChange={handleDstChange}
              />}
            label={"dst " + dst}
          />
          <Box
            paddingLeft={0.35}
            paddingBottom={2}
          >
            {displayDatetime.toLocaleString()}
          </Box>
          <Typography
            variant="div"
            component="h3"
            paddingLeft={0.35}
          >
            Weight Settings
          </Typography>
          <Stack
            spacing={3}
          >
            <FormControlLabel
              label="Top of weight range"
              control={
                <Select
                  value={weightTop ? weightTop : ''}
                  variant="standard"
                  sx={{
                    marginRight: 2,
                    ':after': {
                      borderBottomColor: CustomColorScheme['black'],
                    }
                  }}
                  onChange={handleWeightTopChange}
                >
                  {
                    weightTopRange
                    && weightTopRange.map((i, idx) => {
                      return (
                        <MenuItem key={i} value={i}>{i}</MenuItem>
                      )
                    })
                  }
                </Select>
              }

            />
            <FormControlLabel
              label="Default Weight Selection"
              control={
                <Select
                  value={weightSelect ? weightSelect : ''}
                  variant="standard"
                  sx={{
                    marginRight: 2,
                    ':after': {
                      borderBottomColor: CustomColorScheme['black'],
                    }
                  }}
                  onChange={handleWeightSelectChange}
                >
                  {
                    weightSelectRange
                    && weightSelectRange.map((i) => {
                      return (
                        <MenuItem key={i} value={i}>{i}</MenuItem>
                      )
                    })
                  }
                </Select>
              }

            />
            <FormControlLabel
              label="Bottom of weight range"
              control={
                <Select
                  value={weightBottom ? weightBottom : ''}
                  variant="standard"
                  sx={{
                    marginRight: 2,
                    ':after': {
                      borderBottomColor: CustomColorScheme['black'],
                    }
                  }}
                  onChange={handleWeightBottomChange}
                >
                  {
                    weightBottomRange
                    && weightBottomRange.map((i) => {
                      return (
                        <MenuItem key={i} value={i}>{i}</MenuItem>
                      )
                    })
                  }
                </Select>
              }

            />
          </Stack>
          {
            !isMobile &&
            <>
              <Box
                height={50}
              />
              <Stack
                direction="row"
                spacing={2}
              >
                <Button
                  variant="contained"
                  onClick={handleSaveAndReturn}
                  sx={{
                    backgroundColor: CustomColorScheme['mustard'],
                    '&:hover': {
                      backgroundColor: CustomColorScheme['darkestBrown'],
                    }
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  variant="text"
                  onClick={handleReturn}
                  sx={{
                    color: CustomColorScheme['mustard'],
                    borderColor: CustomColorScheme['mustard'],
                    '&:hover': {
                      color: CustomColorScheme['mustard'],
                      borderColor: CustomColorScheme['darkestBrown'],
                    }
                  }}
                >
                  Close
                </Button>
              </Stack>
            </>
          }
        </Stack>
      </Box>
      {
        isMobile &&
        <Stack
          direction="row"
          spacing={3}
          display='flex'
          flexGrow={1}
          backgroundColor={CustomColorScheme['lightestTan']}
          paddingY={1.5}
          paddingLeft={3}
        >
          <Typography
            fontSize={18}
            fontWeight='bold'
            color={CustomColorScheme['mustard']}
            onClick={handleSaveAndReturn}
          >
            Save Changes
          </Typography>
          <Typography
            fontSize={18}
            color={CustomColorScheme['mustard']}
            onClick={handleReturn}
          >
            Return
          </Typography>
        </Stack>
      }

    </>
  )
};