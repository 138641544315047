import { createTheme, createStyles, ThemeProvider } from "@mui/material";
import { styled, alpha } from '@mui/material/styles';


export const CustomColorScheme = {
  date: "#2f594b",
  darkbody: "#595449",
  datelight: "#44806b",
  task: "#ccc8b8",

  black: "#000",
  blue: "#173091",
  blueGreen: "#256b53",
  brightGreen: "#24ff5f",
  brown: "#998a6d",
  cyan: '#3df0f2',
  darkestGreen: "#113226",
  darkBrown: '#999966',
  darkGray: "#443f37",
  darkGreenBrown: '#3b3b06',
  darkOrange: "#cc972d",
  darkRed: '#a60208',
  darkestBrown: '#565603',
  gray: '#999',
  green: "#478064",
  kellyGreen: '#047823',

  lightBlue: "#88bdbc",
  lightBrown: "#99907d",
  lightGreen: "#3db388",
  lightGray: "#a0a0a0",
  lightOrange: '#ffd22b',
  lightTan: '#ebead8',
  lightestTan: "#f5f5e9",
  lightTask: "#edeadf",
  lightYellow: '#fafa82',

  mediumBrown: '#c7c396',
  mustard: "#a67b24",
  offwhite: '#f5f4e4',
  orange: '#fcba03',
  red: '#e60209',
  rose: '#ff75b1',
  tan: '#d9d9b0',
  text: '#565603',
  warmGray: "#bfbfba",
  // warmGrayLight: "#f2f1eb",
  warmGrayLight: "#e6e6df",
  warmGrayMedium: '#5e5c55',
  white: 'white',

  transparentLightOrange45: "rgba(255, 210, 43, 0.45)",
  transparentLightOrange75: "rgba(255, 210, 43, 0.75)",
  transparentTask45: "rgba(204, 200, 184, 0.45)",
  transparentTask75: "rgba(204, 200, 184, 0.75)",
  transparentTask85: "rgba(204, 200, 184, 0.85)",
  transparentWhite25: "rgba(255, 255, 255, 0.25)",
  transparentWhite70: "rgba(255, 255, 255, 0.70)",
  transparentDarkred25: "rgba(166, 2, 8, 0.25)",

  yellow: '#ffff00',


  test: "#998a6d",
  // test: "#256b53",

}

export const BodyColor = CustomColorScheme['darkOrange'];
export const DateCardColor = CustomColorScheme['blueGreen'];
export const AppbarColor = CustomColorScheme['darkestGreen']

export const DATEFORMAT = "YYYY-MM-DD";

CustomColorScheme['lightblue'] = "#1975d2";

export const ContainerTheme =
  createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          maxWidthSm: {
            maxWidth: 600,
          },
          maxWidthMd: {
            maxWidth: 700,
          },
          maxWidthLg: {
            maxWidth: 800,
          },
        },
      },
    },
  });


export const TitleLabel = styled('div')(({ theme }) => ({
  color: alpha(theme.palette.common.white, 0.70),
  fontSize: 15,
  display: "flex",
  alignItems: "center",
}));

