// general
import '../App.css';
import { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs';

// mui
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// custom components
import { CustomColorScheme } from '../components/CustomTheme';
import { TemplateContext } from '../components/AllContext';
import DeletionConfirmationDialog from './DeletionConfirmationDialog';
import TemplateContent from '../components/TemplateContent';
import {
  TemplateTypes,
  daysOfMonth,
  daysOfWeek,
  frequencyDays,
  orderIdList,
  businessRules,
} from '../components/Resources';


export default function TemplateDialog(props) {
  const {
    dialogOpen,
    setDialogOpen,
    template,
    parentTemplate,
    parentTemplates
  } = props;

  const {
    templates,
    getTemplates,
    setReturnPath,
    setTemplateCreate,
    setTemplateUpdate,
    setTemplateDelete,
    detailTemplate,
    setDetailTemplate,
    detailParentTemplate,
    setDetailParentTemplate,
    detailParentTemplates,
    setDetailParentTemplates,
    getTemplateTasks,
    taskTemplate,
    setTaskTemplate,
  } = useContext(TemplateContext);

  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
    >
      <TemplateContent
        template={template}
        parentTemplate={parentTemplate}
        parentTemplates={parentTemplates}
        onClose={handleDialogClose}
      />
    </Dialog>
  );
}

