// general
import { useEffect, useState, useContext } from "react";
import { useMediaQuery } from 'react-responsive'
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

// material ui components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Popover from '@mui/material/Popover';
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// custom components
import { TaskDetailContext } from "../components/AllContext";
import { CustomColorScheme, DATEFORMAT, AppbarColor } from "../components/CustomTheme";
import ButtonBar from "../components/ButtonBar";
import HourMinutesControl from "./HourMinutesControl";

/////////////////////////////

export default function TaskContent(props) {
  const {
    providedTask,
    onClose,
  } = props;

  const {
    parentTasks,
    getParentTasks,
    setParentTasks,
    setTaskCreate, // onTaskCreate,
    setTaskUpdate, // onTaskSave,
    setTaskDelete, // onTaskDelete,
    includeCompletedTasks,
  } = useContext(TaskDetailContext);

  const [parentTaskId, setParentTaskId] = useState('');
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');
  const [minutes, setMinutes] = useState();
  const [orderId, setOrderId] = useState();
  const [templateId, setTemplateId] = useState();
  const [date, setDate] = useState();
  const [isCompleted, setIsCompleted] = useState(false);
  const [isEmphasis, setIsEmphasis] = useState(false);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });
  const isSearch = useLocation().pathname === '/search';
  const datepickerFormat = isMobile ? 'ddd,  MMMM D, YYYY' : 'dddd,  MMMM D, YYYY';

  const calendarOpen = Boolean(anchorEl);

  const dialogHeader = !(providedTask && providedTask.taskId)
    ? "Create A New Task"
    : providedTask.isClone
      ? "Copy An Existing Task"
      : "Edit Existing Task";

  const filteredParentTasks =
    parentTasks && parentTasks.length > 0
      ?
      parentTasks.filter(el => {
        return (
          (el.taskId !== providedTask.taskId)
          && !el.isDeleted
          && (dayjs(el.date).format(DATEFORMAT) < dayjs().format(DATEFORMAT)
            || el.isCompleted === providedTask.isCompleted
            || !el.isCompleted
          )
        )
      })
      : parentTasks;


  // useEffect ////////////

  useEffect(() => {
    if (providedTask) {
      setTitle(providedTask.title);
      setDetail(providedTask.detail);
      setDate(providedTask.date);
      setTemplateId(providedTask.templateId);
      setMinutes(providedTask.minutes)
      setOrderId(providedTask.orderId);
      setParentTaskId(providedTask.parentTaskId);
      setIsCompleted(providedTask.isCompleted);
      setIsEmphasis(providedTask.isEmphasis);
    }
  }, []);

  useEffect(() => {
    if (date && !isSearch) {
      getParentTasks(dayjs(date).format(DATEFORMAT));
    }
  }, [date]);


  // event handlers /////////

  const handleClose = () => {
    setTitle(null);
    setDetail(null);
    setDate(null);
    setTemplateId(null);
    setMinutes(null);
    setOrderId(null);
    setParentTaskId(null);
    setParentTasks(null);
    setIsCompleted(null);
    setIsEmphasis(null);
    onClose();
  }

  const handleDateChange = (newValue, selectionState) => {
    if (selectionState === 'finish') {
      setAnchorEl(null)
      setDate(newValue);
      setParentTaskId('')
    }
  }

  const handleTaskSave = () => {
    let updatedTask = {
      taskId: providedTask.taskId,
      date: dayjs(date).format(DATEFORMAT),
      detail: detail,
      parentTaskId: parentTaskId,
      minutes: minutes,
      title: title,
      orderId: orderId,
      templateId: templateId,
      isEmphasis: isEmphasis,
      isCompleted: isCompleted,
    }
    providedTask.taskId ? setTaskUpdate(updatedTask) : setTaskCreate(updatedTask);
    onClose();
  }

  const handleTaskComplete = () => {
    let updatedTask = {
      taskId: providedTask.taskId,
      date: dayjs(date).format(DATEFORMAT),
      detail: detail,
      parentTaskId: parentTaskId,
      minutes: minutes,
      title: title,
      orderId: orderId,
      templateId: templateId,
      isEmphasis: isEmphasis,
      isCompleted: !isCompleted
    }
    providedTask.taskId ? setTaskUpdate(updatedTask) : setTaskCreate(updatedTask);
    onClose();
  }

  const handleTaskDelete = () => {
    setTaskDelete(providedTask);
    handleClose();
  }

  const handleCalendarOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleCalendarClose = (event) => {
    setAnchorEl(null);
  }

  const handleParentTaskChange = (event) => {
    let val = event.target.value
    setParentTaskId(val > 0 ? val : '')
  }



  // render /////////////////

  return (
    <>
      {
        isMobile
          ?
          <Stack
            direction='row'
          >
            <Box
              backgroundColor={CustomColorScheme['task']}
              padding={1}
              marginTop={1}
              width={250}
            >
              <Typography
                fontSize={22}
                component="div"
              >
                {dialogHeader}
              </Typography>
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              marginTop={1}
              backgroundColor={CustomColorScheme['task']}
              onClick={() => window.scrollTo(0, document.body.scrollHeight)}
            />
          </Stack>
          :
          <AppBar
            position="static"
            margin={1}
            elevation={0}
            sx={{
              borderTop: 1,
              borderLeft: 1,
              borderRight: 1,
              borderColor: CustomColorScheme['white'],
              backgroundColor: AppbarColor,
            }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                padding: 1,
              }
              }>
              {dialogHeader}
            </Typography>
          </AppBar>
      }
      <Box
        backgroundColor={CustomColorScheme['white']}
        width={isMobile ? 'default' : 450}
        padding={isMobile ? 1 : 2}
      >
        <Stack
          spacing={isMobile ? 2 : 3}
          marginTop={isMobile ? 0 : 1}
        >
          <Stack
            spacing={isMobile ? 2 : !isSearch ? 3 : 1}
          >
            <FormControl
              fullWidth
              variant='standard'
              sx={{
              }}
            >
              <InputLabel
                sx={{
                  '&.MuiInputLabel-root': {
                    color: CustomColorScheme['darkGreenBrown'],
                  },

                }}
              >
                {isSearch ? "Parent Task (disabled in search)" : "Parent Task"}
              </InputLabel>

              {/* parent tasks */}
              <Select
                value={parentTaskId ? parentTaskId : ''}
                onChange={handleParentTaskChange}
                disabled={isSearch}
                sx={{
                  ':after': {
                    borderBottomColor: CustomColorScheme['black'],
                  },
                }}
                MenuProps={{
                  sx: {
                    "&& .Mui-selected": {
                      backgroundColor: CustomColorScheme['lightTask'],
                    },
                  },
                }}
              >
                <MenuItem
                  value={''}
                >
                  none
                </MenuItem>
                {
                  filteredParentTasks &&
                  filteredParentTasks.map((t) => {
                    return (
                      <MenuItem
                        key={t.taskId}
                        value={t.taskId}>
                        {t.title}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            <TextField
              onClick={handleCalendarOpen}
              value={dayjs(date).format(datepickerFormat)}
              variant="standard"
              sx={{
                width: isMobile ? 220 : 250,
                marginBottom: 1,
                input: {
                  cursor: 'pointer',
                  paddingY: 1,
                },
                '&.MuiTextField-root': {
                  ':hover': {
                    cursor: 'pointer',
                  }
                },
                '& .MuiInput-underline, .MuiInput-underline:after': {
                  borderBottomColor: CustomColorScheme['black'],
                },
              }}
              InputProps={{
                readOnly: true,
                endAdornment:
                  <CalendarMonthIcon
                    onClick={handleCalendarOpen}
                    cursor='pointer'
                    sx={{
                      color: CustomColorScheme['darkGreenBrown'],
                    }}
                  />
              }}
            />
            {/* } */}
          </Stack>
          <HourMinutesControl
            providedMinutes={minutes}
            setProvidedMinutes={setMinutes}
            nowAndClear={true}
          />

          <FormControlLabel
            padding={0}
            control={
              <Checkbox
                value={isEmphasis}
                checked={isEmphasis}
                onChange={(e) => setIsEmphasis(!isEmphasis)}
                sx={{
                  padding: 0,
                  color: CustomColorScheme['text'],
                  '&.Mui-checked': {
                    color: CustomColorScheme['text'],
                  }
                }}
              />}
            label="&nbsp;Emphasize"
          />

          <TextField
            label="Title"
            variant="standard"
            required value={title ? title : ''}
            onChange={e => setTitle(e.target.value)}
            InputLabelProps={{
              shrink: true,
              sx: {
                marginLeft: 1.5,
                marginTop: 0.5,
                '&.Mui-focused': {
                  color: CustomColorScheme['darkGreenBrown'],
                },
              }
            }}
            sx={{
              paddingX: 1.5,
              paddingBottom: .5,
              backgroundColor: CustomColorScheme['lightTask'],
              '& .MuiInput-underline:after': {
                borderBottomColor: CustomColorScheme['darkGreenBrown'],
              },
            }}
          />
          <TextField
            label="Detail"
            multiline
            variant="standard"
            minRows={6}
            value={detail ? detail : ''}
            onChange={e => setDetail(e.target.value)}
            InputLabelProps={{
              shrink: true,
              sx: {
                marginLeft: 1.5,
                marginTop: 0.5,
                '&.Mui-focused': {
                  color: CustomColorScheme['darkGreenBrown'],
                },
              }
            }}
            sx={{
              paddingX: 1.5,
              paddingBottom: .5,
              backgroundColor: CustomColorScheme['lightTask'],
              '& .MuiInput-underline:after': {
                borderBottomColor: CustomColorScheme['darkGreenBrown'],
              },
            }} />
        </Stack>
      </Box>
      <Box
        display='flex'
        flexGrow={1}
        justifyContent='center'
        backgroundColor={CustomColorScheme['task']}
      >
        <ButtonBar
          providedTask={providedTask}
          title={title}
          confirmDeleteOpen={confirmDeleteOpen}
          handleTaskSave={handleTaskSave}
          handleTaskComplete={handleTaskComplete}
          handleDialogDelete={handleTaskDelete}
          setConfirmDeleteOpen={setConfirmDeleteOpen}
          handleClose={handleClose}
        />
      </Box>

      {/**** non-render elements ****/}

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={calendarOpen}
        onClose={handleCalendarClose}
      >
        <DateCalendar
          value={providedTask && providedTask.isClone ? dayjs() : date ? dayjs(date) : dayjs()}
          onChange={handleDateChange}
          sx={{
            "& .MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: CustomColorScheme['kellyGreen'],
              },
            }
          }}
        />
      </Popover>
    </>
  );

}
