// general
import '../App.css';
import { useContext } from "react";
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom';

// mui
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";

// components
import { CustomColorScheme } from './CustomTheme';
import { CustomSwitchContext } from './AllContext';

export default function CustomSwitch(props) {
  const {
    stateValue,
    setStateValue,
    displayMode,
    title
  } = props

  const {
    setIncludeCompletedTasks,
    includeCompletedTasks,
  } = useContext(CustomSwitchContext);

  // constants //////////////

  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });

  // event handlers /////////

  const handleStateChange = () => {
    setStateValue(!stateValue)
  }


  // render /////////////////

  return (
    <Stack
      direction='row'
      spacing={isMobile ? 0 : 1}
      alignItems='center'
      marginRight={isMobile ? -1 : 0}
    >
      <Typography
        color={displayMode === 'light' ? CustomColorScheme['transparentWhite70'] : CustomColorScheme['white']}
      >
        {isMobile ? title[0] : title[1]}
      </Typography>

      <Switch
        onChange={handleStateChange}
        // checked={includeCompletedTasks}
        checked={stateValue}
        sx={{
          '& .MuiSwitch-switchBase': {
            color: CustomColorScheme['test'],
            '&.Mui-checked': {
              color: displayMode === 'light' ? CustomColorScheme['green'] : CustomColorScheme['darkOrange'],
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: CustomColorScheme['darkGray'],
              },
            },
          },
        }}
      />
    </Stack>

  )

}
