// Material UI
import Drawer from '@mui/material/Drawer';

// components
import SettingsContent from "../components/SettingsContent";


export default function SettingsDrawer(props) {
  const {
    drawerOpen,
    setDrawerOpen,
  } = props;

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <SettingsContent
        setDrawerOpen={setDrawerOpen}
      />
    </Drawer>
  )

}

